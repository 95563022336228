import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { FirmService } from './firm.service';
import { SlugifyService } from './slugify.service';
import { TagService } from './tag.service';
import { FirmStore } from '../modules/firm/store/firm.store';
import { TranslateService } from '@ngx-translate/core';

declare const gtag;

@Injectable()
export class RouteService implements CanActivate {
  redirectTo: string;

  constructor(
    private router: Router,
    public firmService: FirmService,
    public auth: AuthService,
    public slug: SlugifyService,
    private tagService: TagService,
    private firmStore: FirmStore,
    private translate: TranslateService
  ) {}

  async canActivate() {
    await this.auth.getToken();
    const response = await this.firmService.getDistributor();

    const isTest = location.hostname === response.webshop_domain_test;
    const ownDomain = location.hostname !== response.webshop_domain;

    if (isTest) {
      this.router.navigate(['/firms']);
    } else if (!isTest && ownDomain) {
      this.router.navigate(['/firm']);
    } else {
      this.router.navigate(['/firms']);
    }

    return ownDomain;
  }

  navigateToMenu(params?) {
    gtag('event', 'click', {
      event_category: 'Header',
      event_action: 'Navigation',
      event_label: 'Navigating to the menu page',
    });

    const firm = this.firmStore.currentFirm;
    const ownDomain = this.firmStore.ownDomain;
    const language = this.firmStore.language ? this.firmStore.language : firm.languages[0].code;

    if (params === undefined || params === null) {
      if (!ownDomain) {
        if (firm) {
          this.router.navigate([firm.website_details.sitename, language]);
        }
      } else {
        if (firm) {
          if (firm.parent_is_multifirm) {
            this.router.navigate([firm.website_details.sitename, language]);
          } else {
            this.router.navigate([language]);
          }
        }
      }
    } else {
      if (!ownDomain) {
        if (firm) {
          this.router.navigate([firm.website_details.sitename, language], {
            queryParams: { cat_index: params },
          });
        }
      } else {
        if (firm) {
          if (firm.parent_is_multifirm) {
            this.router.navigate([firm.website_details.sitename, language], {
              queryParams: { cat_index: params },
            });
          } else {
            this.router.navigate([language], { queryParams: { cat_index: params } });
          }
        }
      }
    }
  }

  navigateToProduct(own_domain, firm, language, item) {
    if (!item.category.parent) {
      if (!own_domain) {
        this.router.navigate([
          firm.website_details.sitename,
          language,
          'category',
          this.slug.slugify(item.category.name),
          item.category.category_id,
          'product',
          this.slug.slugify(item.name),
          item.id,
        ]);
      } else {
        if (firm.parent_is_multifirm) {
          this.router.navigate([
            firm.website_details.sitename,
            language,
            'category',
            this.slug.slugify(item.category.name),
            item.category.category_id,
            'product',
            this.slug.slugify(item.name),
            item.id,
          ]);
        } else {
          this.router.navigate([
            language,
            'category',
            this.slug.slugify(item.category.name),
            item.category.category_id,
            'product',
            this.slug.slugify(item.name),
            item.id,
          ]);
        }
      }
    } else {
      if (!own_domain) {
        this.router.navigate([
          firm.website_details.sitename,
          language,
          'category',
          this.slug.slugify(item.category.parent.name),
          item.category.parent.category_id,
          'subcategory',
          this.slug.slugify(item.category.name),
          item.category.category_id,
          'product',
          this.slug.slugify(item.name),
          item.id,
        ]);
      } else {
        if (firm.parent_is_multifirm) {
          this.router.navigate([
            firm.website_details.sitename,
            language,
            'category',
            this.slug.slugify(item.category.parent.name),
            item.category.parent.category_id,
            'subcategory',
            this.slug.slugify(item.category.name),
            item.category.category_id,
            'product',
            this.slug.slugify(item.name),
            item.id,
          ]);
        } else {
          this.router.navigate([
            language,
            'category',
            this.slug.slugify(item.category.parent.name),
            item.category.parent.category_id,
            'subcategory',
            this.slug.slugify(item.category.name),
            item.category.category_id,
            'product',
            this.slug.slugify(item.name),
            item.id,
          ]);
        }
      }
    }
  }

  navigateToCategory(own_domain, firm, language, cat_name, cat_id) {
    if (!own_domain) {
      this.router.navigate([firm.website_details.sitename, language, 'category', this.slug.slugify(cat_name), cat_id]);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([
            firm.website_details.sitename,
            language,
            'category',
            this.slug.slugify(cat_name),
            cat_id,
          ]);
        } else {
          this.router.navigate([language, 'category', this.slug.slugify(cat_name), cat_id]);
        }
      }
    }
  }

  navigateToSubCategory(own_domain, firm, language, cat_name, cat_id, subcat_name, subcat_id) {
    if (!own_domain) {
      this.router.navigate([
        firm.website_details.sitename,
        language,
        'category',
        this.slug.slugify(cat_name),
        cat_id,
        'subcategory',
        this.slug.slugify(subcat_name),
        subcat_id,
      ]);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([
            firm.website_details.sitename,
            language,
            'category',
            this.slug.slugify(cat_name),
            cat_id,
            'subcategory',
            this.slug.slugify(subcat_name),
            subcat_id,
          ]);
        } else {
          this.router.navigate([
            language,
            'category',
            this.slug.slugify(cat_name),
            cat_id,
            'subcategory',
            this.slug.slugify(subcat_name),
            subcat_id,
          ]);
        }
      }
    }
  }

  navigateToInfo(own_domain, firm, language) {
    gtag('event', 'click', {
      event_category: 'Header',
      event_action: 'Navigation',
      event_label: 'Navigating to the info page',
    });

    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'info']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'info']);
        } else {
          this.router.navigate([language, 'info']);
        }
      }
    }
  }

  navigateToFavorites(own_domain, firm, language) {
    gtag('event', 'click', {
      event_category: 'Header',
      event_action: 'Navigation',
      event_label: 'Navigating to the favorites page',
    });

    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'favorites']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'favorites']);
        } else {
          this.router.navigate([language, 'favorites']);
        }
      }
    }
  }

  navigateToReservation(own_domain, firm, language) {
    if (!own_domain) {
      this.router.navigate([firm.website_details.sitename, language, 'reservation']);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'reservation']);
        } else {
          this.router.navigate([language, 'reservation']);
        }
      }
    }
  }

  navigateToProfile(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'profile']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'profile']);
        } else {
          this.router.navigate([language, 'profile']);
        }
      }
    }
  }

  navigateToSignup(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'signup']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'signup']);
        } else {
          this.router.navigate([language, 'signup']);
        }
      }
    }
  }

  navigateToPasswordReset(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'password-reset']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'password-reset']);
        } else {
          this.router.navigate([language, 'password-reset']);
        }
      }
    }
  }

  navigateToLoyalty(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'loyalty']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'loyalty']);
        } else {
          this.router.navigate([language, 'loyalty']);
        }
      }
    }
  }

  navigateToBasket(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'basket']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'basket']);
        } else {
          this.router.navigate([language, 'basket']);
        }
      }
    }
  }

  navigateToExtraInfo(method, own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'checkout', 'extra-info', method]);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'checkout', 'extra-info', method]);
        } else {
          this.router.navigate([language, 'checkout', 'extra-info', method]);
        }
      }
    }
  }

  navigateToPaymentMethod(own_domain, firm, language) {
    if (!own_domain) {
      this.router.navigate([firm.website_details.sitename, language, 'checkout', 'paymentmethod']);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'checkout', 'paymentmethod']);
        } else {
          this.router.navigate([language, 'checkout', 'paymentmethod']);
        }
      }
    }
  }

  async navigateToSearchPage(own_domain, firm, language, search_term) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'search', await encodeURI(search_term)]);

        if (this.router.url === `/${firm.website_details.sitename}/${language}/search`) {
          window.location.reload();
        }
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'search', await encodeURI(search_term)]);

          if (this.router.url === `/${firm.website_details.sitename}/${language}/search`) {
            window.location.reload();
          }
        } else {
          this.router.navigate([language, 'search', await encodeURI(search_term)]);

          if (this.router.url === `/${language}/search`) {
            window.location.reload();
          }
        }
      }
    }
  }

  navigateToSettings(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'settings']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'settings']);
        } else {
          this.router.navigate([language, 'settings']);
        }
      }
    }
  }

  navigateToPrivacyPolicy(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'privacy-policy']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'privacy-policy']);
        } else {
          this.router.navigate([language, 'privacy-policy']);
        }
      }
    }
  }

  navigateToTermsOfUse(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'terms-of-use']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'terms-of-use']);
        } else {
          this.router.navigate([language, 'terms-of-use']);
        }
      }
    }
  }

  navigateToCookiePolicy(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'cookie-policy']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'cookie-policy']);
        } else {
          this.router.navigate([language, 'cookie-policy']);
        }
      }
    }
  }

  navigateToRecentOrders(own_domain, firm, language) {
    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'recent-orders']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'recent-orders']);
        } else {
          this.router.navigate([language, 'recent-orders']);
        }
      }
    }
  }

  navigateToThankPage(own_domain, firm, language, order_id) {
    if (!own_domain) {
      this.router.navigate([firm.website_details.sitename, language, 'checkout', 'order', order_id, 'processed']);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'checkout', 'order', order_id, 'processed']);
        } else {
          this.router.navigate([language, 'checkout', 'order', order_id, 'processed']);
        }
      }
    }
  }

  navigateToOverview(own_domain, firm, language) {
    if (!own_domain) {
      this.router.navigate([firm.website_details.sitename, language, 'checkout', 'overview']);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'checkout', 'overview']);
        } else {
          this.router.navigate([language, 'checkout', 'overview']);
        }
      }
    }
  }

  navigateToOrderPage(own_domain, firm, language, order_id) {
    if (!own_domain) {
      this.router.navigate([firm.website_details.sitename, language, 'order', order_id]);
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'order', order_id]);
        } else {
          this.router.navigate([language, 'order', order_id]);
        }
      }
    }
  }

  async navigateToMultiFirm(firm) {
    if (firm.website_details) {
      if (firm.website_details.custom_url == null || firm.website_details.custom_url == '') {
        await this.router.navigate([firm.website_details.sitename, this.translate.currentLang]);
      } else {
        window.location.href = firm.website_details.custom_url;
      }
    }
  }

  navigateToFirm(firm, firm_name) {
    if (firm.is_store == true) {
      this.router.navigate([firm_name]);
    } else {
      let languages;

      if (firm.languages) {
        languages = firm.languages;
      }

      if (firm.website_details.custom_url == null || firm.website_details.custom_url == '') {
        this.router.navigate([firm_name, languages[0].code.toLowerCase()]);
      } else {
        window.location.href = firm.website_details.custom_url;
      }
    }
  }
}
