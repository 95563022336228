<div class="square-btn clickable"
     [class.center]="centered"
     [class.disabled]="isDisabled()"
     (click)="clickHandler()"
     tappable>
  <div class="content" *ngIf="icon">
    <ion-icon src="../../assets/img/{{icon}}.svg"></ion-icon>
    <p>{{ label }}</p>
  </div>
</div>
