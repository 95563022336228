import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PickupPointStore {
  public pickupPoint: Record<any, any> | null = null;
  public updateEvent = new BehaviorSubject(null);

  private readonly KEY = 'selectedPickupPoint';

  constructor() {}

  public setSelectedPickupPoint<T>(pickupPoint: T): void {
    this.pickupPoint = pickupPoint;
    this.updateEvent.next(pickupPoint);
    return localStorage.setItem(this.KEY, JSON.stringify(pickupPoint));
  }

  public getSelectedPickupPoint<T>() {
    if (this.pickupPoint) {
      return this.pickupPoint;
    }

    return JSON.parse(localStorage.getItem(this.KEY));
  }
}
