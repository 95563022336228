import { Component, Input } from '@angular/core';
import { PickupPointStore } from '../../store/pickup-point.store';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pickup-point-selection',
  templateUrl: './pickup-point-selection.component.html',
  styleUrls: ['./pickup-point-selection.component.css'],
})
export class PickupPointSelectionComponent {
  @Input() pickupPoints: any;
  @Input() showSwitcher: boolean;

  selectedPickupPoint: any;
  selectedPickupPointId: number;

  constructor(
    private pickupPointStore: PickupPointStore,
    private dialogRef: MatDialogRef<PickupPointSelectionComponent>
  ) {
    const pickupPoint = this.pickupPointStore.getSelectedPickupPoint();
    if (pickupPoint) {
      this.selectPickupPoint(pickupPoint);
      this.pickupPointStore.setSelectedPickupPoint(pickupPoint);
    }
  }

  selectPickupPoint(pickup): void {
    this.selectedPickupPoint = pickup;
    this.selectedPickupPointId = pickup.id;
  }

  save(): void {
    this.pickupPointStore.setSelectedPickupPoint(this.selectedPickupPoint);
    this.dismiss(false);
  }

  dismiss(cancelled): void {
    this.dialogRef.close({ cancelled });
  }
}
