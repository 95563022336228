import { DeliveryMethods } from '../../../constants/delivery_methods';
import { AbstractDeliveryTimeService } from './abstract-delivery-time.service';

export class TableDeliveryTimeService extends AbstractDeliveryTimeService {
  getCustomDays(): any[] {
    return [];
  }

  getDeliveryMethod(): string {
    return DeliveryMethods.Table;
  }

  hasCustomDays(): boolean {
    return false;
  }

  hasOrderTimeslots(): boolean {
    return false;
  }

  isLimitedToSameDayOrdering(): boolean {
    return true;
  }

  selectDayFromPeriodsByDate(date: string): any {
    return null;
  }
}
