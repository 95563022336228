import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DateService } from '../../../../services/date.service';
import { DeliveryTimeServiceResolver } from '../../services/delivery-time-service.resolver';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DeliveryTimeServiceInterface } from '../../services/delivery-time-service.interface';
import { MatDatepicker } from '@angular/material/datepicker';
import { DeliveryMethodService } from '../../../../services/delivery-method.service';
import { DeliveryMethods } from '../../../../constants/delivery_methods';
import { PickupPointStore } from '../../../delivery-method/store/pickup-point.store';
import { DateAdapter } from '@angular/material/core';
import { FirmStore } from '../../../firm/store/firm.store';

@Component({
  selector: 'delivery-date-time-picker',
  templateUrl: './delivery-date-time-picker.component.html',
  styleUrls: ['./delivery-date-time-picker.component.scss'],
})
export class DeliveryDateTimePickerComponent implements OnInit, OnDestroy {
  @Input() isLimitedToSameDayOrdering: boolean;
  @Input() firm: any;
  @Input() showHeader = true;

  @ViewChild('dateDayPicker') dateDayPicker: MatDatepicker<any>;

  public firstAvailableTimeResponse: any;
  private deliveryTimeService: DeliveryTimeServiceInterface;

  public deliveryTime = new BehaviorSubject<string>(null);

  public loading = true;
  public dateDay = '';
  public dateTime = '';
  public dateDayModel = '';
  public dateHourModel = '';
  public dateMinutesModel = '';
  public minDate = '';
  public minTime = '';
  public shopIsClosed = false;
  public hoursArray: string[];
  public minutesArray: string[];
  public closingTime: string;
  public schedule: any;
  public isAsap: boolean;
  public asapSelected;

  private subscribers: any[] = [];

  constructor(
    private dateService: DateService,
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private translate: TranslateService,
    private deliveryMethodService: DeliveryMethodService,
    private pickupPointStore: PickupPointStore,
    private dateAdapter: DateAdapter<Date>,
    private firmStore: FirmStore
  ) {}

  ngOnInit(): void {
    this.subscribers[0] = this.deliveryTime.subscribe((deliveryTime: string) => {
      if (deliveryTime === 'asap') {
        this.isAsap = true;
        this.asapSelected = true;
        this.dateTime = this.translate.instant('ASAP');
        this.dateDay = this.translate.instant('TODAY');
        deliveryTime = this.firstAvailableTimeResponse.timestamp;
      } else {
        this.isAsap = false;
        this.asapSelected = false;

        if (this.isToday()) {
          this.dateDay = this.translate.instant('TODAY');
        } else {
          this.dateDay = this.dateService.getDateDay(deliveryTime);
        }

        this.dateTime = this.dateService.formatDateTime(deliveryTime);
      }

      let hour = moment(deliveryTime).format('HH');
      let minutes = moment(deliveryTime).format('mm');
      minutes = this.dateService.transformTime(minutes);

      if (minutes === '60') {
        hour = moment(Number(hour)).add('hours', Number(hour)).format('HH');
        minutes = '00';
      }

      this.setSelected(deliveryTime, hour, minutes);
      if (this.dateDayModel) this.setTimeIntervals();
    });

    const language = this.firmStore.language;
    if (language) {
      this.dateAdapter.setLocale(language);
    }
    this.schedule = this.getSchedule();
    this.calculateDateTime();
  }

  public ngOnDestroy(): void {
    this.subscribers.forEach((subscriber: any) => subscriber.unsubscribe());
  }

  private calculateDateTime(): void {
    this.deliveryTimeServiceResolver
      .resolveDeliveryTimeService(this.firm)
      .then(async (service: DeliveryTimeServiceInterface) => {
        this.loading = true;

        this.deliveryTimeService = service;
        this.firstAvailableTimeResponse = await service.calculateTimestamp();
        this.minDate = this.firstAvailableTimeResponse.timestamp;

        let deliveryTime = await service.getDeliveryTime();

        if (!deliveryTime && this.firm.asap) {
          deliveryTime = 'asap';
        }

        if (!deliveryTime) {
          deliveryTime = moment().format();
        }

        this.deliveryTime.next(deliveryTime);

        if (deliveryTime === 'asap' && this.dateService.isToday(this.firstAvailableTimeResponse.timestamp)) {
          service.updateDeliveryTime(deliveryTime);
        } else {
          if (
            moment(deliveryTime).unix() < moment(this.firstAvailableTimeResponse.timestamp).unix() ||
            deliveryTime === 'asap'
          ) {
            this.deliveryTime.next(moment(this.firstAvailableTimeResponse.timestamp).format());
            this.updateDelay();
          }
        }

        if (this.isLimitedToSameDayOrdering && !this.dateService.isToday(this.firstAvailableTimeResponse.timestamp)) {
          this.shopIsClosed = true;
          this.isLimitedToSameDayOrdering = false;
        }

        this.loading = false;
      });
  }

  public updateDelay(): void {
    this.deliveryTimeService?.updateDeliveryTime(moment(this.deliveryTime.value).format());
  }

  public async handleOnDateDayChange(): Promise<void> {
    if (this.deliveryTime.value === 'asap' && this.dateService.isToday(this.dateDayModel)) {
      return;
    }

    if (this.deliveryTime.value === 'asap') {
      this.deliveryTime.next(moment(this.minTime, 'HH:mm').format());
    }

    const day = moment(this.dateDayModel).format('DD/MM/YYYY');
    const time = `${this.dateHourModel}:${this.dateMinutesModel}`;

    const deliveryTime = moment(`${day} - ${time}`, 'DD/MM/YYYY - HH:mm').format();

    if (!(await this.checkIfOrderingTimeIsValid(deliveryTime))) {
      return;
    }

    this.deliveryTime.next(deliveryTime);
    this.updateDelay();
  }

  public async handleOnDateTimeChange(): Promise<void> {
    if (this.deliveryTime.value === 'asap') {
      this.deliveryTime.next(this.firstAvailableTimeResponse.timestamp);
    }

    let day = moment(this.deliveryTime.value).format('DD/MM/YYYY');

    if (this.isLimitedToSameDayOrdering) {
      day = moment().format('DD/MM/YYYY');
    }

    const time = `${this.dateHourModel}:${this.dateMinutesModel}`;
    const deliveryTime = moment(`${day} - ${time}`, 'DD/MM/YYYY - HH:mm').format();

    if (!(await this.checkIfOrderingTimeIsValid(deliveryTime))) {
      return;
    }

    this.deliveryTime.next(deliveryTime);
    this.updateDelay();
  }

  async handleAsapChange() {
    if (this.asapSelected) {
      this.deliveryTime.next('asap');
      this.deliveryTimeService?.updateDeliveryTime('asap');
    } else {
      await this.handleOnDateTimeChange();
    }
  }

  private async checkIfOrderingTimeIsValid(time: string): Promise<boolean> {
    const isOrderingTimeValid = await this.deliveryTimeService.isOrderingTimeValid(time);

    if (!isOrderingTimeValid) {
      this.shopIsClosed = true;
      return false;
    }

    return true;
  }

  getSchedule() {
    switch (this.deliveryMethodService.currentDeliveryMethod) {
      case DeliveryMethods.Pickup:
        return this.firm.periods.pickup_hours;
      case DeliveryMethods.PickupPoint:
        if (!this.pickupPointStore.pickupPoint) {
          const pickupPoint = this.pickupPointStore.getSelectedPickupPoint();
          this.pickupPointStore.setSelectedPickupPoint(pickupPoint);
        }
        return this.pickupPointStore.pickupPoint.periods.pickup_hours;
      case DeliveryMethods.Delivery:
        return this.firm.periods.delivery_hours;
    }
  }

  setTimeIntervals(): void {
    const day = this.dateService.getSelectedDay(this.dateDayModel, this.schedule);
    if (!this.dateService.isClosed(day)) {
      this.shopIsClosed = false;

      this.minTime = this.isToday()
        ? moment(this.firstAvailableTimeResponse.timestamp).format('HH:mm')
        : this.dateService.getOpeningTime(day);
      this.closingTime = this.dateService.getClosingTime(day);

      this.setSelectableHours(this.minTime, this.closingTime);
      this.prepareMinutesArray();
    } else {
      this.shopIsClosed = true;
    }
  }

  isToday() {
    return this.dateService.isToday(this.dateDayModel);
  }

  setSelectableHours(openingTime, closingTime) {
    this.hoursArray = this.dateService.getTimeArray(
      this.dateService.splitHours(openingTime),
      this.dateService.splitHours(closingTime)
    );
  }

  prepareMinutesArray() {
    const openingHour = this.dateService.transformTime(String(this.dateService.splitHours(this.minTime)));
    const closingHour = this.dateService.transformTime(String(this.dateService.splitHours(this.closingTime)));

    const openingMinutes = this.dateService.splitMinutes(this.minTime);
    const closingMinutes = this.dateService.splitMinutes(this.closingTime);

    this.setSelectableMinutes(openingHour, openingMinutes, closingHour, closingMinutes);
  }

  setSelected(date: string, hour: string, minutes: string) {
    if (date === 'asap') {
      date = this.firstAvailableTimeResponse.timestamp;
    }

    this.dateDayModel = date;
    this.dateHourModel = hour;
    this.dateMinutesModel = minutes;
  }

  setSelectableMinutes(openingHour, openingMinutes, closingHour, closingMinutes) {
    if (this.dateHourModel === openingHour && this.dateHourModel === closingHour) {
      this.minutesArray = this.dateService.getTimeArray(openingMinutes, closingMinutes);
    } else if (this.dateHourModel === openingHour) {
      this.minutesArray = this.dateService.getTimeArray(openingMinutes, 59);
    } else if (this.dateHourModel === closingHour) {
      this.minutesArray = this.dateService.getTimeArray(0, closingMinutes);
    } else {
      this.minutesArray = this.dateService.getTimeArray(0, 59);
    }
  }

  setEarliestHour() {
    if (this.hoursArray) {
      this.dateHourModel = this.hoursArray[0];
    }
  }

  setEarliestTime() {
    if (this.minutesArray) {
      this.dateMinutesModel = this.minutesArray[0];
    }
  }

  async dateChangedHandler() {
    this.setTimeIntervals();
    await this.handleOnDateDayChange();
  }

  async hourChangedHandler() {
    this.prepareMinutesArray();
    this.setEarliestTime();
    await this.handleOnDateTimeChange();
  }

  openDayPicker() {
    this.dateDayPicker.open();
  }
}
