<div class="eo-modal-container">
  <div
    class="header-container"
    [class.header-bg]="header"
  >
    <p *ngIf="header" class="header">{{ header }}</p>
    <div [ngClass]="cancelPosition" (click)="cancel()">
      <i class="fas fa-times-circle close"></i>
    </div>
  </div>
  <div class="content-container">
    <p *ngIf="subHeader" class="subheader">{{ subHeader }}</p>

    <ng-content></ng-content>
  </div>

  <div *ngIf="displayButton" class="footer-container" [ngClass]="footerPosition">
    <button
      (click)="submitHandler()"
      [disabled]="disableButton">
      {{ buttonLabel }}
    </button>

    <button class="back-button"
      *ngIf="displayBackButton"
      (click)="cancelledHandler()">
      {{ 'BUTTONS.BACK' | translate }}
    </button>
  </div>
</div>
