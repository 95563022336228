import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { RouteService } from '../services/route.service';
import { OrderService } from '../services/order.service';
import { Country } from '../core/models/country';
import { Address } from '../core/models/address';
import { DialogsService } from '../services/dialogs.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  user: any;
  userId: string;
  firm: any;
  firm_name: any;
  language: any;
  own_domain: boolean;
  countries: Country[];
  addressList: Address[];

  constructor(
    route: ActivatedRoute,
    public firmService: FirmService,
    private loader: LoaderService,
    private customerService: CustomerService,
    private routeService: RouteService,
    private orderService: OrderService,
    private dialogsService: DialogsService,
    private translate: TranslateService
  ) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }

  /* Get order */
  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.initData();
    });
  }

  /* Get all data */
  async initData() {
    if (this.firmService.checkWebsite(this.firm, this.language)) {
      this.userId = await this.getUserIdFromStorage();

      if (!this.userId) {
        this.user = undefined;
      } else {
        this.loader.present();
        await this.customerService.linkFirm(
          this.firm,
          this.userId,
          this.language,
          this.firm.distributor,
          this.own_domain
        );
        this.user = await this.getUser();
        if (!this.user.country) {
          this.user.country = [];
        }
        this.setAddressList();
        this.loader.dismiss();
      }
    }
  }

  async getUserIdFromStorage() {
    const login: any = await JSON.parse(localStorage.getItem('login'));
    return login?.id;
  }

  getUser() {
    return this.customerService.get(this.firm, this.userId, this.language, this.firm.distributor);
  }

  setUser(user) {
    this.user = user;
    this.userId = user.id;
    if (!this.user.country) {
      this.user.country = [];
    }

    if (this.routeService.redirectTo && this.routeService.redirectTo === 'checkout' && this.firm.is_offline === false) {
      this.orderService.navigateToCheckout('user', this.firm, this.own_domain, this.language);
      this.routeService.redirectTo = undefined;
    }
  }

  async setAddressList() {
    this.addressList = await this.getAddresses();
  }

  selectAddress(address) {
    if (!address.is_default) {
      address.is_default = true;
      this.updateDefaultAddressInList(address);
      this.updateAddress(address).then(() => {
        this.setAddressList().then(() => {
          this.setDefaultAddress();
        });
      });
    }
  }

  updateDefaultAddressInList(address) {
    this.addressList.forEach((a) => {
      if (a.id !== address.id) {
        a.is_default = false;
      } else {
        a.is_default = true;
      }
    });
  }

  presentEditAddressModal(address) {
    const header = this.translate.instant('CHANGE_ADDRESS');
    const subHeader = this.translate.instant('EDIT_FIELDS_BELOW');
    const buttonLabel = this.translate.instant('BUTTONS.SAVE');

    const dialog = this.dialogsService.openAddressModal(header, subHeader, buttonLabel, this.firm.countries, address);
    dialog.subscribe(({ data }) => {
      if (data && !data.cancelled) {
        if (address.is_default !== data.address.is_default) {
          this.updateDefaultAddressInList(data.address);
        }

        this.updateAddress(data.address).then(() => {
          this.setAddressList().then(() => {
            this.setDefaultAddress();
          });
        });
      }
    });
  }

  presentAddAddressModal() {
    const header = this.translate.instant('ADD_ADDRESS');
    const subHeader = this.translate.instant('FILL_IN_ADDRESS');
    const buttonLabel = this.translate.instant('ADD_ADDRESS');

    const dialog = this.dialogsService.openAddressModal(header, subHeader, buttonLabel, this.firm.countries);
    dialog.subscribe(({ data }) => {
      if (data && !data.cancelled) {
        if (data.address.is_default) {
          this.updateDefaultAddressInList(data.address);
        }

        this.addAddress(data.address).then(() => {
          this.setAddressList().then(() => {
            this.setDefaultAddress();
          });
        });
      }
    });
  }

  setDefaultAddress() {
    const defaultAddress = this.addressList.find((address) => address.is_default);
    if (defaultAddress) {
      this.setUserAddress(defaultAddress);
    } else {
      this.resetUserAddress();
    }
  }

  resetUserAddress() {
    this.user.street = null;
    this.user.house_number = null;
    this.user.zipcode = null;
    this.user.locality = null;
    this.user.country = null;
  }

  setUserAddress(address) {
    this.user.street = address.street;
    this.user.house_number = address.bus ? `${address.house_number} ${address.bus}` : address.house_number;
    this.user.zipcode = address.zip_code;
    this.user.locality = address.locality;
    this.user.country = address.country;
  }

  async getAddresses(): Promise<Array<Address>> {
    return this.customerService.getAllCustomerAddresses(this.user.id, this.user.uuid, this.firm.id, this.language);
  }

  async updateAddress(address) {
    return this.customerService.updateCustomerAddress(
      this.user.id,
      this.user.uuid,
      address,
      this.firm.id,
      this.language
    );
  }

  async addAddress(address) {
    return await this.customerService.addCustomerAddress(
      this.user.id,
      this.user.uuid,
      address,
      this.firm.id,
      this.language
    );
  }

  async updateBusinessDetails(businessDetails) {
    return this.customerService.updateBusinessDetails(
      this.user.id,
      this.user.uuid,
      businessDetails,
      this.firm.id,
      this.language
    );
  }

  presentBusinessDetailsModal() {
    const header = this.translate.instant('ADD_COMPANY_DETAILS');
    const subHeader = this.translate.instant('FILL_IN_COMPANY_DETAILS');
    const buttonLabel = this.translate.instant('ADD_COMPANY_DETAILS');

    const dialog = this.dialogsService.openBusinessDetailsModal(header, subHeader, buttonLabel, this.user);
    dialog.subscribe(({ data }) => {
      if (data && !data.cancelled) {
        this.updateBusinessDetails(data.businessDetails).then((success) => {
          this.getUser().then((user) => {
            this.user = user;
          });
        });
      }
    });
  }
}
