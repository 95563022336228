import { Injectable } from '@angular/core';
import { DeliveryTimeStore } from '../store/delivery-time.store';
import { PickupPointStore } from '../../delivery-method/store/pickup-point.store';
import { DateService } from '../../../services/date.service';
import { BasketItemsService } from '../../../services/basket-items.service';
import { ProductService } from '../../../services/products.service';
import { OrderService } from '../../../services/order.service';
import { CustomerService } from '../../../services/customer.service';
import { DeliveryMethodService } from '../../../services/delivery-method.service';
import { FirmService } from '../../../services/firm.service';
import { DeliveryTimeServiceInterface } from './delivery-time-service.interface';
import { DeliveryMethods } from '../../../constants/delivery_methods';
import { TableDeliveryTimeService } from './table-delivery-time.service';
import { HomeDeliveryTimeService } from './home-delivery-time.service';
import { PickupTimeService } from './pickup-time.service';
import { TimestampService } from '../../../services/timestamp.service';
import { ProductStore } from '../../product/product.store';
import { PickupPointTimeService } from './pickup-point-time.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryTimeServiceResolver {
  constructor(
    private deliveryTimeStore: DeliveryTimeStore,
    private pickupPointStore: PickupPointStore,
    private dateService: DateService,
    private basketItemsService: BasketItemsService,
    private productService: ProductService,
    private orderService: OrderService,
    private customerService: CustomerService,
    private deliveryMethodService: DeliveryMethodService,
    private firmService: FirmService,
    private timestampService: TimestampService,
    private productStore: ProductStore
  ) {}

  public async resolveDeliveryTimeService(firm: any): Promise<DeliveryTimeServiceInterface> {
    const deliveryMethod = await this.deliveryMethodService.getSelectedDeliveryMethod();

    if (deliveryMethod === DeliveryMethods.Delivery) {
      return new HomeDeliveryTimeService(
        this.deliveryTimeStore,
        this.dateService,
        this.basketItemsService,
        this.productService,
        this.orderService,
        this.customerService,
        this.firmService,
        this.timestampService,
        this.productStore,
        firm
      );
    }

    if (deliveryMethod === DeliveryMethods.PickupPoint) {
      const service = new PickupPointTimeService(
        this.deliveryTimeStore,
        this.dateService,
        this.basketItemsService,
        this.productService,
        this.orderService,
        this.customerService,
        this.firmService,
        this.timestampService,
        this.productStore,
        firm
      );

      service.setStore(this.pickupPointStore);

      return service;
    }

    if (deliveryMethod === DeliveryMethods.Table) {
      return new TableDeliveryTimeService(
        this.deliveryTimeStore,
        this.dateService,
        this.basketItemsService,
        this.productService,
        this.orderService,
        this.customerService,
        this.firmService,
        this.timestampService,
        this.productStore,
        firm
      );
    }

    return new PickupTimeService(
      this.deliveryTimeStore,
      this.dateService,
      this.basketItemsService,
      this.productService,
      this.orderService,
      this.customerService,
      this.firmService,
      this.timestampService,
      this.productStore,
      firm
    );
  }
}
