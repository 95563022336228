<!-- Header -->
<div
  class="multifirm_header flex align-items-center flex-end"
  [style.background-image]="'url('+ distributor?.header_logo +')'">

  <div
    class="order-now-button clickable"
    (click)="openMethodSelectionModal()"
    tappable>
    {{ 'ORDER_NOW' | translate }}
  </div>
</div>

<div class="content flex">
  <div class="sidebar">
    <div class="methods-container">
      <p class="font-15">{{ 'HOW_WOULD_YOU_LIKE_TO_ORDER' | translate }}</p>
      <method-grid template="list"></method-grid>
    </div>
  </div>

  <div class="map">
    <agm-map [fitBounds]="mapBounds" [disableDefaultUI]="true">
      <agm-marker *ngFor="let firm of firms"
        [latitude]="firm?.geocode?.lat"
        [longitude]="firm?.geocode?.long"
        [iconUrl]="mapMarker">
        <agm-info-window>
          <div class="info-window">
            <p class="title bold font-17">{{ firm?.name }}</p>
            <p class="address">{{ firm?.address }}, {{ firm?.zipcode }} {{ firm?.locality }}</p>
          </div>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>
