<eo-modal
  [header]="header"
  [subHeader]="subHeader"
  [buttonLabel]="buttonLabel"
  [disableButton]="disableButton"
  (submitted)="saveHandler()">
  <address-form
    [address]="address"
    [showAddressName]="true"
    [showAddressDefault]="true"
    (onChange)="handleAddressFormOnChange($event)">
  </address-form>
</eo-modal>
