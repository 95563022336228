<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [buttonLabel]="'CONTINUE' | translate"
  [displayButton]="true"
  (submitted)="save()">

  <method-delivery-switcher *ngIf="showSwitcher"></method-delivery-switcher>

  <div class="eo-modal-header flex align-items-center">
    <ion-icon src="../../assets/img/pickupPoint.svg"></ion-icon>
    <div class="ml-10">
      <p>{{ 'PICKUP_POINT' | translate }}</p>
      <p class="font-13">{{ 'SELECT_A_PICKUP_POINT' | translate }}</p>
    </div>
  </div>

  <div scrollx="true" class="scroll-x">
    <mat-radio-group [(ngModel)]="selectedPickupPointId">
      <ng-container *ngFor="let pickup of pickupPoints">
        <div
          class="radio-container eo-radio-button flex align-items-center justify-space-between clickable"
          *ngIf="!pickup?.main_branch"
          [class.disabled]="pickup?.temporary_inactive"
          (click)="selectPickupPoint(pickup)">
          <div class="title-container">
            <strong>{{ pickup?.name }}</strong>
            <p class="ellipsis font-12">({{ pickup?.address }}, {{ pickup?.zipcode}} {{ pickup?.locality }})</p>
          </div>

          <div class="radio-button-container flex ion-justify-content-end ion-align-items-center">
            <mat-radio-button [value]="pickup?.id"></mat-radio-button>
          </div>
        </div>
      </ng-container>
    </mat-radio-group>
  </div>

</eo-modal>
