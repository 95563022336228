import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eo-square-btn',
  templateUrl: './square-btn.component.html',
  styleUrls: ['./square-btn.component.scss'],
})
export class SquareBtnComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() centered: boolean;

  @Output() clicked = new EventEmitter<any>();

  isDisabled() {
    return this.disabled;
  }

  clickHandler() {
    this.clicked.emit();
  }
}
