import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

import { FirmService } from '../services/firm.service';
import { SlugifyService } from '../services/slugify.service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { SearchService } from '../services/search.service';
import { DialogsService } from '../services/dialogs.service';
import { Subscription } from 'rxjs';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  own_domain: boolean;
  firm: any;
  search_term: any;

  items: any;
  initItems: any;
  message: any;

  firm_name: any;
  cat_id: any;
  cat_name: any;
  language: any;

  pickup_label: any;
  vat_delivery_method: any;
  openVat: boolean = false;
  deliveryTimeSubscription: Subscription;

  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public loader: LoaderService,
    public firmService: FirmService,
    public searchService: SearchService,
    public productService: ProductService,
    public slug: SlugifyService,
    public dialogsService: DialogsService,
    private deliveryTimeStore: DeliveryTimeStore
  ) {
    window.scrollTo(0, 0);

    let search_term = route.snapshot.params.search_term;
    this.search_term = decodeURI(search_term);
    this.firm_name = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;

    this.deliveryTimeSubscription = this.deliveryTimeStore.updateEvent.subscribe(() => {
      this.setAvailableData();
    });
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.initData();
    });
  }

  async initData() {
    if(this.firmService.checkWebsite(this.firm, this.language)) {
      this.items = await this.searchService.get(this.firm, this.search_term, this.language);
    }
    this.setAvailableData();
  }

  setAvailableData() {
    this.items.forEach(async (item) => {
      if (item.category.parent) {
        item.isAvailable = this.productService.isAvailable(item.category.parent.availabilities);
        item.firstAvailableDate = await this.productService.getFirstAvailableDate(item.category.parent.availabilities);
      } else {
        item.isAvailable = this.productService.isAvailable(item.category.availabilities);
        item.firstAvailableDate = await this.productService.getFirstAvailableDate(item.category.availabilities);
      }
    });
  }

  vatDeliveryMethodChanged(event) {
    this.vat_delivery_method = event;
  }

  ngOnDestroy() {
    this.deliveryTimeSubscription.unsubscribe();
  }
}
