import { Component, Input, OnInit } from '@angular/core';
import { DeliveryTimeServiceInterface } from '../../services/delivery-time-service.interface';
import { DeliveryTimeServiceResolver } from '../../services/delivery-time-service.resolver';
import * as moment from 'moment';

@Component({
  selector: 'custom-days-picker',
  templateUrl: './custom-days-picker.component.html',
  styleUrls: ['./custom-days-picker.component.css'],
})
export class CustomDaysPickerComponent implements OnInit {
  @Input() firm: any;

  public customDateModel = '';
  public customDates = [];

  private deliveryTimeService: DeliveryTimeServiceInterface;

  constructor(private deliveryTimeServiceResolver: DeliveryTimeServiceResolver) {}

  public ngOnInit(): void {
    this.deliveryTimeServiceResolver
      .resolveDeliveryTimeService(this.firm)
      .then(async (service: DeliveryTimeServiceInterface) => {
        this.deliveryTimeService = service;
        this.customDateModel = service.getDeliveryTime();
        this.customDates = service.getCustomDays().map((customDay: any) => ({
          value: customDay.date,
          text: moment(customDay.date).format('DD/MM/YYYY - HH:mm'),
        }));
      });
  }

  public handleOnChange(): void {
    this.deliveryTimeService?.updateDeliveryTime(this.customDateModel);
  }
}
