<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [buttonLabel]="'CONTINUE' | translate"
  [displayButton]="true"
  [disableButton]="disableButton"
  (submitted)="handleOnSubmit()">

  <method-delivery-switcher *ngIf="showSwitcher"></method-delivery-switcher>

  <div class="eo-modal-header flex align-items-center">
    <ion-icon src="../../assets/img/delivery.svg"></ion-icon>
    <div class="ml-10">
      <p>{{ 'DELIVERY' | translate }}</p>
      <p *ngIf="!addresses" class="font-13">{{ 'FILL_IN_ADDRESS' | translate }}</p>
      <p *ngIf="addresses?.length > 0" class="font-13">{{ 'SELECT_AN_ADDRESS' | translate }}</p>
    </div>

    <button
      *ngIf="addresses?.length > 0"
      class="add-address btn-fill custom-btn flex align-items-center font-13"
      (click)="presentAddDeliveryAddressModal()">
      <div class="add-icon mr-5">
        <i class="fas fa-plus-circle add-icon"></i>
      </div>
      {{ 'ADD_ADDRESS' | translate }}
    </button>
  </div>

  <div *ngIf="addresses?.length > 0; else addressForm">
    <div class="address-container">
      <eo-slider>
        <eo-address
          *ngFor="let address of addresses"
          [address]="address"
          [displaySelected]="isAddressSelected(address)"
          (selected)="selectAddress($event)"
          (edit)="presentEditAddressModal($event)">
        </eo-address>
        <div class="eo-card add-card"
             (click)="presentAddDeliveryAddressModal()">
          <div class="address-content flex font-13">
            <div class="add-icon">
              <i class="fas fa-plus-circle add-icon"></i>
            </div>
            <strong>{{ 'ADD_ADDRESS' | translate }}</strong>
          </div>
        </div>
      </eo-slider>
    </div>
  </div>

  <ng-template #addressForm>
      <address-form
        [address]="address"
        [showAddressName]="false"
        [showAddressDefault]="false"
        (onChange)="handleAddressFormOnChange($event)">
      </address-form>
  </ng-template>
</eo-modal>
