import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeliveryMethods } from '../constants/delivery_methods';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryTimeServiceInterface } from '../modules/delivery-time/services/delivery-time-service.interface';
import { CustomerStore } from '../modules/customer/store/customer.store';
import { DialogsService } from '../services/dialogs.service';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';
import { DeliveryMethodService } from '../services/delivery-method.service';
import { TableDeliveryMethodStore } from '../modules/delivery-method/store/table-delivery-method.store';
import { DeliveryTimeServiceResolver } from '../modules/delivery-time/services/delivery-time-service.resolver';
import { LoaderService } from '../services/loader.service';
import { FirmStore } from '../modules/firm/store/firm.store';
import { RouteService } from '../services/route.service';
import { FirmService } from '../services/firm.service';
import { Router } from '@angular/router';
import { DeliveryTimePickerService } from '../modules/delivery-time/services/delivery-time-picker.service';
import { DeliveryAddressService } from '../modules/delivery-method/services/delivery-address.service';

@Component({
  selector: 'sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
  @Input() firm: any;

  public readonly DELIVERY_METHODS = DeliveryMethods;
  public deliveryMethods: any[] = [];
  public selectedDeliveryMethod = '';
  public deliveryTimeService: DeliveryTimeServiceInterface;

  public deliveryAddress$ = this.customerStore.deliveryAddress$;
  public currentDeliveryTime$ = new BehaviorSubject<string>('');
  public tableNumber$ = new BehaviorSubject<string>('');

  public hasParent: boolean;
  public parentFirm: any;
  public parentShowDeliveryMethodFirst: boolean;

  constructor(
    private translate: TranslateService,
    private customerStore: CustomerStore,
    private deliveryMethodService: DeliveryMethodService,
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private deliveryTimeStore: DeliveryTimeStore,
    private tableMethodStore: TableDeliveryMethodStore,
    private modalService: DialogsService,
    private loader: LoaderService,
    private firmStore: FirmStore,
    private routeService: RouteService,
    private firmService: FirmService,
    private router: Router,
    private deliveryTimePickerService: DeliveryTimePickerService,
    private deliveryAddressService: DeliveryAddressService
  ) {}

  public ngOnInit(): void {
    this.listenToEvents();
    this.setTableNumber();
    this.hasParent = this.firmStore.hasParent;
    this.parentFirm = this.firmStore.parentFirm;
    const selectedAddress = this.customerStore.getSelectedAddress();
    if (selectedAddress) {
      this.customerStore.setSelectedAddress(selectedAddress);
    }
  }

  ngOnChanges(): void {
    this.deliveryMethodService.getSelectedDeliveryMethod().then((method) => {
      this.handleDeliveryMethodChange(method);
    });
  }

  handleDeliveryMethodChange(method) {
    this.deliveryTimeServiceResolver
      .resolveDeliveryTimeService(this.firm)
      .then(async (service: DeliveryTimeServiceInterface) => {
        this.deliveryTimeService = service;

        if (!this.parentShowDeliveryMethodFirst && this.firmStore.currentFirm.parent_firm) {
          await this.setParentShowDeliveryMethodFirst();
        }

        const deliveryMethod = await this.deliveryMethodService.getSelectedDeliveryMethod();
        if (
          !deliveryMethod &&
          this.firm.theme.show_delivery_method_first &&
          this.deliveryTimeStore.modalShown$.getValue() === false
        ) {
          return this.openMethodSelectionModal();
        }

        this.setActiveDeliveryMethods(method);

        let deliveryTime = this.deliveryTimeStore.getDeliveryTime();
        if (
          !deliveryTime &&
          this.firm.theme.show_timepicker_modal &&
          this.deliveryTimeStore.modalShown$.getValue() === false
        ) {
          return this.openDateTimeModal();
        }

        if (!deliveryTime && !this.firm.theme.show_timepicker_modal) {
          await this.deliveryTimePickerService.selectFirstAvailableTime();
          deliveryTime = this.deliveryTimeStore.getDeliveryTime();
        }

        this.setCurrentDeliveryTime(deliveryTime);
      });
  }

  async setParentShowDeliveryMethodFirst() {
    await this.firmService.getAllFirms(this.firmStore.currentFirm.parent_firm?.uuid);
    this.parentShowDeliveryMethodFirst = this.firmStore.distributor.theme.show_delivery_method_first;
  }

  displayLocationInfo(): boolean {
    return this.hasParent && this.firm?.theme?.show_location_info_header;
  }

  private setTableNumber(): void {
    const tableNumber = this.tableMethodStore.getSelectedTableNumber();
    this.tableNumber$.next(tableNumber);
  }

  private listenToEvents(): void {
    this.deliveryTimeStore.updateEvent.subscribe((deliveryTime: string) => {
      this.setCurrentDeliveryTime(deliveryTime);
    });

    this.deliveryMethodService.selectDeliveryMethodUpdate.subscribe((method) => {
      this.handleDeliveryMethodChange(method);
    });

    this.tableMethodStore.updateSelectedTableNumberEvent.subscribe((tableNumber: any) => {
      this.tableNumber$.next(tableNumber);
    });
  }

  public setCurrentDeliveryTime(deliveryTime: string): void {
    if (deliveryTime === 'asap') {
      this.currentDeliveryTime$.next(`${this.translate.instant('TODAY')} - ${this.translate.instant('ASAP')}`);
      return;
    }

    if (!deliveryTime) {
      this.currentDeliveryTime$.next(this.translate.instant('CHOOSE_TIME'));
      return;
    }

    this.currentDeliveryTime$.next(this.deliveryTimeService?.formatDeliveryTime(deliveryTime));
  }

  public setActiveDeliveryMethods(method): void {
    this.deliveryMethods = [];
    this.selectedDeliveryMethod = method;

    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      this.setActiveDeliveryMethodsForMultifirm(method);
    } else {
      this.setActiveDeliveryMethodsForSingleFirm(method);
    }

    const selected = this.deliveryMethods.filter((deliveryMethod: any) => deliveryMethod.selected);

    if (selected.length === 0 && method !== '') {
      this.deliveryMethodService.updateSelectedDeliveryMethod(this.deliveryMethods[0].name);
    }
  }

  public setActiveDeliveryMethodsForSingleFirm(method): void {
    if (
      this.deliveryMethodService.isPickupMethodActiveForFirm(this.firm) &&
      !this.deliveryMethodService.isPickupMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.Pickup,
        name: DeliveryMethods.Pickup,
        icon: 'takeaway',
      });
    }

    if (
      this.deliveryMethodService.isDeliveryMethodActive(this.firm) &&
      !this.deliveryMethodService.isDeliveryMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.Delivery,
        name: DeliveryMethods.Delivery,
        icon: 'delivery',
      });
    }

    if (
      this.deliveryMethodService.isPickupPointMethodActive(this.firm.pickup, this.firm.pickup_points) &&
      !this.deliveryMethodService.isPickupMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.PickupPoint,
        name: DeliveryMethods.PickupPoint,
        icon: 'pickuppoint',
      });
    }

    if (
      this.deliveryMethodService.isTableMethodActive(this.firm) &&
      !this.deliveryMethodService.isTableMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.Table,
        name: DeliveryMethods.Table,
        icon: 'qrcode',
      });
    }
  }

  public setActiveDeliveryMethodsForMultifirm(method): void {
    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.Pickup)) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.Pickup,
        name: DeliveryMethods.Pickup,
        icon: 'takeaway',
      });
    }

    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.Delivery)) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.Delivery,
        name: DeliveryMethods.Delivery,
        icon: 'delivery',
      });
    }

    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.PickupPoint)) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.PickupPoint,
        name: DeliveryMethods.PickupPoint,
        icon: 'pickuppoint',
      });
    }

    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.Table)) {
      this.deliveryMethods.push({
        selected: method === DeliveryMethods.Table,
        name: DeliveryMethods.Table,
        icon: 'qrcode',
      });
    }
  }

  public mustDisplayDeliveryAddress(): boolean {
    return this.selectedDeliveryMethod === DeliveryMethods.Delivery;
  }

  public mustDisplayTableNumber(): boolean {
    return this.selectedDeliveryMethod === DeliveryMethods.Table;
  }

  public openMethodSelectionModal(): void {
    this.deliveryTimeStore.modalShown$.next(true);
    this.modalService.openMethodSelectionModal();
  }

  public hasMoreThanTwoDeliveryMethodAndOneMethodIsSelected(): boolean {
    return this.deliveryMethods.length > 2 && this.selectedDeliveryMethod && this.selectedDeliveryMethod !== '';
  }

  public hasMoreThanTwoDeliveryMethodAndNoMethodIsSelected(): boolean {
    return this.deliveryMethods.length > 2 && (!this.selectedDeliveryMethod || this.selectedDeliveryMethod === '');
  }

  public hasOneDeliveryMethodSelectedButNotEatIn(): boolean {
    return (
      this.selectedDeliveryMethod &&
      this.selectedDeliveryMethod !== '' &&
      this.selectedDeliveryMethod !== DeliveryMethods.Table
    );
  }

  public async handleOnDeliveryMethodTap(): Promise<void> {
    if (this.selectedDeliveryMethod === DeliveryMethods.Delivery) {
      return this.openDeliveryAddressModal(true);
    }

    if (this.selectedDeliveryMethod === DeliveryMethods.Pickup) {
      return this.openMethodSelectionModal();
    }

    if (this.selectedDeliveryMethod === DeliveryMethods.PickupPoint) {
      this.modalService.openPickPointSelectionModal(this.firm.pickup_points, true);
      return;
    }

    if (this.selectedDeliveryMethod === DeliveryMethods.Table) {
      if (this.firm.custom_table_methods?.length > 0 || this.firm.table_numbers?.length > 0) {
        this.modalService.openTableSelectionModal(this.firm.custom_table_methods, this.firm, true);
        return;
      }

      await this.tableMethodStore.resetSelectedTableMethod();
      await this.tableMethodStore.resetSelectedTableNumber();
      return this.openMethodSelectionModal();
    }
  }

  public openDeliveryAddressModal(showSwitcher: boolean): void {
    const modal = this.deliveryAddressService.openAddressSelectionModal(
      this.customerStore.addresses,
      showSwitcher,
      this.customerStore.selectedAddress
    );

    const sub = modal.subscribe(async ({ data }) => {
      sub.unsubscribe();

      if (!data || data.cancelled) {
        return;
      }

      let firm: any;
      this.loader.present();

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        const response = await this.firmService.getFirmByAddress(data);

        if (!response) {
          return this.loader.dismiss();
        }

        firm = await this.firmService.getFirmById(response.id);
        this.firmStore.setLanguage(firm.languages[0]?.code);
      }

      this.customerStore.setSelectedAddress(data);

      this.deliveryMethodService.updateSelectedDeliveryMethod(DeliveryMethods.Delivery);

      this.loader.dismiss();

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        return this.openDateTimeModal(firm);
      }

      return this.openDateTimeModal();
    });
  }

  async openDateTimeModal(selectedFirm?) {
    this.deliveryTimeStore.modalShown$.next(true);

    if (!selectedFirm) {
      selectedFirm = this.firm;
    }

    if (!selectedFirm.theme.show_timepicker_modal) {
      await this.deliveryTimePickerService.selectFirstAvailableTime();

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        return this.openShop(selectedFirm);
      }

      return;
    }

    this.openDeliveryDateTimeModal(selectedFirm);
  }

  openDeliveryDateTimeModal(firm?) {
    const modal = this.deliveryTimePickerService.openDeliveryDateTimeModal();

    const sub = modal.subscribe(async ({ data }) => {
      sub.unsubscribe();

      if (data.cancelled) {
        return;
      }

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        await this.openShop(firm);
      }
    });
  }

  async openShop(firm) {
    const url = this.router.url;
    await this.routeService.navigateToMultiFirm(firm);

    if (!url.includes(firm.website_details.sitename)) {
      window.location.reload();
    }
  }
}
