import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DialogsService } from '../services/dialogs.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { ProductService } from '../services/products.service';
import { SlugifyService } from '../services/slugify.service';
import { RouteService } from '../services/route.service';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent implements OnInit {
  own_domain: any;
  result: any;
  route: any;
  firm: any;
  firm_name: any;
  language: any;
  category: any;
  parent_category: any;
  cat_name: any;
  cat_id: any;
  subcat_name: any;
  subcat_id: any;
  showCategories: boolean;
  subcategory: boolean;
  pickup_label: any;
  openVat = false;
  vat_delivery_method: any;
  deliveryTimeSubscription: Subscription;

  constructor(
    public auth: AuthService,
    public route2: ActivatedRoute,
    public firmService: FirmService,
    public productService: ProductService,
    public dialogsService: DialogsService,
    public router: Router,
    public slug: SlugifyService,
    public loader: LoaderService,
    public routeService: RouteService,
    private deliveryTimeStore: DeliveryTimeStore
  ) {
    window.scrollTo(0, 0);
    this.route = route2;

    // Get route params
    this.firm_name = this.route.snapshot.params.firm_name;
    this.cat_name = this.route.snapshot.params.cat_name;
    this.cat_id = this.route.snapshot.params.cat_id;
    this.subcat_name = this.route.snapshot.params.subcat_name;
    this.subcat_id = this.route.snapshot.params.subcat_id;
    this.language = this.route.snapshot.params.language;

    this.deliveryTimeSubscription = this.deliveryTimeStore.updateEvent.subscribe(async () => {
      if (this.parent_category) {
        this.parent_category = await this.getAvailableData();
      }
    });
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.initData();
    });
  }

  async initData() {
    if (this.firmService.checkWebsite(this.firm, this.language) && this.firmService.checkForPassword(this.firm)) {
      // Loading spinner
      this.loader.present();

      // Check if category or subcategory
      if (this.route.snapshot.params.subcat_id) {
        this.subcategory = true;
        this.subcat_name = this.route.snapshot.params.subcat_name;
        this.subcat_id = this.route.snapshot.params.subcat_id;
        this.category = await this.productService.getCategoryById(
          this.firm.id,
          this.language,
          this.subcat_id,
          this.firm.uses_deliverect
        );
        this.parent_category = await this.productService.getCategoryById(
          this.firm.id,
          this.language,
          this.cat_id,
          this.firm.uses_deliverect
        );
      } else if (!this.route.snapshot.params.subcat_id) {
        this.subcategory = false;
        this.category = await this.productService.getCategoryById(
          this.firm.id,
          this.language,
          this.cat_id,
          this.firm.uses_deliverect
        );
        this.parent_category = this.firm.uses_deliverect
          ? await this.productService.getCategoryById(
              this.firm.id,
              this.language,
              this.category.parent_category_id,
              this.firm.uses_deliverect
            )
          : this.category;
      }

      this.parent_category = await this.getAvailableData();

      this.loader.dismiss();
    }
  }

  async getAvailableData() {
    return this.productService.setProductAvailableData(this.parent_category);
  }

  vatDeliveryMethodChanged(event) {
    this.vat_delivery_method = event;
  }

  menuCardClicked(category, subcategory) {
    this.routeService.navigateToSubCategory(
      this.own_domain,
      this.firm,
      this.language,
      category.name,
      category.category_id,
      subcategory.name,
      subcategory.category_id
    );
  }

  ngOnDestroy() {
    this.deliveryTimeSubscription.unsubscribe();
  }
}
