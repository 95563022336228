<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [buttonLabel]="'CONTINUE' | translate"
  [displayButton]="true"
  (submitted)="handleOnSubmit()">

  <div class="eo-modal-header flex align-items-center">
    <i class="far fa-clock icon"></i>
    <div class="ml-10">
      <ng-container *ngIf="!isLimitedToSameDayOrdering()">
        <p>{{ 'DATE_AND_TIME' | translate }}</p>
        <p class="font-13">{{ 'CHOOSE_DATE_TIME' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="isLimitedToSameDayOrdering()">
        <p>{{ 'TIME' | translate }}</p>
        <p class="font-13">{{ 'CHOOSE_TIME' | translate }}</p>
      </ng-container>
    </div>
  </div>

  <div>
    <delivery-date-time-picker
      *ngIf="!hasCustomDaysEnabled() && !hasOrderTimeSlotsEnabled()"
      [isLimitedToSameDayOrdering]="isLimitedToSameDayOrdering()"
      [showHeader]="false"
      [firm]="firm">
    </delivery-date-time-picker>

    <custom-days-picker *ngIf="hasCustomDaysEnabled()" [firm]="firm"></custom-days-picker>

    <timeslots-picker
      *ngIf="hasOrderTimeSlotsEnabled()"
      [showHeader]="false"
      [firm]="firm">
    </timeslots-picker>
  </div>
</eo-modal>
