import { Component, Input } from '@angular/core';
import { FirmStore } from '../../store/firm.store';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'firms-list-modal',
  templateUrl: './firms-list-modal.component.html',
  styleUrls: ['./firms-list-modal.component.scss'],
})
export class FirmsListModalComponent {
  @Input() deliveryMethod: string;
  @Input() header: string;

  public firms = [...this.firmStore.firms];
  public selectedFirm: any = null;

  constructor(private firmStore: FirmStore, private dialogRef: MatDialogRef<FirmsListModalComponent>) {}

  public handleOnSubmit(): void {
    this.dismiss({
      cancelled: false,
      firmId: this.selectedFirm,
    });
  }

  public selectFirm(firmId: number): void {
    this.selectedFirm = firmId;
  }

  public firmHasDeliveryMethod(firm: any): boolean {
    return firm.delivery_methods.indexOf(this.deliveryMethod) !== -1;
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
