<head>
  <title>{{ firm?.name }} | {{ 'HEADER.BASKET' | translate }}</title>
</head>

<body style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="pagecontent">

      <div class="view-shoppingbasket">
        <div class="centered">

          <h1 class="title-font">{{ 'BASKET.TITLE' | translate }}</h1>

          <p *ngIf="basketItems?.length == 0">{{ 'BASKET.EMPTY_MESSAGE' | translate }}</p>

          <div *ngIf="basketItems?.length > 0">

            <div class="cart-container"
              [style.background-color]="firm?.theme.container_color"
              [style.color]="firm?.theme.container_contrast_color">

              <!-- Extra cart info -->
              <ng-template [ngIf]="cart_info && cart_info?.content !== ''">
                <div *ngIf="cart_info && cart_info?.content !== ''" class="categories row cart-info">
                  <p style="white-space: pre-wrap">{{ cart_info?.content }}</p>
                </div>
                <hr/>
              </ng-template>

              <!-- Item list -->
              <div class="products">

                <!-- Products -->
                <div *ngFor="let item of basketItems" class="product">
                  <!-- Image -->
                  <div
                    class="image"
                    *ngIf="item.item.item.images && item.item.item.images.length > 0"
                    [style.background-color]="firm?.theme.well_color">
                    <img [src]="item.item.item.images[0].thumb" alt="">

                    <span
                      class="count"
                      [style.background-color]="firm?.theme.theme_color"
                      [style.color]="firm?.theme.theme_color_contrast_color">
                      {{ item.item.item.unit.id === units.KG ? item.item.amount / 1000 : item.item.amount}}
                    </span>
                  </div>

                  <!-- Product info -->
                  <div class="productinfo">
                    <p class="title">{{ item.item.name }}</p>
                    <ul class="ingredients">
                      <div *ngFor="let ingr of item.item.ingredients">
                        <li *ngIf="ingr.type === 'free' && ingr.action === 'rem'">
                          - {{ ingr.name }}
                          <ng-container *ngTemplateOutlet="quantity; context: {ingr: ingr}"></ng-container>
                        </li>
                      </div>
                      <div *ngFor="let ingr of item.item.ingredients">
                        <li *ngIf="ingr.type === 'free' && ingr.action === 'add'">
                          + {{ ingr.name }}
                          <ng-container *ngTemplateOutlet="quantity; context: {ingr: ingr}"></ng-container>
                        </li>
                      </div>
                      <div *ngFor="let ingr of item.item.ingredients">
                        <li *ngIf="ingr.type === 'paid' && !firm?.uses_vat">
                          + {{ ingr.name }}
                          <ng-container *ngTemplateOutlet="quantity; context: {ingr: ingr}"></ng-container>
                          {{ firm?.currency.symbol }}{{ ingr.price | number:'1.2-2' }}
                          <span *ngIf="item.item.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                          <span *ngIf="item.item.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                        </li>
                        <li *ngIf="ingr.type === 'paid' && firm?.uses_vat">
                          + {{ ingr.name }}
                          <ng-container *ngTemplateOutlet="quantity; context: {ingr: ingr}"></ng-container>
                          {{ firm?.currency.symbol }}{{ ingr.price + (ingr.price / 100 * item.item.item.vat_percentage) | number:'1.2-2' }}
                          <span *ngIf="item.item.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                          <span *ngIf="item.item.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                        </li>
                      </div>
                      <div *ngFor="let option of item.item.options_selected">
                        <li *ngIf="!firm?.uses_vat">+ {{ option.selected_option.name }}
                          <span
                            *ngIf="option.selected_option.price > 0">{{ firm?.currency.symbol }}{{ option.selected_option.price | number:'1.2-2' }}
                            <span *ngIf="item.item.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                                                          <span
                                                            *ngIf="item.item.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                                                      </span>
                        </li>
                        <li *ngIf="firm?.uses_vat">+ {{ option.selected_option.name }}
                          <span *ngIf="option.selected_option.price > 0">{{ firm?.currency.symbol }}{{ option.selected_option.price + (option.selected_option.price / 100 * item.item.item.vat_percentage) | number:'1.2-2' }}
                            <span *ngIf="item.item.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                            <span *ngIf="item.item.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                          </span>
                        </li>
                      </div>
                    </ul>
                    <span class="comment">{{ item.item.comment }}</span>

                    <!-- VAT -->
                    <div class="vat-box align-center" *ngIf="firm?.uses_vat && firm?.show_vat_in_frontend">
                      <div class="vat-col inline-block width-30 align-center">
                        <p no-margin>{{ 'zBTW' | translate }}</p>
                        <p no-margin
                          class="color-grey">{{ firm?.currency.symbol }}{{ item.item.total_price | number:'1.2-2' }}</p>
                      </div>
                      <div class="vat-col inline-block width-30 align-center">
                        <p no-margin>{{ 'VAT' | translate }} ({{ item.item.item.vat_percentage }}%)</p>
                        <p no-margin
                          class="color-grey">{{ firm?.currency.symbol }}{{ item.item.total_price / 100 * item.item.item.vat_percentage | number:'1.2-2' }}</p>
                      </div>
                      <div class="vat-col inline-block width-30 align-center">
                        <p no-margin>{{ 'BTWi' | translate }}</p>
                        <p no-margin
                          class="color-grey">{{ firm?.currency.symbol }}{{ item.item.total_price + (item.item.total_price / 100 * item.item.item.vat_percentage) | number:'1.2-2' }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- Buttons -->
                  <div class="actions">
                    <button
                      (click)="editItem(item)"
                      class="edit btn-fill"
                      [style.color]="firm?.theme.gradient_color_from_color"
                      [style.background-color]="firm?.theme.gradient_color_contrast_color">
                      {{ 'BASKET.EDIT' | translate }}
                    </button>
                    <button
                      (click)="removeItem(item)"
                      class="delete btn-fill"
                      [style.color]="firm?.theme.gradient_color_from_color"
                      [style.background-color]="firm?.theme.gradient_color_contrast_color">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>

                  <!-- Price -->
                  <div class="price" *ngIf="!firm?.uses_vat">{{ firm?.currency.symbol }}{{ item.item.total_price | number:'1.2-2' }}</div>
                  <div class="price" *ngIf="firm?.uses_vat && !firm?.show_vat_in_frontend">{{ firm?.currency.symbol }}{{ item.item.price_vat | number:'1.2-2' }}</div>

                </div>

                <hr>

                <!-- Total price of basket -->
                <div class="total">
                  <p class="title">{{ 'BASKET.TOTAL' | translate }}</p>
                  <span class="price">{{ firm?.currency.symbol }}{{ total_price | number:'1.2-2' }}</span>
                </div>
              </div>

            </div>

            <!-- Place order -->
            <div class="buttons" *ngIf="login; else loginButtons">
              <button
                class="btn-fill custom-btn"
                (click)="orderService.navigateToCheckout('user', firm, own_domain, language)"
                [disabled]="firm?.is_offline == true">
                {{ 'BASKET.PLACE_ORDER' | translate }}
              </button>
            </div>
            <ng-template #loginButtons>
              <div class="buttons">
                <button
                  class="btn-fill custom-btn"
                  (click)="signin()">
                  {{ 'LOGIN' | translate }}
                </button>
                <button
                  *ngIf="firm?.allow_guest_checkout"
                  class="btn-fill custom-btn"
                  (click)="orderService.navigateToCheckout('guest', firm, own_domain, language)"
                  [disabled]="firm?.is_offline == true">
                  {{ 'ORDER_AS_GUEST' | translate }}
                </button>
              </div>
            </ng-template>
          </div>

        </div>
      </div>

    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

  </div>

  <div id="iosfix-top"></div>
  <div id="iosfix-btm"></div>
</body>

<ng-template #quantity let-ingr="ingr">
  <span *ngIf="ingr.amount > 1">(x{{ ingr.amount }})</span>
</ng-template>
