<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [buttonLabel]="'TAKEAWAY' | translate"
  [displayButton]="true"
  [displayBackButton]="true"
  (cancelled)="close()"
  (submitted)="handleOnSubmit()">

  <div class="header flex align-items-center">
    <i class="fas fa-exclamation-circle icon"></i>
    <div class="ml-10">
      <strong>{{ 'OOPS' | translate }}</strong>
      <p class="font-13">{{ 'CANNOT_DELIVER' | translate }}</p>
    </div>
  </div>

  <div class="content align-center font-13">
    <p class="mb-10">{{ 'WE_DONT_OFFER_DELIVERY' | translate }}</p>
    <p>{{ 'WANT_TO_ORDER_ANYWAY' | translate }}</p>
    <p>{{ 'CHOOSE_TAKEAWAY' | translate }}</p>
  </div>
</eo-modal>
