import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-method-selection-modal',
  templateUrl: './method-selection-modal.component.html',
  styleUrls: ['./method-selection-modal.component.scss'],
})
export class MethodSelectionModalComponent {
  constructor(private dialogRef: MatDialogRef<MethodSelectionModalComponent>) {}

  dismiss(event) {
    this.dialogRef.close(event);
  }
}
