import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../../core/models/address';
import { CustomerService } from '../../../../services/customer.service';
import { DialogsService } from '../../../../services/dialogs.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FirmStore } from '../../../firm/store/firm.store';
import { CustomerStore } from '../../../customer/store/customer.store';

@Component({
  selector: 'app-address-selection-modal',
  templateUrl: './address-selection-modal.component.html',
  styleUrls: ['./address-selection-modal.component.css'],
})
export class AddressSelectionModalComponent implements OnInit {
  @Input() addresses: Address[];
  @Input() address: Address;
  @Input() showSwitcher = false;

  disableButton = true;

  constructor(
    private customerService: CustomerService,
    private customerStore: CustomerStore,
    private modalService: DialogsService,
    private dialogRef: MatDialogRef<AddressSelectionModalComponent>,
    private translate: TranslateService,
    private firmStore: FirmStore
  ) {}

  public ngOnInit(): void {
    if (this.address) {
      this.disableButton = false;
    }

    this.addresses?.forEach((address: Address) => {
      if (!this.customerStore.selectedAddress && address.is_default) {
        this.address = address;
        this.disableButton = false;
      }

      if (this.customerStore.selectedAddress?.id === address.id) {
        this.address = address;
        this.disableButton = this.disableButton = false;
      }
    });
  }

  public selectAddress(address): void {
    this.address = address;
    this.address.is_default = true;
  }

  public presentEditAddressModal(address): void {
    const header = this.translate.instant('CHANGE_ADDRESS');
    const subHeader = this.translate.instant('EDIT_FIELDS_BELOW');
    const buttonLabel = this.translate.instant('BUTTONS.SAVE');

    const modal = this.modalService.openAddressModal(
      header,
      subHeader,
      buttonLabel,
      this.firmStore.currentFirm.countries,
      address
    );

    const sub = modal.subscribe(async ({ data }) => {
      sub.unsubscribe();

      if (data.cancelled) {
        return;
      }

      this.customerStore.updateAddress(data.address);

      await this.customerService.updateCustomerAddress(
        this.customerStore.customer.id,
        this.customerStore.customer.uuid,
        data.address
      );

      this.addresses = this.customerStore.addresses;
    });
  }

  public presentAddDeliveryAddressModal(): void {
    const header = this.translate.instant('ADD_ADDRESS');
    const subHeader = this.translate.instant('FILL_IN_ADDRESS');
    const buttonLabel = this.translate.instant('ADD_ADDRESS');

    const modal = this.modalService.openAddressModal(
      header,
      subHeader,
      buttonLabel,
      this.firmStore.currentFirm.countries
    );

    const sub = modal.subscribe(async ({ data }) => {
      sub.unsubscribe();

      if (data.cancelled) {
        return;
      }

      const address = await this.customerService.addCustomerAddress(
        this.customerStore.customer.id,
        this.customerStore.customer.uuid,
        data.address
      );

      this.customerStore.addAddress(address);
    });
  }

  public isAddressSelected(address): boolean {
    return this.address?.id === address.id;
  }

  public handleOnSubmit(): void {
    this.dismiss(this.address);
  }

  public handleAddressFormOnChange(event): void {
    this.disableButton = !event.validForm;
    this.address = event.address;
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
