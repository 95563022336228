<a href="javascript:void(0)" (click)="routeService.navigateToProduct(own_domain, firm, language, item)">

  <div class="product center-container"
    [style.background-color]="firm?.theme.container_color"
    [style.color]="firm?.theme.container_contrast_color"
    [ngClass]="{ 'gold-container': item?.extra_points && item?.extra_points > 0 }">

    <!-- Badges -->
    <div class="badge-container" *ngIf="!item?.in_stock || item?.has_promotional_price || item?.popular">
      <div *ngIf="item?.has_promotional_price" class="promo-container container-shadow">
        <p>{{ 'PROMO' | translate }}</p>
      </div>
      <div *ngIf="item?.popular" class="popular-container container-shadow">
        <p>{{ 'POPULAR' | translate }}</p>
      </div>
    </div>

    <!-- Image -->
    <div *ngIf="item.images && firm?.product_images_enabled"
      [style.background-color]="firm?.theme.well_color"
      class="list-product-img">
      <img [src]="item.main_image" alt="">
    </div>

    <!-- Extra points -->
    <eo-extra-points *ngIf="item?.extra_points && item?.extra_points > 0" [points]="item?.extra_points" [position]="'top-right'"></eo-extra-points>

    <!-- No image -->
    <div *ngIf="!firm?.product_images_enabled" style="height: 70px; display: inline-block"></div>

    <div [className]="item?.allergenes.length == 0 ? 'product-info center-container' : 'product-info-allergenes center-container'">

      <div class="info-container">
        <p class="name">{{ item.name }}</p>

        <!-- No VAT || VAT -->
        <div *ngIf="!firm?.uses_vat || (firm?.uses_vat && firm?.show_vat_in_frontend)">
          <p *ngIf="item.has_promotional_price == false" class="price font-14">
            {{ firm?.currency.symbol }}{{ item.price | number:'1.2-2' }} / {{ item?.unit.name_singular}}
            <span *ngIf="firm?.uses_vat && firm?.show_vat_in_frontend">{{ 'VAT_EXCL' | translate }}</span>
          </p>

          <div *ngIf="item.has_promotional_price == true">
            <del class="price font-14">{{ firm?.currency.symbol }}{{ item.price | number:'1.2-2' }}
              / {{ item.unit.name_singular}}</del>
            <span class="color-red font-14">{{ firm?.currency.symbol }}{{ item.promotion.price | number:'1.2-2' }}
              / {{ item.unit.name_singular}}  <span *ngIf="firm?.uses_vat">{{ 'VAT_EXCL' | translate }}</span></span>
          </div>
        </div>

        <!-- VAT (BE only) -->
        <div *ngIf="firm?.uses_vat && !firm?.show_vat_in_frontend">
          <p *ngIf="item.has_promotional_price == false"
             class="price font-14">{{ firm?.currency.symbol }}{{ item.price + (item.price / 100 * VAT_percentage) | number:'1.2-2' }}
            / {{ item?.unit.name_singular}} </p>

          <div *ngIf="item.has_promotional_price == true">
            <del
              class="price font-14">{{ firm?.currency.symbol }}{{ item.price + (item.price / 100 * VAT_percentage) | number:'1.2-2' }}
              / {{ item.unit.name_singular}}</del>
            <span
              class="color-red font-14">{{ firm?.currency.symbol }}{{ item.promotion.price + (item.promotion.price / 100 * VAT_percentage) | number:'1.2-2' }}
              / {{ item.unit.name_singular}}</span>
          </div>
        </div>

        <!-- Allergens -->
        <div *ngIf="item?.allergenes.length > 0" class="allergenes">
          <img *ngFor="let allergene of item?.allergenes" [src]="allergene.image" mat-raised-button [matTooltip]="allergene.name">
        </div>

        <!-- Snoozed message -->
        <div class="snoozed margin-top font-14" *ngIf="productService.isSnoozed(item)">
          <eo-snoozed-label [snoozeEnd]="item.snooze_end"></eo-snoozed-label>
        </div>
      </div>

    </div>

    <!-- Add button -->
    <div *ngIf="displayOrderButton()" class="item_addtobasket add-btn">
      <a href="javascript:void(0)" *ngIf="firm?.website_details.is_webshop"
         (click)="$event.stopPropagation(); openModal(item)" class="btn-fill add"
         [style.background-color]="firm?.theme.well_color"
         [style.color]="firm?.theme.well_contrast_color">+<span class="label"></span>
      </a>
    </div>

    <!-- Out of stock -->
    <div *ngIf="!item?.in_stock" class="outofstock-container">
      <div class="out_of_stock stock_list item_addtobasket" [style.background-color]="firm?.theme.well_color" [style.color]="firm?.theme.well_contrast_color">
        <p>{{ 'NOT_IN_STOCK' | translate }}</p>
      </div>
    </div>

  </div>
</a>
