<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [displayButton]="false">

  <div class="eo-modal-header">
    <p>{{ 'READY_TO_ORDER' | translate }}</p>
  </div>

  <div class="content">
    <method-grid
      template="grid"
      (dismissed)="handleOnMethodSelect($event)">
    </method-grid>
  </div>

</eo-modal>
