import { Component, Input } from '@angular/core';

@Component({
  selector: 'order-now-bar',
  templateUrl: './order-now-bar.component.html',
  styleUrls: ['./order-now-bar.component.scss'],
})
export class OrderNowBarComponent {
  @Input() firm;
  @Input() minified = false;
  @Input() displayBorders = true;
}
