<div *ngIf="!loading">
  <div class="datepicker clearfix" id="datepicker">

    <div class="day flex align-items-center" *ngIf="!isLimitedToSameDayOrdering">
      <p class="mr-10">{{ 'DATE' | translate }}:</p>

      <div
        *ngIf="isToday()"
        class="date-box clickable"
        (click)="openDayPicker()">
        {{ dateDay }}
      </div>

      <div
        [hidden]="isToday()"
        class="date-box clickable">
        <input
          [matDatepicker]="dateDayPicker"
          [min]="minDate"
          [(ngModel)]="dateDayModel"
          (dateChange)="dateChangedHandler()"
          (click)="openDayPicker()"
          matInput
          name="date"
          required>
        <mat-datepicker touchUi #dateDayPicker disabled="false"></mat-datepicker>
      </div>
    </div>

    <div class="time flex align-items-center">
      <p class="mr-10">{{ 'TIME' | translate }}:</p>

      <ng-container *ngIf="!asapSelected">
        <select
          class="date-box font-15"
          [class.clickable]="!asapSelected"
          [(ngModel)]="dateHourModel"
          [disabled]="asapSelected"
          (ngModelChange)="hourChangedHandler()"
          name="hours" required>
          <option *ngFor="let hour of hoursArray" [value]="hour">
            {{ hour }}
          </option>
        </select>
        <span class="ml-5 mr-5">:</span>
        <select
          class="date-box font-15"
          [class.clickable]="!asapSelected"
          [(ngModel)]="dateMinutesModel"
          (ngModelChange)="handleOnDateTimeChange()"
          [disabled]="asapSelected"
          name="minutes" required>
          <option *ngFor="let minutes of minutesArray" [value]="minutes">
            {{ minutes }}
          </option>
        </select>
      </ng-container>

      <div class="flex align-items-center ml-10" *ngIf="firm?.asap && isToday()">
        <mat-checkbox
          [(ngModel)]="asapSelected"
          (ngModelChange)="handleAsapChange()"
          disableRipple="true">
          {{ 'ASAP' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div class="errors">
      <p *ngIf="isLimitedToSameDayOrdering && !isToday()">{{ 'ORDER_TODAY_NOT_POSSIBLE' | translate }}</p>
      <p *ngIf="shopIsClosed" [hidden]="isLimitedToSameDayOrdering && !isToday">{{ 'SHOP_NOT_OPEN' | translate }}</p>
    </div>

  </div>
</div>

<p class="loading" *ngIf="loading">{{ 'LOADING' | translate }}</p>
