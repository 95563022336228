import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryMethods } from '../../../../constants/delivery_methods';
import { FirmStore } from '../../../firm/store/firm.store';
import { DeliveryMethodService } from '../../../../services/delivery-method.service';
import { DialogsService } from '../../../../services/dialogs.service';
import { CustomerStore } from '../../../customer/store/customer.store';
import { LoaderService } from '../../../../services/loader.service';
import { FirmService } from '../../../../services/firm.service';
import { RouteService } from '../../../../services/route.service';
import { Router } from '@angular/router';
import { DeliveryTimePickerService } from '../../../delivery-time/services/delivery-time-picker.service';
import { DeliveryAddressService } from '../../services/delivery-address.service';
import { MenuStore } from '../../../menu/store/menu.store';

@Component({
  selector: 'method-grid',
  templateUrl: './method-grid.component.html',
  styleUrls: ['./method-grid.component.scss'],
})
export class MethodGridComponent implements OnInit {
  @Input() template: string;

  @Output() dismissed = new EventEmitter<any>();

  firm: any;
  pickupMainBranch = false;
  hasParent: boolean;
  storeId: string;
  parentShowDeliveryMethodFirst: boolean;
  parentFirm: any;
  loading: boolean;

  constructor(
    private firmStore: FirmStore,
    private deliveryMethodService: DeliveryMethodService,
    private modalService: DialogsService,
    private customerStore: CustomerStore,
    private loader: LoaderService,
    private firmService: FirmService,
    private routeService: RouteService,
    private router: Router,
    private deliveryTimePickerService: DeliveryTimePickerService,
    private deliveryAddressService: DeliveryAddressService,
    private menuStore: MenuStore
  ) {}

  ngOnInit() {
    this.firm = this.firmStore.currentFirm;
    this.hasParent = this.firmStore.hasParent;
    if (this.hasParent) {
      this.loading = true;
      this.setParentShowDeliveryMethodFirst().then(() => {
        this.loading = false;
      });
    }
  }

  async setParentShowDeliveryMethodFirst() {
    const storeId = this.getStoreId();
    await this.firmService.getAllFirms(storeId);
    this.parentFirm = this.firmStore.distributor;
    this.parentShowDeliveryMethodFirst = this.parentFirm.theme.show_delivery_method_first;
  }

  getStoreId() {
    if (!this.firmStore.ownDomain) {
      if (this.firm.is_store) {
        return this.firm.store_id;
      }

      if (!this.firm.is_store) {
        return this.firm.parent_firm?.uuid;
      }
    }

    if (this.firmStore.ownDomain) {
      if (this.firm.is_store) {
        return this.firm.uuid;
      }

      if (!this.firm.is_store) {
        return this.firm.parent_firm?.uuid;
      }
    }
  }

  displayPickupMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.Pickup);
    }

    return this.deliveryMethodService.isPickupMethodActiveForFirm(this.firm);
  }

  displayDeliveryMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.Delivery);
    }

    return this.deliveryMethodService.isDeliveryMethodActive(this.firm);
  }

  displayPickupPointMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return false;
    }

    return this.deliveryMethodService.isPickupPointMethodActive(this.firm.pickup, this.firm.pickup_points);
  }

  displayTableMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return this.deliveryMethodService.isMethodEnabledForMultiFirm(DeliveryMethods.Table);
    }

    return this.deliveryMethodService.isTableMethodActive(this.firm);
  }

  disablePickupMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return false;
    }

    return this.deliveryMethodService.isPickupMethodTemporarilyInactive(this.firm);
  }

  disableDeliveryMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return false;
    }

    return this.deliveryMethodService.isDeliveryMethodTemporarilyInactive(this.firm);
  }

  disableTableMethod() {
    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      return false;
    }

    return this.deliveryMethodService.isTableMethodTemporarilyInactive(this.firm);
  }

  selectPickupPointMethod() {
    this.dismiss({ cancelled: false });

    this.deliveryMethodService.updateSelectedDeliveryMethod(DeliveryMethods.PickupPoint);
    this.modalService.openPickPointSelectionModal(this.firm.pickup_points, false);
  }

  selectTableMethod() {
    this.dismiss({ cancelled: false });

    this.deliveryMethodService.updateSelectedDeliveryMethod(DeliveryMethods.Table);

    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      const modal = this.modalService.openFirmListModal(DeliveryMethods.Table);

      const sub = modal.subscribe(async ({ data }) => {
        sub.unsubscribe();

        if (data.cancelled || !data.firmId) {
          return;
        }

        this.firm = await this.firmService.getFirmById(data.firmId);
        return this.selectTableMethodForFirm();
      });

      return;
    }

    return this.selectTableMethodForFirm();
  }

  selectTableMethodForFirm() {
    if (this.firm.custom_table_methods?.length > 0 || this.firm.table_numbers?.length > 0) {
      const modal = this.modalService.openTableSelectionModal(this.firm.custom_table_methods, this.firm, false);

      const sub = modal.subscribe(async ({ data }) => {
        sub.unsubscribe();

        if (data.cancelled) {
          return;
        }

        if (this.hasParent && this.parentShowDeliveryMethodFirst) {
          await this.openShop(this.firm);
          return;
        }
      });

      return;
    }

    localStorage.removeItem('selectedTableMethod');
    localStorage.removeItem('selectedTableNumber');
  }

  async selectDeliveryMethod() {
    this.dismiss({ cancelled: false });

    const modal = this.deliveryAddressService.openAddressSelectionModal(
      this.customerStore.addresses,
      false,
      this.customerStore.selectedAddress
    );

    const sub = modal.subscribe(async ({ data }) => {
      sub.unsubscribe();

      if (!data || data.cancelled) {
        return;
      }

      let firm;
      this.loader.present();

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        const response = await this.firmService.getFirmByAddress(data);

        if (!response) {
          return this.loader.dismiss();
        }

        firm = await this.firmService.getFirmById(response.id);
        this.firmStore.setLanguage(firm.languages[0]?.code);
      }

      this.customerStore.setSelectedAddress(data);

      this.deliveryMethodService.updateSelectedDeliveryMethod(DeliveryMethods.Delivery);
      this.loader.dismiss();

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        return this.openDeliveryDateTimeModal(firm);
      }

      return this.openDeliveryDateTimeModal();
    });
  }

  async selectPickupMethod() {
    this.dismiss({ cancelled: false });

    this.deliveryMethodService.updateSelectedDeliveryMethod(DeliveryMethods.Pickup);

    if (this.hasParent && this.parentShowDeliveryMethodFirst) {
      const modal = await this.modalService.openFirmListModal(DeliveryMethods.Pickup);

      const sub = modal.subscribe(async ({ data }) => {
        sub.unsubscribe();

        if (data.cancelled || !data.firmId) {
          return;
        }

        const firm = await this.firmService.getFirmById(data.firmId);

        return this.openDeliveryDateTimeModal(firm);
      });

      return;
    }

    return this.openDeliveryDateTimeModal();
  }

  async openDeliveryDateTimeModal(selectedFirm?) {
    if (!selectedFirm) {
      selectedFirm = this.firm;
    }

    if (
      (!this.firm?.is_store && !selectedFirm?.theme?.show_timepicker_modal) ||
      (this.firm.is_store && !this.parentFirm?.theme?.show_timepicker_modal)
    ) {
      await this.deliveryTimePickerService.selectFirstAvailableTime();

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        await this.openShop(selectedFirm);
        this.dismiss({ cancelled: false });
        return;
      }

      this.dismiss({ cancelled: false });
      return;
    }

    const modal = this.deliveryTimePickerService.openDeliveryDateTimeModal();

    const sub = modal.subscribe(async ({ data }) => {
      sub.unsubscribe();

      if (data.cancelled) {
        return;
      }

      if (this.hasParent && this.parentShowDeliveryMethodFirst) {
        await this.openShop(selectedFirm);
        this.dismiss({ cancelled: false });
        return;
      }
      this.dismiss({ cancelled: false });
    });
  }

  async openShop(firm) {
    const url = this.router.url;
    await this.routeService.navigateToMultiFirm(firm);

    if (!url.includes(firm.website_details.sitename)) {
      window.location.reload();
    }
  }

  displayViewMenu() {
    if (!this.firm.is_store) {
      return false;
    }

    if (this.hasParent) {
      return this.firmStore?.distributor?.theme?.show_menu_preview;
    }

    return this.firmStore?.currentFirm?.theme?.show_menu_preview;
  }

  async viewMenu() {
    this.menuStore.setViewMenuActive(true);

    const currentFirm = await this.firmService.getFirmById(this.firmStore.firms[0].id);
    this.firmStore.setCurrentFirm(currentFirm);

    await this.navigateToMenu(currentFirm);
  }

  async navigateToMenu(firm) {
    await this.routeService.navigateToMultiFirm(firm);
  }

  dismiss(data) {
    this.dismissed.emit({ data });
  }
}
