<div class="sub-toolbar flex align-items-center">
  <div class="center-container flex align-items-center font-13 centered"
    [ngClass]="displayLocationInfo() ? 'justify-space-between' : 'flex-end'">

    <div *ngIf="displayLocationInfo()" class="toolbar-box multifirm-info">
      <div>
        <strong>{{ parentFirm.name }}</strong>
        <p class="font-12">{{ firm.name }}</p>
      </div>
    </div>

    <div class="flex align-items-center">

      <div *ngIf="deliveryMethods.length <= 2" class="toolbar-box">
        <div class="delivery-methods flex clickable" tappable (click)="openMethodSelectionModal()">
          <span *ngFor="let method of deliveryMethods" [class.selected]="method.selected">
            <ion-icon src="../../assets/img/{{ method.icon }}.svg"></ion-icon>
            <p *ngIf="method.name === DELIVERY_METHODS.Pickup">{{ 'TAKEAWAY' | translate }}</p>
            <p *ngIf="method.name === DELIVERY_METHODS.Delivery">{{ 'DELIVERY' | translate }}</p>
            <p *ngIf="method.name === DELIVERY_METHODS.Table">{{ 'ORDER_ON_THE_SPOT' | translate }}</p>
            <p *ngIf="method.name === DELIVERY_METHODS.PickupPoint">{{ 'PICKUP_POINT' | translate }}</p>
          </span>
        </div>

        <div *ngIf="mustDisplayDeliveryAddress()" class="flex align-items-center ml-10 font-12 clickable" tappable (click)="openDeliveryAddressModal(false)">
            <div *ngIf="(deliveryAddress$ | async) as address; else fillInAddress">
              <p>{{ address.street }} {{ address.house_number }},</p>
              <p>{{ address.zip_code }} {{ address.locality }}</p>
            </div>
            <ng-template #fillInAddress>
              <p>{{ 'FILL_IN_ADDRESS' | translate }}</p>
            </ng-template>
            <i class="fas fa-pen ml-10"></i>
        </div>
      </div>

      <div *ngIf="hasMoreThanTwoDeliveryMethodAndOneMethodIsSelected()"
        class="toolbar-box delivery-method"
        tappable
        (click)="handleOnDeliveryMethodTap()">
        <div class="select flex align-items-center clickable" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.Pickup">
          <ion-icon src="../../assets/img/takeaway.svg"></ion-icon>
          <div class="text">
            {{ 'TAKEAWAY' | translate }}
            <i class="fas fa-chevron-down ml-5 font-10"></i>
          </div>
        </div>
        <div class="select flex align-items-center clickable" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.Delivery">
          <ion-icon src="../../assets/img/delivery.svg"></ion-icon>
          <div class="text">
            {{ 'DELIVERY' | translate }}
            <span *ngIf="(deliveryAddress$ | async) as address" class="font-10">({{ address.street }} {{ address.house_number }}, {{ address.zip_code }} {{ address.locality }})</span>
            <i class="fas fa-chevron-down ml-5 font-10"></i>
          </div>
        </div>
        <div class="select flex align-items-center clickable" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.Table">
          <ion-icon src="../../assets/img/qrcode.svg"></ion-icon>
          <div class="text">
            {{ 'ORDER_ON_THE_SPOT' | translate }}
            <span *ngIf="(tableNumber$ | async) as tableNumber" class="font-10">({{ tableNumber }})</span>
            <i class="fas fa-chevron-down ml-5 font-10"></i>
          </div>
        </div>
        <div class="select flex align-items-center clickable" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.PickupPoint">
          <ion-icon src="../../assets/img/pickuppoint.svg"></ion-icon>
          <div class="text">
            {{ 'PICKUP_POINT' | translate }}
            <i class="fas fa-chevron-down ml-5 font-10"></i>
          </div>
        </div>
      </div>

      <div *ngIf="hasMoreThanTwoDeliveryMethodAndNoMethodIsSelected()"
        class="toolbar-box delivery-method clickable"
        tappable
        (click)="openMethodSelectionModal()">
        {{ 'CHANGE_DELIVERY_METHOD_TITLE' | translate }}
      </div>

      <div *ngIf="hasOneDeliveryMethodSelectedButNotEatIn()" class="toolbar-box ml-10" tappable (click)="openDeliveryDateTimeModal()">
        <div>
          <strong class="order-time-title">{{ 'ORDER_TIME' | translate }}:</strong>
          <p class="clickable font-12">
            <i class="far fa-clock"></i>
            {{ currentDeliveryTime$ | async }}
            <i class="fas fa-chevron-down ml-5 font-10"></i>
          </p>
        </div>
      </div>
    </div>

  </div>
</div>
