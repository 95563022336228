<div>
  <p>{{ 'CHOOSE_DATE_TIME' | translate }}</p>
  <div class="flex">
    <select
      class="hours date-box font-15 clickable"
      [(ngModel)]="customDateModel"
      (ngModelChange)="handleOnChange()"
      name="hours">
      <option *ngFor="let customDate of customDates" [value]="customDate.value">
        {{ customDate.text }}
      </option>
    </select>
  </div>
</div>
