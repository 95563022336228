<div
  class="flex align-items-center"
  [class.disabled]="disabled || isSnoozed || !inStock"
  (click)="handleSelect()"
  tappable>

  <div class="flex align-items-center justify-center">
    <mat-checkbox
      *ngIf="isCheckbox() && multiMax === 1"
      [disabled]="disabled || isSnoozed || !inStock"
      [checked]="checked"
      (click)="$event.stopPropagation()"
      (change)="handleSelect()"
      disableRipple="true"
      class="mr-5">
    </mat-checkbox>

    <mat-radio-button
      *ngIf="isRadio()"
      [checked]="checked"
      [value]="label"
      [disabled]="disabled || isSnoozed || !inStock"
      disableRipple="true">
    </mat-radio-button>
  </div>

  <div
    *ngIf="displayQuantity()"
    class="ingr-counter mr-5"
    (click)="$event.stopPropagation()">
    <a class="btn-fill subtract ingr-btn"
       [ngClass]="quantity <= 0 || disabled ? 'disabled' : 'enabled'"
       (click)="subtract()">
      -
    </a>

    <input class="ingr-input" name="amount" type="number" id="amount" [(ngModel)]="quantity" disabled="true">

    <a class="btn-fill add ingr-btn"
       [ngClass]="hasReachedMultiMax() || reachedGroupMax || disabled ? 'disabled' : 'enabled'"
       (click)="add()">
      +
    </a>
  </div>

  <div *ngIf="enableImage" class="image-container flex align-items-center justify-center">
    <img *ngIf="image" [src]="image">
    <div *ngIf="!image" class="missing-img"></div>
  </div>

  <div class="flex align-items-center justify-space-between full-width ml-5">
    <div>
      <p class="title-txt">
        {{ label }}

        <mat-icon
          *ngIf="description"
          [matTooltip]="description">
          <i class="fas fa-question-circle option-description"></i>
        </mat-icon>
      </p>

      <eo-snoozed-label
        *ngIf="isSnoozed"
        [snoozeEnd]="snoozeEnd">
      </eo-snoozed-label>

      <eo-out-of-stock-label
        *ngIf="!inStock">
      </eo-out-of-stock-label>
    </div>

    <div class="price-container" *ngIf="price > 0 && !isSnoozed">
      <p class="font-12">
        + {{ currency }}{{ price | number:'1.0-2' }}
        <span *ngIf="unitId === units.GRAM">/{{ 'GR' | translate }}</span>
        <span *ngIf="unitId === units.KG">/{{ 'KG' | translate }}</span>
      </p>
    </div>
  </div>
</div>
