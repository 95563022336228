<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [buttonLabel]="'CONTINUE' | translate"
  [displayButton]="true"
  (submitted)="handleOnSubmit()">

  <div class="eo-modal-header flex align-items-center">
    <ion-icon src="../../assets/img/takeaway.svg"></ion-icon>
    <div class="ml-10">
      <p>{{ 'SELECT_A_LOCATION' | translate }}</p>
    </div>
  </div>

  <div scrollx="true" class="scroll-x">
    <mat-radio-group [(ngModel)]="selectedFirm">
      <ng-container *ngFor="let firm of firms">
        <div
          class="radio-container eo-radio-button flex align-items-center justify-space-between clickable"
          *ngIf="!firm.is_offline && firmHasDeliveryMethod(firm)"
          (click)="selectFirm(firm.id)">

          <div class="title-container">
            <strong>{{ firm?.name }}</strong>
          </div>

          <div class="radio-button-container flex ion-justify-content-end ion-align-items-center">
            <mat-radio-button [value]="firm.id"></mat-radio-button>
          </div>
        </div>
      </ng-container>
    </mat-radio-group>
  </div>

</eo-modal>
