import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { FirmStore } from './modules/firm/store/firm.store';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private router: Router,
    public auth: AuthService,
    public loader: LoaderService,
    translate: TranslateService,
    private firmStore: FirmStore
  ) {
    translate.setDefaultLang('nl');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const params = this.router.routerState.snapshot.root.children[0].params;
        this.firmStore.setLanguage(params.language ?? params.parameter);

        gtag('config', 'UA-142065824-2', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
