import { Component, Input } from '@angular/core';
import { Country } from '../../core/models/country';
import { Address } from '../../core/models/address';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() buttonLabel: string;
  @Input() countries: Country[];
  @Input() address: Address;

  disableButton: boolean;

  constructor(private dialogRef: MatDialogRef<AddressModalComponent>) {}

  saveHandler() {
    this.dismiss({
      cancelled: false,
      address: { ...this.address },
    });
  }

  public handleAddressFormOnChange(event): void {
    this.disableButton = !event.validForm;
    this.address = event.address;
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
