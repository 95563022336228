import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PickupPointSelectionComponent } from './components/pickup-point-selection/pickup-point-selection.component';
import { SharedModule } from '../../shared/shared.module';
import { MethodDeliverySwitcherComponent } from './components/method-delivery-switcher/method-delivery-switcher.component';
import { MatRadioModule } from '@angular/material/radio';
import { AddressSelectionModalComponent } from './components/address-selection-modal/address-selection-modal.component';
import { TableSelectionComponent } from './components/table-selection/table-selection.component';
import { MatSelectModule } from '@angular/material/select';
import { MultifirmHomepageComponent } from './pages/multifirm-homepage/multifirm-homepage.component';
import { MethodSelectionModalComponent } from './components/method-selection-modal/method-selection-modal.component';
import { AgmCoreModule } from '@agm/core';
import { MethodGridComponent } from './components/method-grid/method-grid.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReadyToOrderModalComponent } from './components/ready-to-order-modal/ready-to-order-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    MatRadioModule,
    MatSelectModule,
    AgmCoreModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PickupPointSelectionComponent,
    MethodDeliverySwitcherComponent,
    AddressSelectionModalComponent,
    TableSelectionComponent,
    MultifirmHomepageComponent,
    MethodSelectionModalComponent,
    MethodGridComponent,
    ReadyToOrderModalComponent,
  ],
  exports: [
    PickupPointSelectionComponent,
    MethodDeliverySwitcherComponent,
    AddressSelectionModalComponent,
    TableSelectionComponent,
    MultifirmHomepageComponent,
    MethodSelectionModalComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class DeliveryMethodModule {}
