import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eo-modal',
  templateUrl: './eo-modal.component.html',
  styleUrls: ['./eo-modal.component.scss'],
})
export class EoModalComponent {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() buttonLabel: string;
  @Input() disableButton: boolean;
  @Input() displayButton = true;
  @Input() displayBackButton = false;
  @Input() cancelPosition = 'default';
  @Input() footerPosition = 'default';
  @Input() iconName: string;

  @Output() submitted = new EventEmitter<void>();
  @Output() cancelled = new EventEmitter<void>();

  constructor(private dialogRef: MatDialogRef<any>) {}

  cancel() {
    this.dismiss({ cancelled: true });
  }

  submitHandler() {
    this.submitted.emit();
  }

  cancelledHandler() {
    this.cancelled.emit();
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
