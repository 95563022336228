import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FirmStore {
  public currentFirm: any = null;
  public distributor: any = null;
  public demoFirms: any = null;
  public firms: any[] = [];
  public ownDomain: boolean = null;
  public language: string = null;
  public hasParent: boolean = null;
  public parentFirm: any = null;

  constructor() {}

  public setDistributor(distributor: any): void {
    this.distributor = distributor;
  }

  public setCurrentFirm(currentFirm: any): void {
    this.currentFirm = currentFirm;
  }

  public setFirms(firms: any[]): void {
    this.firms = firms;
  }

  public setDemoFirms(demoFirms: any): void {
    this.demoFirms = demoFirms;
  }

  public setOwnDomain(ownDomain: boolean): void {
    this.ownDomain = ownDomain;
  }

  public setLanguage(language: string): void {
    this.language = language;
  }

  public setParent(parentFirm: any): void {
    this.parentFirm = parentFirm;
  }

  public setHasParent(hasParent: boolean): void {
    this.hasParent = hasParent;
  }
}
