import { Component, OnInit } from '@angular/core';
import { DeliveryTimeStore } from '../../store/delivery-time.store';
import { FirmService } from '../../../../services/firm.service';
import { DeliveryTimeServiceResolver } from '../../services/delivery-time-service.resolver';
import { FirmStore } from '../../../firm/store/firm.store';
import { DeliveryTimeServiceInterface } from '../../services/delivery-time-service.interface';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-time-modal',
  templateUrl: './delivery-time-modal.component.html',
  styleUrls: ['./delivery-time-modal.component.scss'],
})
export class DeliveryTimeModalComponent implements OnInit {
  private deliveryTimeService: DeliveryTimeServiceInterface;

  public firm: any;
  public delay = '';
  public loading = true;
  public selectedDate: string;

  constructor(
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private firmService: FirmService,
    private firmStore: FirmStore,
    private deliveryTimeStore: DeliveryTimeStore,
    private dialogRef: MatDialogRef<DeliveryTimeModalComponent>
  ) {}

  ngOnInit(): void {
    this.firm = this.firmStore.currentFirm;

    this.deliveryTimeServiceResolver
      .resolveDeliveryTimeService(this.firm)
      .then(async (service: DeliveryTimeServiceInterface) => {
        this.deliveryTimeService = service;

        const deliveryTime = await this.deliveryTimeService?.getDeliveryTime();
        this.setDelay(deliveryTime);

        this.loading = false;
      });

    this.listenToEvents();
  }

  public listenToEvents(): void {
    this.deliveryTimeStore.updateEvent.subscribe((deliveryTime: string) => {
      this.setDelay(deliveryTime);
    });
  }

  private setDelay(deliveryTime: string): void {
    this.delay = deliveryTime;
  }

  public isLimitedToSameDayOrdering(): boolean {
    return this.deliveryTimeService?.isLimitedToSameDayOrdering();
  }

  public hasCustomDaysEnabled(): boolean {
    return this.deliveryTimeService?.hasCustomDays();
  }

  public hasOrderTimeSlotsEnabled(): boolean {
    return this.deliveryTimeService?.hasOrderTimeslots();
  }

  public handleOnSubmit(): void {
    this.dismiss({ cancelled: false });
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
