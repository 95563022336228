import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { CustomerService } from '../services/customer.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.css'],
})
export class LoyaltyComponent implements OnInit {
  firm_name: any;
  language: any;

  firm: any;
  own_domain: boolean;

  firmValuta: any;
  firmLoyalty: any;
  customerLoyalty: any;
  points: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public firmService: FirmService,
    public customerService: CustomerService
  ) {
    window.scrollTo(0, 0);

    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }

  ngOnInit(): void {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;

      this.initData();
    });
  }

  initData(): void {
    if (this.firmService.checkWebsite(this.firm, this.language) && this.firm.loyalty && this.firm.use_loyalty) {
      this.firmValuta = this.firm.currency.symbol;
      this.firmLoyalty = this.firm.loyalty;

      this.getCustomerLoyalty().subscribe((customerLoyalty) => {
        this.customerLoyalty = customerLoyalty;

        // Fill the points
        this.points = [];

        let i = 1;

        if (!this.customerLoyalty.add_loyalty_to_order) {
          for (i; i <= this.customerLoyalty.current_points; i++) {
            this.points.push({
              id: i,
              filled_point: true,
            });
          }
          for (i; i <= this.firmLoyalty.points_required; i++) {
            this.points.push({
              id: i,
              filled_point: false,
            });
          }
        } else {
          for (i; i <= this.firmLoyalty.points_required; i++) {
            this.points.push({
              id: i,
              filled_point: true,
            });
          }
        }
      });
    } else {
      this.router.navigate([this.firm.website_details.sitename, this.language, '404']);
    }
  }

  getCustomerLoyalty(): Observable<any> {
    return new Observable<any>((observer) => {
      const login: any = JSON.parse(localStorage.getItem('login'));

      if (login) {
        this.customerService
          .get(this.firm, login.id, this.language, this.firm.distributor)
          .then((customer: any) => {
            observer.next(customer.loyalty);
          });
      }
    });
  }
}
