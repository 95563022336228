import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { DeliveryTimeModule } from '../delivery-time/delivery-time.module';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, TranslateModule, DeliveryTimeModule],
  declarations: [],
  exports: [],
})
export class CustomerModule {}
