<header class="menu">
  <div class="centered width-full no-padding">
    <div class="mainmenu">
      <div class="btm-bar"
           [style.background-color]="firm?.theme.main_color"
           [style.color]="firm?.theme.main_color_contrast_color">
      </div>
      <div class="centered"
           [style.color]="firm?.theme.main_color_contrast_color">
        >
        <div class="right clearfix">
          <!-- Navigation -->
          <nav>
            <a href="javascript:void(0)"
               (click)="routeService.navigateToMenu()">{{ 'HEADER.SELECTION' | translate }}</a>
            <a href="javascript:void(0)"
               (click)="routeService.navigateToFavorites(own_domain, firm, language)">{{ 'HEADER.FAVORITES' | translate }}</a>
            <a href="javascript:void(0)"
               (click)="routeService.navigateToInfo(own_domain, firm, language)">{{ 'HEADER.INFO' | translate }}</a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="topmenu">
  <div *ngIf="firm?.website_details.is_webshop" id="basketmenu">
    <div (click)="showMenu == false ? showMenu=true : showMenu=false" class="label" style="padding-top: 8px">
      <div class="cart"></div>
      <p *ngIf="basket.basketAmount == 0"><span class="num">0</span> {{ 'HEADER.ITEMS' | translate }}</p>
      <p *ngIf="basket.basketAmount == 1"><span class="num">1</span> {{ 'HEADER.ITEM' | translate }}</p>
      <p *ngIf="basket.basketAmount > 1"><span
        class="num">{{ basket.basketAmount }}</span> {{ 'HEADER.ITEMS' | translate }}</p>
    </div>

    <div *ngIf="showMenu && basket.basketAmount > 0" class="submenu">
      <ul>
        <li *ngFor="let item of basket.basketItems">
          <span class="amount"
                *ngIf="item.item.item.unit.id !== units.KG">{{ item.item.amount }} {{ item.item.item.unit.name_short }}</span>
          <span class="amount"
                *ngIf="item.item.item.unit.id === units.KG">{{ item.item.amount / 1000 }} {{ item.item.item.unit.name_short }}</span>
          <p>{{ item.item.name }}</p>
        </li>
      </ul>

      <div class="subtotal">
        {{ 'HEADER.SUBTOTAL' | translate }}
        <span class="price">{{ firm?.currency.symbol }}{{ basket.basketPrice | number:'1.2-2' }}</span>
      </div>
      <div class="buttons clear" *ngIf="customerService.login">
        <a href="javascript:void(0)" class="edit"
           (click)="routeService.navigateToBasket(own_domain, firm, language)">{{ 'HEADER.EDIT' | translate }}</a>
        <a href="javascript:void(0)" class="btn-fill"
          (click)="orderService.navigateToCheckout('user', firm, own_domain, language)">
          {{ 'HEADER.ORDER' | translate }}
        </a>
      </div>
      <div class="buttons clear" *ngIf="!customerService.login">
        <a href="javascript:void(0)" class="edit"
           (click)="routeService.navigateToBasket(own_domain, firm, language)">{{ 'HEADER.EDIT' | translate }}</a>
        <br>
        <br>
        <a href="javascript:void(0)" class="btn-fill"
          (click)="routeService.navigateToProfile(own_domain, firm, language)">
          {{ 'LOGIN' | translate }}
        </a>
        <a href="javascript:void(0)" class="btn-fill"
           *ngIf='firm?.allow_guest_checkout'
          (click)="orderService.navigateToCheckout('guest', firm, own_domain, language)">
          {{ 'ORDER_AS_GUEST' | translate }}
        </a>
      </div>

    </div>
  </div>

  <div id="basketmenu" class="usermenu inline-block background-white" style="height: 44px; margin-left: -2px;">
    <button *ngIf="!login" mat-button style="padding: 3px 0 4px 0; min-width: 138px"
            (click)="routeService.navigateToProfile(own_domain, firm, language)">
      <i class="far fa-user menu-icon"></i>
      <span style="margin-left: 15px">{{ 'HEADER.LOGIN' | translate }}</span>
    </button>

    <button *ngIf="login" mat-button [matMenuTriggerFor]="menu" style="padding: 3px 0 4px 0; min-width: 138px">
      <i class="far fa-user menu-icon"></i>
      <span style="margin-left: 30px; margin-right: 15px;">{{ login?.name }}</span>
    </button>

    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item class="menu-item"
              (click)="routeService.navigateToProfile(own_domain, firm, language)">{{ 'HEADER.EDIT_PROFILE' | translate }}</button>
      <button mat-menu-item class="menu-item" (click)="routeService.navigateToLoyalty(own_domain, firm, language)"
              *ngIf="firm?.use_loyalty && firm?.loyalty">{{ 'LOYALTY_CARD' | translate }}</button>
      <button mat-menu-item class="menu-item"
              (click)="routeService.navigateToRecentOrders(own_domain, firm, language)">{{ 'RECENT_ORDERS.TITLE' | translate }}</button>
      <button mat-menu-item class="menu-item"
              (click)="routeService.navigateToSettings(own_domain, firm, language)">{{ 'HEADER.SETTINGS' | translate }}</button>
      <button mat-menu-item class="menu-item" (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</button>
    </mat-menu>
  </div>
</div>
