<div
  *ngIf="firm?.theme.show_download_banner"
  class="order-now"
  [class.borders]="displayBorders">
  <div class="centered">

    <div class="content">

      <div class="text"
        [class.minified]="minified">
        <h2>{{ firm?.theme.call_to_actions?.app_download_banner?.title }}</h2>
        <p *ngIf="!minified">{{ firm?.theme.call_to_actions?.app_download_banner?.description }}</p>
        <div class="download-buttons flex align-items-center">
          <a [href]="firm?.android_app_url" target="_blank"><img src="../../assets/img/download_android.png" alt="Download Android App"></a>
          <a [href]="firm?.ios_app_url" target="_blank"><img src="../../assets/img/download_ios.png" alt="Download iOS App"></a>
        </div>
      </div>

      <div class="phone" *ngIf="!minified">
        <img src="../../assets/img/iphone6.png" alt="">
        <div class="screen">
          <img *ngIf="firm?.website_details" [src]="firm?.website_details.app_preview_image" alt="">
        </div>
      </div>

    </div>

  </div>
</div>
