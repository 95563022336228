import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableDeliveryMethodStore {
  public currentSelectedTableNumber = null;
  public currentSelectedTableMethod = null;

  public updateSelectedTableNumberEvent = new EventEmitter();
  public updateSelectedTableMethodEvent = new EventEmitter();

  constructor() {}

  public resetSelectedTableMethod(): void {
    this.currentSelectedTableMethod = null;
    this.updateSelectedTableMethodEvent.emit(this.currentSelectedTableMethod);
    localStorage.removeItem('selectedTableMethod');
  }

  public resetSelectedTableNumber(): void {
    this.currentSelectedTableNumber = null;
    this.updateSelectedTableNumberEvent.emit(this.currentSelectedTableNumber);
    localStorage.removeItem('selectedTableNumber');
  }

  public setSelectedTableNumber(tableNumber: any): void {
    this.currentSelectedTableNumber = tableNumber;
    this.updateSelectedTableNumberEvent.emit(tableNumber);
    localStorage.setItem('selectedTableNumber', JSON.stringify(tableNumber));
  }

  public setSelectedTableMethod(tableMethod: any): void {
    this.currentSelectedTableMethod = tableMethod;
    this.updateSelectedTableMethodEvent.emit(tableMethod);
    localStorage.setItem('selectedTableMethod', JSON.stringify(tableMethod));
  }

  public getSelectedTableMethod() {
    const tableMethod = localStorage.getItem('selectedTableMethod');

    if (tableMethod) {
      return JSON.parse(tableMethod);
    }

    return null;
  }

  public getSelectedTableNumber() {
    const tableNumber = localStorage.getItem('selectedTableNumber');

    if (tableNumber) {
      return JSON.parse(tableNumber);
    }

    return null;
  }
}
