import { Component, Input, OnInit } from '@angular/core';
import { TableDeliveryMethodStore } from '../../store/table-delivery-method.store';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-table-selection',
  templateUrl: './table-selection.component.html',
  styleUrls: ['./table-selection.component.scss'],
})
export class TableSelectionComponent implements OnInit {
  @Input() methods: any;
  @Input() tableNumberRequired: boolean;
  @Input() showSwitcher: boolean;

  selectedMethod: any;
  selectedMethodId: number;
  selectedTableNumber: any;

  constructor(
    private tableDeliveryMethodStore: TableDeliveryMethodStore,
    private dialogRef: MatDialogRef<TableSelectionComponent>
  ) {}

  ngOnInit(): void {
    if (this.methods?.length > 0) {
      const selectedTableMethod = this.tableDeliveryMethodStore.getSelectedTableMethod();
      if (selectedTableMethod) {
        this.selectedMethod = selectedTableMethod;
        this.selectedMethodId = selectedTableMethod.id;
      } else {
        this.selectedMethod = this.methods[0];
        this.selectedMethodId = this.methods[0].id;
      }
    }

    if (this.selectedMethod?.location_numbers?.length > 0) {
      const selectedTableNumber = this.tableDeliveryMethodStore.getSelectedTableNumber();
      if (selectedTableNumber) {
        this.selectedTableNumber = selectedTableNumber;
      }
    }
  }

  selectMethod(method) {
    this.selectedMethod = method;
    this.selectedMethodId = method.id;
    this.selectTableNumber(null);
  }

  selectTableNumber(tableNumber) {
    this.selectedTableNumber = tableNumber;
  }

  isButtonDisabled(): boolean {
    return !this.selectedTableNumber && this.tableNumberRequired;
  }

  save() {
    if (this.selectedMethod) {
      this.tableDeliveryMethodStore.setSelectedTableMethod(this.selectedMethod);
    } else {
      this.tableDeliveryMethodStore.resetSelectedTableMethod();
    }

    if (this.selectedTableNumber) {
      this.tableDeliveryMethodStore.setSelectedTableNumber(this.selectedTableNumber);
    } else {
      this.tableDeliveryMethodStore.resetSelectedTableNumber();
    }

    this.dismiss({ cancelled: false });
  }

  cancel() {
    this.dismiss({ cancelled: true });
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
