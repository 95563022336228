import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { Injectable } from '@angular/core';

import { OrderModalComponent } from '../ordermodal/ordermodal.component';
import { EditOrderModalComponent } from '../edit-ordermodal/edit-ordermodal.component';
import { VatDeliveryMethodComponent } from '../vat-delivery-method/vat-delivery-method.component';
import { QrCodeComponent } from '../qr-code/qr-code.component';
import { AddressModalComponent } from '../shared/address-modal/address-modal.component';
import { BusinessDetailsModalComponent } from '../shared/business-details-modal/business-details-modal.component';
import { SelectInvoiceAddressModalComponent } from '../shared/select-invoice-address-modal/select-invoice-address-modal.component';
import { PickupPointSelectionComponent } from '../modules/delivery-method/components/pickup-point-selection/pickup-point-selection.component';
import { TableSelectionComponent } from '../modules/delivery-method/components/table-selection/table-selection.component';
import { MethodSelectionModalComponent } from '../modules/delivery-method/components/method-selection-modal/method-selection-modal.component';
import { FirmsListModalComponent } from '../modules/firm/components/firms-list-modal/firms-list-modal.component';
import { ReadyToOrderModalComponent } from '../modules/delivery-method/components/ready-to-order-modal/ready-to-order-modal.component';
import { ErrorModal } from '../errormodal/errormodal.component';

@Injectable()
export class DialogsService {
  constructor(private dialog: MatDialog) {}

  public openError(title, message, buttons): Observable<boolean> {
    const dialogRef = this.dialog.open(ErrorModal, {
      panelClass: 'myapp-no-padding-dialog',
      width: '350px',
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.buttons = buttons;

    return dialogRef.afterClosed();
  }

  public openModal(product, firm, cat_id, language): Observable<boolean> {
    const dialogRef = this.dialog.open(OrderModalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.product = product;
    dialogRef.componentInstance.firm = firm;
    dialogRef.componentInstance.cat_id = cat_id;
    dialogRef.componentInstance.language = language;

    return dialogRef.afterClosed();
  }

  public openEditModal(product, firm, cat_id, index, language): Observable<boolean> {
    const dialogRef = this.dialog.open(EditOrderModalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.product = product;
    dialogRef.componentInstance.firm = firm;
    dialogRef.componentInstance.cat_id = cat_id;
    dialogRef.componentInstance.index = index;
    dialogRef.componentInstance.language = language;

    return dialogRef.afterClosed();
  }

  public openVATModal(firm): Observable<boolean> {
    const dialogRef = this.dialog.open(VatDeliveryMethodComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
    });

    dialogRef.componentInstance.firm = firm;

    return dialogRef.afterClosed();
  }

  public openQR(qr): Observable<boolean> {
    const dialogRef = this.dialog.open(QrCodeComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: 'fit-content',
    });

    dialogRef.componentInstance.qr = qr;

    return dialogRef.afterClosed();
  }

  public openAddressModal(header, subHeader, buttonLabel, countries, address?) {
    const dialogRef = this.dialog.open(AddressModalComponent, {
      panelClass: 'side-modal',
      height: '100%',
    });

    dialogRef.componentInstance.header = header;
    dialogRef.componentInstance.subHeader = subHeader;
    dialogRef.componentInstance.buttonLabel = buttonLabel;
    dialogRef.componentInstance.countries = countries;

    if (address) {
      dialogRef.componentInstance.address = address;
    }

    return dialogRef.afterClosed();
  }

  public openBusinessDetailsModal(header, subHeader, buttonLabel, businessDetails) {
    const dialogRef = this.dialog.open(BusinessDetailsModalComponent, {
      panelClass: 'side-modal',
      height: '100%',
    });

    dialogRef.componentInstance.header = header;
    dialogRef.componentInstance.subHeader = subHeader;
    dialogRef.componentInstance.buttonLabel = buttonLabel;
    dialogRef.componentInstance.businessDetails = businessDetails;

    return dialogRef.afterClosed();
  }

  public openInvoiceAddressModal(header, subHeader, buttonLabel, addressList, selectedId) {
    const dialogRef = this.dialog.open(SelectInvoiceAddressModalComponent, {
      panelClass: 'side-modal',
      height: '100%',
    });

    dialogRef.componentInstance.header = header;
    dialogRef.componentInstance.subHeader = subHeader;
    dialogRef.componentInstance.buttonLabel = buttonLabel;
    dialogRef.componentInstance.addressList = addressList;
    dialogRef.componentInstance.selectedId = selectedId;

    return dialogRef.afterClosed();
  }

  public openPickPointSelectionModal(pickupPoints, showSwitcher): Observable<any> {
    const dialogRef = this.dialog.open(PickupPointSelectionComponent, {
      panelClass: 'eo-modal',
      width: '400px',
    });

    dialogRef.componentInstance.pickupPoints = pickupPoints;
    dialogRef.componentInstance.showSwitcher = showSwitcher;

    return dialogRef.afterClosed();
  }

  public openTableSelectionModal(methods, firm, showSwitcher) {
    const dialogRef = this.dialog.open(TableSelectionComponent, {
      panelClass: 'eo-modal',
      width: '400px',
    });

    if (!methods) {
      methods = [];
    }

    if (!methods.find((m) => m.id === 0)) {
      methods.unshift({
        id: 0,
        name: firm.labels.at_table,
        location_number_name: firm.labels.table_number,
        location_numbers: firm.table_numbers,
      });
    }

    dialogRef.componentInstance.methods = methods;
    dialogRef.componentInstance.tableNumberRequired = firm.table_number_required;
    dialogRef.componentInstance.showSwitcher = showSwitcher;

    return dialogRef.afterClosed();
  }

  public openFirmListModal(deliveryMethod: string): Observable<any> {
    const dialogRef = this.dialog.open(FirmsListModalComponent, {
      panelClass: 'eo-modal',
      width: '400px',
    });

    dialogRef.componentInstance.deliveryMethod = deliveryMethod;

    return dialogRef.afterClosed();
  }

  public openMethodSelectionModal(): Observable<any> {
    const dialogRef = this.dialog.open(MethodSelectionModalComponent, {
      panelClass: 'eo-modal',
      width: '400px',
    });

    return dialogRef.afterClosed();
  }

  public openReadyToOrderModal(): Observable<any> {
    const dialogRef = this.dialog.open(ReadyToOrderModalComponent, {
      panelClass: 'eo-modal',
      width: '400px',
    });

    return dialogRef.afterClosed();
  }
}
