import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BasketItemsService } from '../services/basket-items.service';
import { FirmService } from '../services/firm.service';
import { OrderService } from '../services/order.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';
import { DialogsService } from '../services/dialogs.service';
import { DeliveryTimePickerService } from '../modules/delivery-time/services/delivery-time-picker.service';

@Component({
  selector: 'app-order-processed',
  templateUrl: './order-processed.component.html',
  styleUrls: ['./order-processed.component.css'],
})
export class OrderProcessedComponent implements OnInit {
  own_domain: boolean;
  orderShow = false;
  order: any;
  firm: any;
  firm_name: any;
  language: any;
  order_id: any;
  datetime: any;
  param: any;

  /* QR */
  qr_code: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public slug: SlugifyService,
    public firmService: FirmService,
    public orderService: OrderService,
    public basketItemsService: BasketItemsService,
    public routeService: RouteService,
    public dialogsService: DialogsService,
    private deliveryTimePickerService: DeliveryTimePickerService
  ) {
    window.scrollTo(0, 0);
    this.firm_name = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;
    this.order_id = this.route.snapshot.params.order_id;
  }

  async ngOnInit() {
    const response = await this.firmService.getFirmByCheckingDomain(this.firm_name, this.language);
    this.firm = response.firm;
    this.own_domain = response.own_domain;

    const login: any = JSON.parse(localStorage.getItem('login'));
    if (login) {
      const order = await this.orderService.get(this.firm.id, this.order_id, login.id, this.language);

      if (order !== 'ERROR') {
        // Set firm
        this.order = order;

        // Enable order section
        this.orderShow = true;

        // Set QR
        this.qr_code = JSON.stringify(this.order.qr_url);

        // Set customer email
        this.param = {
          email: this.order.customer.email,
        };

        // Set locale for timestamp
        moment.locale(this.language);
        this.datetime = moment(this.order.process_timestamp).format('LL HH:mm');
      }
    }

    await this.deliveryTimePickerService.selectFirstAvailableTime();

    // cleanup
    localStorage.removeItem('uuid');
    sessionStorage.removeItem('payment_activated');
    this.basketItemsService.removeAll(this.firm.id);
  }

  clear() {
    sessionStorage.clear();
  }

  openQrCode() {
    this.dialogsService.openQR(this.qr_code);
  }
}
