<head>
    <title>{{ firm?.name }} | {{ search_term }}</title>
</head>
<body>

    <div class="main">
        <!-- Header -->
        <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
        <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

        <div class="pagecontent"
             style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
             [style.color]="firm?.theme.main_gradient_contrast_color">

            <div class="view-productcategory">
                <div class="centered">

                    <!-- Vat delivery method -->
                    <app-vat-delivery-method-opener *ngIf="firm" [firm]="firm" (vatChanged)="vatDeliveryMethodChanged($event)"></app-vat-delivery-method-opener>

                    <h1 >{{ 'SEARCH.RESULTS_FOR' | translate }} "{{ search_term }}"</h1>

                    <p *ngIf="items?.length == 0">{{ 'SEARCH.NO_RESULTS' | translate }}</p>

                    <ul class="products row" *ngIf="items?.length > 0 && firm?.product_images_enabled">
                        <li class="col third col-sm " *ngFor="let item of items">
                          <app-category-item
                            [favorites]="false"
                            [images_enabled]="true"
                            [vat_delivery_method]="vat_delivery_method"
                            [item]="item"
                            [firm]="firm"
                            [own_domain]="own_domain"
                            [parent]="'search'">
                          </app-category-item>
                        </li>

                    </ul>

                    <!-- Products without image -->
                    <ul class="products row no-picture " *ngIf="items?.length > 0 && !firm?.product_images_enabled">
                        <li class="col third col-sm no-picture" *ngFor="let item of items">
                          <app-category-item
                            [favorites]="false"
                            [images_enabled]="false"
                            [vat_delivery_method]="vat_delivery_method"
                            [item]="item"
                            [firm]="firm"
                            [own_domain]="own_domain"
                            [parent]="'search'">
                          </app-category-item>
                        </li>
                    </ul>

                </div>
            </div>

          <!-- Order now -->
          <order-now-bar *ngIf="firm" [firm]="firm" [minified]="true" [displayBorders]="false"></order-now-bar>

        </div>

        <!-- Footer -->
        <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

    </div>

    <div id="iosfix-top"></div>
    <div id="iosfix-btm"></div>

</body>
