import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { DeliveryTimeModalComponent } from './components/delivery-time-modal/delivery-time-modal.component';
import { DeliveryDateTimePickerComponent } from './components/delivery-date-time-picker/delivery-date-time-picker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CustomDaysPickerComponent } from './components/custom-days-picker/custom-days-picker.component';
import { TimeslotsPickerComponent } from './components/timeslots-picker/timeslots-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    DeliveryTimeModalComponent,
    DeliveryDateTimePickerComponent,
    CustomDaysPickerComponent,
    TimeslotsPickerComponent,
  ],
  exports: [
    DeliveryTimeModalComponent,
    DeliveryDateTimePickerComponent,
    CustomDaysPickerComponent,
    TimeslotsPickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatCheckboxModule,
  ],
})
export class DeliveryTimeModule {}
