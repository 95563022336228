<body
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.container_contrast_color"
>

<div class="main checkout">


  <app-header *ngIf="firmService.checkEmbed() == false" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
  <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

  <div class="centered">

    <!-- Content -->
    <div class="pagecontent">

      <div class="row">
        <div class="col">

          <div *ngIf="!orderShow" class="view-processed">
            <h1 >{{ 'THANK.TITLE' | translate }}</h1>
            <p>{{ 'THANK.EMAIL_STANDARD' | translate:param }}</p>
            <br>
            <a href="javascript:void(0)" class="btn-fill"
              (click)="clear(); routeService.navigateToMenu()">
              {{ 'CHECKOUT_HEADER.BACK_TO_SITE' | translate }}
            </a>
          </div>

          <div *ngIf="orderShow" class="view-processed">

            <h1 class="color-green title-font">{{ 'THANK.TITLE' | translate }}</h1>

            <p *ngIf="order?.delivery_method == 'pickup'">{{ 'THANK.EMAIL_PICKUP' | translate:param }}</p>
            <p *ngIf="order?.delivery_method == 'delivery'">{{ 'THANK.EMAIL_DELIVERY' | translate:param }}</p>
            <p *ngIf="order?.delivery_method == 'table'">{{ 'THANK.EMAIL_TABLE' | translate:param }}</p>

            <div class="qr" *ngIf="qr_code">
              <a href="javascript:void(0)" (click)="openQrCode()" class="qr-btn"><i class="fas fa-qrcode icon"></i></a>
            </div>

            <div class="summary clearfix">
              <img src="../../assets/img/calendar.png">
              <div class="text">
                <div class="title">{{ datetime }}</div>
                <div class="reference">
                  <span>{{ 'THANK.REFERENCE' | translate }}</span> #{{ order?.reference }}
                </div>
              </div>
            </div>

            <!-- PICKUP -->
            <div *ngIf="order?.delivery_method == 'pickup'" class="pickuppoint clearfix">
              <img src="../../assets/img/location.png">
              <div class="text">
                <div class="title">{{ !order?.pickup_point ? firm?.name : order?.pickup_point.name }}</div>
                <div class="address">
                  <span class="icon pickup"></span>{{ 'OVERVIEW.PICKUP' | translate }} - {{ datetime }}
                  <br>
                  <span *ngIf="!order.pickup_point">{{ firm?.address }}, {{ firm?.zipcode }} {{ firm?.locality }}</span>
                  <span *ngIf="order.pickup_point">{{ order?.pickup_point?.address }}
                    , {{ order?.pickup_point?.zipcode }} {{ order?.pickup_point?.locality }}</span>
                </div>
              </div>
            </div>

            <!-- DELIVERY -->
            <div *ngIf="order?.delivery_method == 'delivery'" class="pickuppoint clearfix">
              <img src="../../assets/img/location.png">
              <div class="text">
                <div class="address">
                  {{ order?.delivery_street }} {{ order?.delivery_street_nr }}
                  <br/>
                  {{ order?.delivery_zipcode }} {{ order?.delivery_locality }}
                </div>
              </div>
            </div>

            <!-- TABLE -->
            <div *ngIf="order?.delivery_method == 'table'" class="pickuppoint clearfix">
              <img src="../../assets/img/location.png">
              <div class="text">
                <div class="address">
                  <i class="fas fa-utensils icon-table"></i>{{ 'OVERVIEW.TABLE' | translate }} {{ order.table_number }}
                </div>
              </div>
            </div>

            <br/>
            <a
              class="btn-fill custom-btn"
              (click)="clear(); routeService.navigateToMenu()"
            >
              <i class="fas fa-angle-left"></i>
              {{ 'CHECKOUT_HEADER.BACK_TO_SITE' | translate }}
            </a>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</body>
