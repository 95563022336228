import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuStore {
  public viewMenuActive = new BehaviorSubject<boolean>(false);

  constructor() {
    const viewMenuActive = this.getViewMenuActive();
    if (viewMenuActive) {
      this.setViewMenuActive(viewMenuActive);
    }
  }

  getViewMenuActive() {
    const viewMenuActive = localStorage.getItem('viewMenuActive');

    if (!viewMenuActive) {
      return null;
    }

    return JSON.parse(viewMenuActive);
  }

  setViewMenuActive(viewMenuActive: boolean) {
    this.viewMenuActive.next(viewMenuActive);
    localStorage.setItem('viewMenuActive', JSON.stringify(viewMenuActive));
  }
}
