import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogsService } from '../../../../services/dialogs.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ErrorModalComponent>,
    private dialog: MatDialog,
    private modalService: DialogsService
  ) {}

  handleOnSubmit() {
    this.dialog.closeAll();
    this.modalService.openMethodSelectionModal();
  }

  close() {
    this.dialogRef.close();
  }
}
