import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Units } from '../constants/units';
import * as moment from 'moment';
import { ProductService } from '../services/products.service';
import { BasketItemsService } from '../services/basket-items.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { AddToCartService } from '../services/add-to-cart.service';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.css'],
  animations: [
    trigger('openSelector', [transition(':enter', [style({ opacity: 0 }), animate('4000ms', style({ opacity: 1 }))])]),
  ],
})
export class OrderItemComponent implements OnInit {
  @Input() parent: any;
  @Input() firm: any;
  @Input() language: any;
  @Input() sequenceGroup: any;
  @Input() basketItem: any;
  @Output() itemChangedOutput;
  @Output() disableBtnOutput;

  moment = moment;

  basketItems: any;
  basketAmount: any;

  init_product: any;
  options_selected: any;
  colors: any;
  ingr_label: any;
  freeExists: boolean;
  paidExists: boolean;
  ingredients: any;
  quantity: any;
  comment: string;
  options_price: any = 0;
  cost: any;
  disableBtn: boolean;
  amountAlreadyInCart: number;
  unit_price: any;

  units = Units;

  constructor(
    public productsService: ProductService,
    private basketItemsService: BasketItemsService,
    private addToCartService: AddToCartService
  ) {
    // Set event emitters
    this.itemChangedOutput = new EventEmitter<any>();
    this.disableBtnOutput = new EventEmitter<any>();
  }

  async ngOnInit() {
    moment.locale(this.language);

    // Set init product
    this.init_product = this.basketItem.init_item;

    // Add VAT
    if (this.firm.uses_vat) {
      this.unit_price = this.unit_price + (this.unit_price / 100) * this.basketItem.item.vat_percentage;
    }

    if (this.parent === 'item') {
      this.ingredients = [];
      this.options_selected = [];
      this.options_price = 0;

      // Set quantity
      if (this.basketItem.item.unit.id === Units.GRAM || this.basketItem.item.unit.id === Units.KG) {
        this.quantity = this.basketItem.item.min ? this.basketItem.item.min : this.basketItem.item.unit_interval;
      } else {
        this.quantity = this.basketItem.item.min ? this.basketItem.item.min : 1;
      }

      // Check option groups
      if (this.basketItem.item.option_groups) {
        await this.basketItem.item.option_groups.forEach((option_group) => {
          const selected_options = option_group.options.filter(
            (option) => option.selected && !this.productsService.isSnoozed(option)
          );
          if (selected_options) {
            selected_options.forEach((option) => {
              if (option.selected && !this.productsService.isSnoozed(option)) {
                this.addOption(option, option_group.id);
              }
            });
          }
        });

        // Add options price to product
        if (this.basketItem.item.has_promotional_price) {
          this.basketItem.item.promotion.price = this.basketItem.item.promotion.price + this.options_price;
        } else {
          this.basketItem.item.price = this.basketItem.item.price + this.options_price;
        }
      }

      // Set ingredients
      if (this.basketItem.item.ingredient_groups.length > 0) {
        this.basketItem.item.ingredient_groups.forEach((ingr_gr) => {
          ingr_gr.ingredients.forEach((ingr) => {
            ingr.amount = 1;
            if (ingr.type === 'main') {
              ingr.action = 'add';
              ingr.selected = 1;
              this.addToIngredients(ingr);
            }
          });
        });
      }
    } else if (this.parent === 'basket') {
      this.quantity = this.basketItem.amount;
      this.ingredients = this.basketItem.ingredients;
      this.options_selected = this.basketItem.options_selected;
      this.comment = this.basketItem.comment;
    }

    this.disableNextButton();

    // Set unit price
    if (this.basketItem.item.has_promotional_price) {
      this.unit_price = this.basketItem.item.promotion.price;
    } else {
      this.unit_price = this.basketItem.item.price;
    }

    this.basketItems = await this.basketItemsService.get(this.firm.id);
    this.getBasketAmount();

    this.emit();
  }

  quantityChanged(event) {
    this.quantity = event;
    this.emit();
  }

  subtractIngredientAmount(ingredient) {
    ingredient.amount = Number(ingredient.amount) - 1;
    this.updateIngredientAmount(ingredient);
    this.disableNextButton();
  }

  addIngredientAmount(ingredient) {
    ingredient.amount = Number(ingredient.amount) + 1;
    this.updateIngredientAmount(ingredient);
    this.disableNextButton();
  }

  /*disable ingredient checkboxes when the max has been reached*/
  disableIngredientCheckbox(ingredient, ingredient_group) {
    return this.hasReachedGroupMax(ingredient_group) && !ingredient.selected;
  }

  /* Disable next button when min amount hasn't been reached yet or when not all option groups have been selected */
  async disableNextButton() {
    const ingrDisabled = this.disableIngredientNextButton();
    const optDisabled = !this.addToCartService.haveAllGroupsBeenSelected(
      this.basketItem.item.option_groups,
      this.options_selected
    );
    this.disableBtnOutput.emit(ingrDisabled || optDisabled);
  }

  /* Disable ingredient next button when min amount hasn't been reached yet */
  disableIngredientNextButton() {
    return !this.addToCartService.haveAllGroupsReachedGroupMin(this.basketItem.item.ingredient_groups);
  }

  /* Check if amount has reached group max */
  hasReachedGroupMax(group) {
    let selectedIngredients = 0;
    if (group && group.max_selection !== null) {
      group.ingredients.forEach((ingr) => {
        if (ingr.selected && ingr.type !== 'main') {
          selectedIngredients += Number(ingr.amount);
        }
      });
      return selectedIngredients >= group.max_selection;
    } else {
      return false;
    }
  }

  /* Check if amount has reached ingredient max */
  hasReachedMultiMax(ingredient, group) {
    return group.multi_max ? ingredient.amount >= group.multi_max : false;
  }

  /* Check / uncheck an ingredient */
  async checkIngredient(selectedIngredient, ingredient_group) {
    if (!this.disableIngredientCheckbox(selectedIngredient, ingredient_group)) {
      const originalIngredient: any = await this.getOriginalIngredient(selectedIngredient);

      if (!originalIngredient.selected && !selectedIngredient.selected) {
        originalIngredient.action = 'add';
        originalIngredient.amount = selectedIngredient.amount;
        this.addToIngredients(originalIngredient);
        selectedIngredient.type === 'paid' ? this.addPriceToItem(selectedIngredient.price) : null;
      } else if (originalIngredient.selected && selectedIngredient.selected) {
        originalIngredient.action = 'rem';
        this.addToIngredients(originalIngredient);
        selectedIngredient.type === 'paid' ? this.addPriceToItem(selectedIngredient.price) : null;
        selectedIngredient.amount = 1;
      } else {
        this.removeFromIngredients(selectedIngredient.id);
        selectedIngredient.type === 'paid'
          ? this.subtractPriceFromItem(selectedIngredient.price, selectedIngredient.amount)
          : null;
        selectedIngredient.amount = 1;
      }

      selectedIngredient.selected = !selectedIngredient.selected;

      this.recalculateUnitPrice();
      this.disableNextButton();
      this.emit();
    }
  }

  /* Get ingredient from initial product */
  getOriginalIngredient(ingredient): Promise<any> {
    return new Promise(async (resolve) => {
      const init_product = JSON.parse(this.init_product);
      init_product.ingredient_groups.forEach((ingr_group) => {
        ingr_group.ingredients.forEach((ingr) => {
          if (ingredient.id === ingr.id) {
            resolve(ingr);
          }
        });
      });
    });
  }

  /* Add to ingredients list */
  addToIngredients(ingredient) {
    this.ingredients.push(ingredient);
    this.emit();
  }

  /* Remove from igredients list */
  removeFromIngredients(ingr_id) {
    this.ingredients.forEach(async (ingr) => {
      if (ingr.id === ingr_id) {
        const index = await this.getIndex(ingr);
        if (index > -1) {
          this.ingredients.splice(index, 1);
        }
      }
    });
    this.emit();
  }

  /* Update the ingredient amount */
  updateIngredientAmount(ingredient) {
    this.ingredients.forEach(async (ingr) => {
      if (ingr.id === ingredient.id) {
        ingr.amount = Number(ingredient.amount);
      }
    });
    this.emit();
  }

  addPriceToItem(price) {
    if (this.basketItem.item.has_promotional_price) {
      this.basketItem.item.promotion.price = this.basketItem.item.promotion.price + price;
    } else {
      this.basketItem.item.price = this.basketItem.item.price + price;
    }
  }

  subtractPriceFromItem(price, quantity?) {
    if (!quantity) quantity = 1;
    if (this.basketItem.item.has_promotional_price) {
      this.basketItem.item.promotion.price = this.basketItem.item.promotion.price - price * quantity;
    } else {
      this.basketItem.item.price = this.basketItem.item.price - price * quantity;
    }
  }

  /* Get index of basket item */
  getIndex(ingr: any) {
    return this.ingredients.indexOf(ingr);
  }

  /* Get group */
  getGroup(group) {
    if (group.type === 'option_group') {
      return this.basketItem.item.option_groups.find((ingr_gr) => ingr_gr.id === group.id);
    } else {
      return this.basketItem.item.ingredient_groups.find((ingr_gr) => ingr_gr.id === group.id);
    }
  }

  /* Check an option */
  checkOptionGroup(group, option) {
    option.selected = true;

    if (this.options_selected && this.options_selected.length > 0) {
      const opt_gr = this.options_selected.find((option_group) => option_group.id === group.id);

      if (opt_gr) {
        this.subtractPriceFromItem(opt_gr.selected_option.price);
        opt_gr.selected_option = option;
        this.addPriceToItem(opt_gr.selected_option.price);
      } else {
        this.addOption(option, group.id);
        this.addPriceToItem(option.price);
      }
    } else {
      this.addOption(option, group.id);
      this.addPriceToItem(option.price);
    }

    this.recalculateUnitPrice();
    this.disableNextButton();
    this.emit();
  }

  addOption(option, group_id) {
    this.options_price = this.options_price + option.price;
    this.options_selected.push({
      id: group_id,
      selected_option: option,
    });
  }

  getBasketAmount() {
    this.basketAmount = this.basketItemsService.getTotalBasketAmount(this.firm.id, this.basketItems);
    this.amountAlreadyInCart = this.basketItemsService.getProductAmountFromBasket(
      this.basketItem.item.id,
      this.basketItems
    );

    if (this.parent === 'basket') {
      this.basketAmount -= this.basketItem.amount;
      this.amountAlreadyInCart -= this.basketItem.amount;
    }
  }

  recalculateUnitPrice() {
    if (this.basketItem.item.has_promotional_price) {
      this.unit_price = this.basketItem.item.promotion.price;
    } else {
      this.unit_price = this.basketItem.item.price;
    }

    // Add VAT
    if (this.firm.uses_vat) {
      this.unit_price = this.unit_price + (this.unit_price / 100) * this.basketItem.item.vat_percentage;
    }
  }

  isInStock(item) {
    return item.in_stock;
  }

  async handleQuantitySubtract(ingredient) {
    this.subtractIngredientAmount(ingredient);

    const originalIngredient = await this.getOriginalIngredient(ingredient);
    if (originalIngredient.selected && ingredient.amount === 1) {
      this.removeFromIngredients(originalIngredient.id);
    }

    this.subtractPriceFromItem(ingredient.price);
    this.recalculateUnitPrice();
    this.emit();
  }

  async handleQuantityAdd(ingredient) {
    this.addIngredientAmount(ingredient);

    const originalIngredient = await this.getOriginalIngredient(ingredient);
    if (originalIngredient.selected && ingredient.amount === 2) {
      originalIngredient.action = 'add';
      originalIngredient.amount = ingredient.amount;
      this.addToIngredients(originalIngredient);
    }

    this.addPriceToItem(ingredient.price);
    this.recalculateUnitPrice();
    this.emit();
  }

  emit() {
    this.basketItem.amount = this.quantity;

    let price;
    if (this.basketItem.item.has_promotional_price) {
      price = this.basketItem.item.promotion.price;
    } else {
      price = this.basketItem.item.price;
    }

    if (this.basketItem.item.unit.id === Units.KG) {
      this.basketItem.total_price = price.toFixed(2) * (this.basketItem.amount / 1000);
    } else {
      this.basketItem.total_price = price.toFixed(2) * this.basketItem.amount;
    }
    this.basketItem.ingredients = this.ingredients;
    this.basketItem.options_selected = this.options_selected;
    this.basketItem.comment = this.comment;
    this.itemChangedOutput.emit({ basketItem: this.basketItem });
  }
}
