<div *ngIf="!loading; else loader" [ngClass]="template">
  <eo-square-btn
    *ngIf="displayPickupMethod()"
    icon="takeaway"
    [label]="'TAKEAWAY' | translate"
    [disabled]="disablePickupMethod()"
    (clicked)="selectPickupMethod()">
  </eo-square-btn>

  <eo-square-btn
    *ngIf="displayDeliveryMethod()"
    icon="delivery"
    [label]="'DELIVERY' | translate"
    [disabled]="disableDeliveryMethod()"
    (clicked)="selectDeliveryMethod()">
  </eo-square-btn>

  <eo-square-btn
    *ngIf="displayPickupPointMethod()"
    icon="pickuppoint"
    [label]="'PICKUP_POINT' | translate"
    [disabled]="disablePickupMethod()"
    (clicked)="selectPickupPointMethod()">
  </eo-square-btn>

  <eo-square-btn
    *ngIf="displayTableMethod()"
    icon="qrcode"
    [label]="'ORDER_ON_THE_SPOT' | translate"
    [disabled]="disableTableMethod()"
    (clicked)="selectTableMethod()">
  </eo-square-btn>

  <eo-square-btn
    *ngIf="displayViewMenu()"
    icon="book"
    [label]="'VIEW_MENU' | translate"
    (clicked)="viewMenu()">
  </eo-square-btn>
</div>

<ng-template #loader>
  <div class="loader-container width-full flex align-items-center justify-center">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</ng-template>
