import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogsService } from '../../../../services/dialogs.service';

@Component({
  selector: 'method-delivery-switcher',
  templateUrl: './method-delivery-switcher.component.html',
  styleUrls: ['./method-delivery-switcher.component.scss'],
})
export class MethodDeliverySwitcherComponent {
  constructor(private dialogRef: MatDialogRef<any>, private modalService: DialogsService) {}

  public openMethodSelectionModal(): void {
    this.dialogRef.close({ cancelled: true });
    this.modalService.openMethodSelectionModal();
  }
}
