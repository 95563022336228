import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { ProductService } from '../services/products.service';
import { DateService } from '../services/date.service';

@Component({
  selector: 'eo-snoozed-label',
  templateUrl: './snoozed-label.component.html',
  styleUrls: ['./snoozed-label.component.css'],
})
export class SnoozedLabelComponent {
  @Input() snoozeEnd: string;

  moment = moment;

  constructor(public productService: ProductService, private dateService: DateService) {}

  getDateTillSnoozeEnd(): string {
    return this.dateService.getDateTillSnoozeEnd(this.snoozeEnd);
  }
}
