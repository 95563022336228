<div class="iframe-ingredients" mat-dialog-content>
  <div class="quantity clearfix">

    <div class="input">

      <div class="label">
        <label for="amount">
          {{ 'MODAL.AMOUNT' | translate }} ({{ basketItem?.item.unit.name_singular}})
        </label>
      </div>

      <eo-quantity-selector>
        <eo-unit-selector
          *ngIf="basketItem?.item.unit.id === units.UNIT || basketItem?.item.unit.id === units.LITER || basketItem?.item.unit.id === units.PERSON"
          [quantity]="quantity"
          [minQuantity]="basketItem?.item.min"
          [maxQuantity]="basketItem?.item.max"
          [maxCartQuantity]="firm?.max_cart_amount"
          [basketAmount]="basketAmount"
          [amountAlreadyInCart]="amountAlreadyInCart"
          (quantityChanged)="quantityChanged($event)">
        </eo-unit-selector>
        <eo-weight-selector
          *ngIf="basketItem?.item.unit.id === units.GRAM || basketItem?.item.unit.id === units.KG"
          [quantity]="quantity"
          [minQuantity]="basketItem?.item?.min"
          [maxQuantity]="basketItem?.item?.max"
          [interval]="basketItem?.item?.unit_interval"
          [unitId]="basketItem?.item?.unit.id"
          (quantityChanged)="quantityChanged($event)">
        </eo-weight-selector>
      </eo-quantity-selector>

    </div>

    <div class="price" *ngIf="basketItem?.item.unit.id !== units.KG">
      {{ firm?.currency?.symbol }}{{ unit_price?.toFixed(2) * quantity | number:'1.2-2' }}
    </div>
    <div class="price" *ngIf="basketItem?.item.unit.id === units.KG">
      {{ firm?.currency?.symbol }}{{ unit_price?.toFixed(2) * (quantity/1000) | number:'1.2-2' }}
    </div>

  </div>

  <div *ngIf="sequenceGroup?.length > 0">
    <div *ngFor="let group of sequenceGroup">

      <!-- Ingredients -->
      <div *ngIf="group.type === 'ingredient_group'">
        <div>
          <label>{{ getGroup(group).name }}
            <mat-icon *ngIf="getGroup(group).description" [matTooltip]="getGroup(group).description"><i class="fas fa-question-circle option-description"></i></mat-icon>
          </label>
          <p *ngIf="group.min_selection > 0">{{'MINIMUM' | translate}}: {{group.min_selection}}</p>
          <p *ngIf="group.max_selection > 0">{{'MAXIMUM' | translate}}: {{group.max_selection}}</p>

          <div *ngIf="getGroup(group).ingredients">
            <div class="ingredients" *ngIf="getGroup(group).ingredients.length != 0">
              <div class="columns">

                <!-- Main -->
                <div class="checkbox" *ngFor="let ingr of getGroup(group).ingredients">
                  <eo-modifier
                    *ngIf="ingr?.type === 'main'"
                    type="checkbox"
                    [label]="ingr.name"
                    [disabled]="true"
                    [checked]="true"
                    [isSnoozed]="productsService.isSnoozed(ingr)"
                    [snoozeEnd]="ingr.snooze_end"
                    [inStock]="isInStock(ingr)"
                    [enableImage]="firm?.theme?.show_modifier_images"
                    [image]="ingr.image">
                  </eo-modifier>
                </div>

                <!-- Free / Paid -->
                <div class="checkbox" *ngFor="let ingr of getGroup(group).ingredients">
                  <eo-modifier
                    *ngIf="ingr?.type === 'paid' || ingr?.type === 'free'"
                    type="checkbox"
                    [label]="ingr.name"
                    [disabled]="disableIngredientCheckbox(ingr, getGroup(group)) || productsService.isSnoozed(ingr) || !isInStock(ingr)"
                    [checked]="ingr.selected && isInStock(ingr)"
                    [isSnoozed]="productsService.isSnoozed(ingr)"
                    [snoozeEnd]="ingr.snooze_end"
                    [inStock]="isInStock(ingr)"
                    [price]="ingr.price"
                    [currency]="firm?.currency.symbol"
                    [unitId]="basketItem?.unit_id"
                    [enableImage]="firm?.theme?.show_modifier_images"
                    [image]="ingr.image"
                    [multiMax]="getGroup(group).multi_max"
                    [reachedGroupMax]="hasReachedGroupMax(getGroup(group))"
                    (selected)="checkIngredient(ingr, getGroup(group))"
                    (quantitySubtract)="handleQuantitySubtract(ingr)"
                    (quantityAdd)="handleQuantityAdd(ingr)">
                  </eo-modifier>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Product options -->
      <div *ngIf="group.type === 'option_group'">
        <div>
          <!-- Option group -->
          <label>{{ getGroup(group).name }}
            <mat-icon *ngIf="getGroup(group).description" [matTooltip]="getGroup(group).description"><i class="fas fa-question-circle option-description"></i></mat-icon>
          </label>

          <div class="ingredients">
            <mat-radio-group [name]="getGroup(group).name">

              <!-- Options -->
              <div class="columns">
                <div *ngFor="let option of getGroup(group).options">
                  <eo-modifier
                    type="radio"
                    [label]="option.name"
                    [id]="option.id"
                    [description]="option.description"
                    [checked]="option.selected && !productsService.isSnoozed(option) && isInStock(option)"
                    [disabled]="productsService.isSnoozed(option) || !isInStock(option)"
                    [isSnoozed]="productsService.isSnoozed(option)"
                    [snoozeEnd]="option.snooze_end"
                    [inStock]="isInStock(option)"
                    [price]="option.price"
                    [currency]="firm?.currency?.symbol"
                    [unitId]="basketItem?.item.unit.id"
                    [enableImage]="firm?.theme?.show_modifier_images"
                    [image]="option.image"
                    (selected)="checkOptionGroup(getGroup(group), option)">
                  </eo-modifier>
                </div>
              </div>

            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="remarks">
    <div class="input">
      <div class="inputfield">
        <textarea placeholder="{{ 'MODAL.MESSAGE' | translate }}" rows="4" name="remarks" cols="50"
                  [(ngModel)]="comment" (ngModelChange)="emit()"></textarea>
      </div>
    </div>
  </div>

</div>
