import { Component, Input, OnInit } from '@angular/core';
import { RouteService } from '../services/route.service';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.css']
})
export class OrderHeaderComponent implements OnInit {

  @Input() focused;
  @Input() firm;
  @Input() own_domain;
  @Input() language;

  constructor(private routeService: RouteService) {}

  ngOnInit() {
    this.setCenter();
  }

  navigateTo(page) {
    switch (page) {
      case 'basket':
        this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
        break;
      case 'payment':
        this.routeService.navigateToPaymentMethod(this.own_domain, this.firm, this.language);
        break;
      case 'overview':
        this.routeService.navigateToOverview(this.own_domain, this.firm, this.language);
        break;
    }
  }

  async setCenter() {
    setTimeout(async () => {
      const activeEl = await document.getElementById(`${this.focused}_focus`);
      if(activeEl) activeEl.scrollIntoView({block: "end", behavior: "smooth", inline: "center"});
    });
  }

}
