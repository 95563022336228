<div>
  <p *ngIf="showHeader">{{ 'CHOOSE_DATE_TIME' | translate }}</p>

  <p>{{ 'DATE' | translate }}:</p>
  <div class="date-box clickable" tappable (click)="openDateDayPicker()">
    {{ dateDay }}
  </div>
  <input matInput
    [matDatepicker]="dateDayPicker"
    [(ngModel)]="dateDayModel"
    [hidden]="true"
    (dateChange)="handleOnDateDayChange()"
    name="date">
  <mat-datepicker touchUi #dateDayPicker disabled="false"></mat-datepicker>

  <div class="spacer"></div>

  <p>{{ 'TIME' | translate }}:</p>
  <div *ngIf="loadingTimeslot && !noTimeslotAvailable">
    <ng-container *ngTemplateOutlet="loading"></ng-container>
  </div>

  <div class="flex align-items-center overflow-x-auto" *ngIf="!loadingTimeslot && !noTimeslotAvailable">
    <div
      *ngFor="let timeslot of timeslots"
      class="timeslot date-box clickable mr-10"
      [class.selected]="selectedTimeslot === timeslot.id"
      tappable
      (click)="selectTimeslot(timeslot.id)">
      {{ timeslot.from_time }} - {{ timeslot.to_time }}
    </div>
  </div>

  <div *ngIf="intervals?.length > 0" class="horizontal-ruler"></div>

  <div class="flex align-items-center overflow-x-auto" *ngIf="!loadingInterval; else loading">
    <div
      *ngFor="let interval of intervals"
      class="interval date-box clickable mr-10"
      [class.selected]="selectedInterval === interval.time"
      tappable
      (click)="selectInterval(interval.time)">
      {{ interval.time }}
    </div>
  </div>

  <div class="error" *ngIf="noTimeslotAvailable">
    {{ 'MESSAGES.NO_TIMESLOTS_AVAILABLE' | translate }}
  </div>

</div>


<ng-template #loading>
  <p>{{ 'LOADING' | translate }}</p>
</ng-template>
