import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuStore } from '../../../menu/store/menu.store';

@Component({
  selector: 'app-ready-to-order-modal',
  templateUrl: './ready-to-order-modal.component.html',
  styleUrls: ['./ready-to-order-modal.component.css'],
})
export class ReadyToOrderModalComponent {
  constructor(private dialogRef: MatDialogRef<ReadyToOrderModalComponent>, private menuStore: MenuStore) {}

  handleOnMethodSelect(event) {
    this.menuStore.setViewMenuActive(false);
    this.dismiss(event);
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
