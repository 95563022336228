import { Injectable } from '@angular/core';
import { ProductService } from '../../services/products.service';

@Injectable({
  providedIn: 'root',
})
export class ProductStore {
  public products: any[] = [];

  constructor(private productService: ProductService) {}

  public setProducts(products: any[]): void {
    this.products = products;
  }

  public async getProducts(forceLoad?) {
    if (this.products.length === 0 || forceLoad) {
      this.products = await this.productService.getCategories();
    }

    return this.products;
  }
}
