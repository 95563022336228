import { Injectable } from '@angular/core';
import { DeliveryMethods } from '../constants/delivery_methods';
import { OrderForCalculation } from '../interfaces/order-for-calculation';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root',
})
export class TimestampService {
  constructor(private orderService: OrderService) {}

  getOrderForCalculation(categories, products, deliveryMethod, pickupPointId, timestamp?): OrderForCalculation {
    return {
      timestamp: timestamp ? new Date(timestamp).toISOString() : new Date().toISOString(),
      categories: categories,
      products: products,
      delivery_method: deliveryMethod,
      firm_pickup_point_id: deliveryMethod === DeliveryMethods.Pickup ? pickupPointId : undefined,
    };
  }

  async calculateTimestamp(categories, products, deliveryMethod, pickupPointId, firm, userId): Promise<string> {
    const order = this.getOrderForCalculation(categories, products, deliveryMethod, pickupPointId);
    const response = await this.orderService.getFirstAvailableTimestampForOrder(order, userId);
    return response.timestamp;
  }

  async validateTimestamp(
    timestamp,
    categories,
    products,
    deliveryMethod,
    pickupPointId,
    firm,
    userId
  ): Promise<string> {
    const order = this.getOrderForCalculation(categories, products, deliveryMethod, pickupPointId, timestamp);
    const response = await this.orderService.validateTimestampForOrder(order, userId);
    return response.valid ? response.timestamp : undefined;
  }
}
