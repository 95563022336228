import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Units } from '../constants/units';
import { SlugifyService } from './slugify.service';
import { ErrorModal } from '../errormodal/errormodal.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TagService } from './tag.service';
import { FirmStore } from '../modules/firm/store/firm.store';

@Injectable()
export class BasketItemsService {
  basketItems: any;
  basketAmount: any;
  basketPrice: any;

  constructor(
    public router: Router,
    public slug: SlugifyService,
    private tagService: TagService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private firmStore: FirmStore
  ) {}

  /* Getter */
  async get(firm_id?) {
    if (!firm_id) {
      firm_id = this.firmStore.currentFirm?.id;
    }

    const allBasketItems = JSON.parse(localStorage.getItem('basketItems'));

    let firmBasketItems;

    if (allBasketItems == null || allBasketItems == undefined) {
      firmBasketItems = [];
    } else {
      firmBasketItems = allBasketItems.filter(function (basketObj) {
        return basketObj.firm_id == firm_id;
      });
    }
    return firmBasketItems;
  }

  /* Save basket item to storage */
  async add(item, firm_id, firm_name, firm) {
    if (firm.group_same_products_for_order) {
      item = await this.groupIdenticalProducts(item, firm);
    }

    let basketItems = JSON.parse(localStorage.getItem('basketItems'));

    // get firm id and make favorite object
    const basketObj = {
      firm_id: firm_id,
      firm_name: firm_name,
      item: item,
    };

    if (basketItems == null || basketItems == undefined) {
      basketItems = [];
    }

    basketItems.push(basketObj);
    await localStorage.setItem('basketItems', JSON.stringify(basketItems));

    this.getAmount(firm_id);
    this.calculatePrice(firm_id);

    this.snackBar.open(this.translate.instant('PROD_ADDED_TO_BASKET', { productName: item.name }), '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.tagService.addEvent('eo_web_add_item_to_cart');
  }

  /* Remove item from basket */
  async remove(basketItems, firm_id) {
    await this.removeAllProducts(firm_id);
    const allBasketItems = JSON.parse(localStorage.getItem('basketItems'));

    basketItems.forEach((item) => {
      allBasketItems.push(item);
    });

    await localStorage.setItem('basketItems', JSON.stringify(allBasketItems));
    this.getAmount(firm_id);
    this.calculatePrice(firm_id);
  }

  removeAll(firm_id) {
    this.removeAllProducts(firm_id);
  }

  /* Remove all items from basket */
  async removeAllProducts(firm_id) {
    // ITEMS
    this.basketItems = JSON.parse(localStorage.getItem('basketItems'));

    if (this.basketItems) {
      /* Backward iteration for deleting all items (foreach doesn't work with splice) */
      for (let i = this.basketItems.length - 1; i >= 0; i--) {
        if (this.basketItems[i].firm_id == firm_id) {
          const index = this.getIndex(this.basketItems[i]);
          // remove item
          if (index > -1) {
            this.basketItems.splice(index, 1);
          }
        }
      }

      await localStorage.setItem('basketItems', JSON.stringify(this.basketItems));

      this.getAmount(firm_id);
      this.calculatePrice(firm_id);
    }
  }

  /* Remove all items from basket */
  async removeAllByFirmname(firm_name) {
    let firm_id;

    // ITEMS
    this.basketItems = JSON.parse(localStorage.getItem('basketItems'));

    /* Backward iteration for deleting all items (foreach doesn't work with splice) */
    for (let i = this.basketItems.length - 1; i >= 0; i--) {
      if (this.basketItems[i].firm_name == firm_name) {
        const index = this.getIndex(this.basketItems[i]);
        firm_id = this.basketItems[i].firm_id;

        // remove item
        if (index > -1) {
          this.basketItems.splice(index, 1);
        }
      }
    }

    await localStorage.setItem('basketItems', JSON.stringify(this.basketItems));

    this.getAmount(firm_id);
    this.calculatePrice(firm_id);
  }

  /* Get basket amount */
  async getAmount(firm_id) {
    this.basketItems = await this.get(firm_id);
    this.basketAmount = this.getTotalBasketAmount(firm_id, this.basketItems);
  }

  getTotalBasketAmount(firmId, basketItems) {
    let basketAmount = 0;

    if (basketItems && basketItems.length > 0) {
      basketItems.forEach((basketItem) => {
        if (basketItem.firm_id === firmId) {
          if (basketItem.item.unit_id !== Units.KG && basketItem.item.unit_id !== Units.GRAM) {
            basketAmount += Number(basketItem.item.amount);
          } else basketAmount += 1;
        }
      });
    }

    return basketAmount;
  }

  getProductAmountFromBasket(productId, basketItems) {
    let basketAmount = 0;

    if (basketItems && basketItems.length > 0) {
      const productsInCart = basketItems.filter((item) => item.item.id === productId);
      if (productsInCart && productsInCart.length > 0) {
        productsInCart.forEach((product) => {
          if (product.item.unit_id !== Units.KG && product.item.unit_id !== Units.GRAM) {
            basketAmount += Number(product.item.amount);
          } else basketAmount += 1;
        });
      }
    }

    return basketAmount;
  }

  /* Calculate total price of basket */
  async calculatePrice(firm_id, basketItems?) {
    this.basketItems = basketItems ? basketItems : await this.get(firm_id);

    let total_price = 0;

    this.basketItems.forEach((item) => {
      total_price = total_price + item.item.total_price;
    });

    this.basketPrice = total_price;
    return total_price;
  }

  async calculateVatPrice(firm_id, basketItems?) {
    this.basketItems = basketItems ? basketItems : await this.get(firm_id);
    let sub_total = 0;

    await Promise.all(
      this.basketItems.map(async (item) => {
        const init_item = await JSON.parse(item.item.init_item);
        let price = init_item.price + (init_item.price / 100) * Number(item.item.item.vat_percentage);
        price = Number(price.toFixed(2));

        await item.item.ingredients.forEach((ingr) => {
          if (ingr.price > 0) {
            const VATi = ingr.price + (ingr.price / 100) * item.item.item.vat_percentage;
            price = price + Number(VATi.toFixed(2));
            price = Number(price.toFixed(2));
          }
        });

        await item.item.options_selected.forEach((opt) => {
          const VATi = opt.selected_option.price + (opt.selected_option.price / 100) * item.item.item.vat_percentage;
          price = price + Number(VATi.toFixed(2));
          price = Number(price.toFixed(2));
        });

        if (item.item.unit_id !== Units.KG) {
          price = price * item.item.amount;
        } else if (item.item.unit_id === Units.KG) {
          price = price * (item.item.amount / 1000);
        }

        sub_total = sub_total + price;
      })
    );

    this.basketPrice = sub_total;
    return sub_total;
  }

  /* Get index of basket item */
  getIndex(item: any) {
    return this.basketItems.indexOf(item);
  }

  /* If basket = empty, redirect to basket page */
  async checkBasketItems(firm_id, firm_name, language, own_domain) {
    const basketItems = await this.get(firm_id);

    if (basketItems.length == 0) {
      if (!own_domain) {
        this.router.navigate([this.slug.slugify(firm_name), language, 'basket']);
      } else {
        this.router.navigate([language, 'basket']);
      }
    }
  }

  async groupIdenticalProducts(item, firm) {
    let group = false;
    let basket_item;

    return new Promise(async (resolve) => {
      this.basketItems = JSON.parse(localStorage.getItem('basketItems'));

      if (this.basketItems && this.basketItems.length > 0) {
        await this.basketItems.forEach(async (bi) => {
          if (bi.item.id === item.id && bi.item.comment === item.comment) {
            let ingredients = true;
            if (JSON.stringify(bi.item.ingredients) !== JSON.stringify(item.ingredients)) {
              ingredients = false;
            }

            let options = true;
            if (JSON.stringify(bi.item.options_selected) !== JSON.stringify(item.options_selected)) {
              options = false;
            }

            if (ingredients && options) {
              item.amount = (await Number(item.amount)) + Number(bi.item.amount);

              if (item.has_promotional_price) {
                item.total_price = Number(item.item.promotion.price) * Number(item.amount);
              } else {
                item.total_price = Number(item.item.price) * Number(item.amount);
              }

              group = true;
              basket_item = bi;
            }
          }
        });
      }

      if (group) {
        // remove old item
        const index = await this.getIndex(basket_item);
        if (index > -1) {
          this.basketItems.splice(index, 1);
        }

        await this.remove(this.basketItems, firm.id);
      }

      resolve(item);
    });
  }

  setIngredientTypes(ingredients) {
    ingredients.forEach((ingr) => {
      if (ingr.type == 'paid') {
        ingr.action = 'add';
      } else if (ingr.type == 'main') {
        ingr.action = 'add';
        ingr.selected = 1;
      }
    });

    return ingredients;
  }

  setAmount(amount, unit_name) {
    if (unit_name == 'Kilogram' || unit_name == 'Liter') {
      return Number(amount).toFixed(2);
    } else {
      return Number(amount);
    }
  }

  async checkMaxAmountInCartExceeded(item, firm) {
    const basketItems = await this.get(firm.id);
    let basket_amount = 0;

    if (basketItems && basketItems.length > 0) {
      await basketItems.forEach((basketItem) => {
        if (basketItem.firm_id === firm.id) {
          basket_amount = basket_amount + Number(basketItem.item.amount);
        }
      });
    }

    if (item.item.max && basket_amount + item.amount > item.item.max) {
      this.displayError(
        this.translate.instant('MAX_AMOUNT_EXCEEDED', {
          amount: item.item.max,
          unit: item.item.max > 1 ? item.item.unit.name.toLowerCase() : item.item.unit.name_singular.toLowerCase(),
        })
      );
      return true;
    } else if (firm.max_cart_amount && basket_amount + item.amount > firm.max_cart_amount) {
      this.displayError(
        this.translate.instant('MAX_CART_AMOUNT_EXCEEDED', {
          amount: firm.max_cart_amount,
          unit:
            firm.max_cart_amount > 1 ? item.item.unit.name.toLowerCase() : item.item.unit.name_singular.toLowerCase(),
        })
      );
      return true;
    } else {
      return false;
    }
  }

  displayError(message) {
    const dialogRef: MatDialogRef<ErrorModal> = this.dialog.open(ErrorModal, {
      panelClass: 'myapp-no-padding-dialog',
      width: '350px',
    });

    dialogRef.componentInstance.title = null;
    dialogRef.componentInstance.message = message;

    return dialogRef.afterClosed();
  }

  async updateBasket(firm_id, basketItems) {
    await this.removeAll(firm_id);
    const allBasketItems = JSON.parse(localStorage.getItem('basketItems'));
    basketItems.forEach((item) => {
      allBasketItems.push(item);
    });
    localStorage.setItem('basketItems', JSON.stringify(allBasketItems));
    this.getAmount(firm_id);
  }
}
