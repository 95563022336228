import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../core/models/language';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  availableLanguages = [
    {
      id: '1',
      code: 'nl',
      name: 'Nederlands',
    },
    {
      id: '2',
      code: 'fr',
      name: 'Français',
    },
    {
      id: '3',
      code: 'en',
      name: 'English',
    },
    {
      id: '4',
      code: 'es',
      name: 'Español',
    },
    {
      id: '5',
      code: 'de',
      name: 'Deutsch',
    },
    {
      id: '6',
      code: 'pt',
      name: 'Portuguese',
    },
  ];

  constructor(public router: Router, private translate: TranslateService) {}

  getBrowserLang(languages?) {
    if (!languages) {
      languages = this.availableLanguages;
    }

    // Get browser language
    const userLang = navigator.language.substring(0, 2);

    // Check if language is in list of firm languages
    const lang = languages.filter((lang) => lang.code === userLang);

    // If lang is not supported by firm return first language of the list
    if (lang.length == 0) {
      return languages[0].code;
    }
    // If supported return browser language
    else {
      return userLang;
    }
  }

  /* Check if language in url */
  async checkForLanguage(language, firm, own_domain) {
    const isSupported = this.isLanguageSupported(firm.languages, language);

    if (language && isSupported) {
      return true;
    }

    if (!language || !isSupported) {
      const lang = this.getSupportedLanguage(firm.languages, firm.default_language);

      if (own_domain) {
        if (firm.parent_is_multifirm) {
          await this.router.navigate([firm.website_details.sitename, lang]);
        } else {
          await this.router.navigate([lang]);
        }
      } else {
        await this.router.navigate([firm.website_details.sitename, lang]);
      }
      location.reload();
    }
  }

  setLanguage(language) {
    language = this.getValidatedLanguage(language);
    if (language) {
      this.translate.use(language.code);
    } else {
      this.router.navigate(['404']);
    }
  }

  getValidatedLanguage(language) {
    return this.availableLanguages.find((l) => l.code === language.toLowerCase());
  }

  getSupportedLanguage(supportedLanguages: Language[], defaultLanguage: string): string {
    const browserLanguage = this.getBrowserLang();
    const languageFound = this.isLanguageSupported(supportedLanguages, browserLanguage);

    if (!languageFound) {
      return defaultLanguage;
    }

    return browserLanguage;
  }

  isLanguageSupported(supportedLanguages: Language[], language: string): Language | undefined {
    return supportedLanguages.find((lang) => lang.code === language);
  }
}
