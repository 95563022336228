import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(private translate: TranslateService, private deliveryTimeStore: DeliveryTimeStore) {}

  getDateDay(selectedDate): string {
    if (this.isToday(selectedDate)) {
      return this.translate.instant('TODAY');
    }

    return moment(selectedDate).format('DD/MM');
  }

  formatDateDay(dateDay: string): string {
    if (dateDay === this.translate.instant('TODAY')) {
      return moment().format('DD/MM/YYYY');
    }

    return moment(dateDay).format('DD/MM/YYYY');
  }

  formatDateTime(selectedDate): string {
    return moment(selectedDate).format('HH:mm');
  }

  getSelectedDay(selectedDate, hours) {
    const date = new Date(selectedDate);
    const dayId = date.getDay() !== 0 ? date.getDay() : 7;
    return hours.filter((day) => day.day_id === dayId)[0];
  }

  getOpeningTime(day) {
    if (this.isOnlyOpenedAM(day) || this.isOpenedAMandPM(day)) {
      return day.am.from_time;
    } else if (this.isOnlyOpenedPM(day)) {
      return day.pm.from_time;
    }
  }

  getClosingTime(day) {
    if (this.isOnlyOpenedPM(day) || this.isOpenedAMandPM(day)) {
      return day.pm.to_time;
    } else if (this.isOnlyOpenedAM(day)) {
      return day.am.to_time;
    }
  }

  getTimeArray(min, max) {
    const array = [];

    let i = min;
    do {
      array.push(this.transformTime(String(i)));
      i++;
    } while (i <= max);

    return array;
  }

  isToday(selectedDay) {
    const today = new Date();
    const day = new Date(selectedDay);
    return (
      day.getDate() == today.getDate() && day.getMonth() == today.getMonth() && day.getFullYear() == today.getFullYear()
    );
  }

  isClosed(day): boolean {
    if (!day.am) {
      return day.pm.type === 'closed';
    }

    if (!day.pm) {
      return day.am.type === 'closed';
    }

    return day.am.type === 'closed' && day.pm.type === 'closed';
  }

  isOnlyOpenedAM(day) {
    return day.am?.type === 'open' && (!day.pm || day.pm?.type === 'closed');
  }

  isOnlyOpenedPM(day) {
    return (!day.am || day.am?.type === 'closed') && day.pm?.type === 'open';
  }

  isOpenedAMandPM(day) {
    return day.am?.type === 'open' && day.pm?.type === 'open';
  }

  splitHours(time) {
    return parseInt(time.split(':')[0]);
  }

  splitMinutes(time) {
    return parseInt(time.split(':')[1]);
  }

  transformTime(time) {
    if (time.length < 2) {
      time = `0${time}`;
    }
    return time;
  }

  setTimeOnDate(date: Date, time: string): Date {
    const timeArr = time.split(':').map((value) => Number(value));

    date.setHours(timeArr[0]);
    date.setMinutes(timeArr[1]);

    return date;
  }

  getDateTillSnoozeEnd(snoozeEnd): string {
    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime;

    if (deliveryTime === 'asap') {
      return moment().to(snoozeEnd);
    }

    return moment(deliveryTime).to(snoozeEnd);
  }
}
