import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {config} from '../../config';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {ErrorService} from './error.service';
import {StoreService} from './store.service';
import * as moment from 'moment';
import { FirmStore } from '../modules/firm/store/firm.store';
import { DialogsService } from './dialogs.service';
import { MenuStore } from '../modules/menu/store/menu.store';
import { Availability } from '../core/models/availability';
import { DateService } from './date.service';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';

@Injectable()
export class ProductService {

  private endpoint = '/sync/products';
  private endpoint_cat = '/category/';
  private endpoint_prod = '/product/';

  products: any;
  token: any;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    public errorService: ErrorService,
    public storeService: StoreService,
    private firmStore: FirmStore,
    private dialogsService: DialogsService,
    private menuStore: MenuStore,
    private deliveryTimeStore: DeliveryTimeStore,
    private dateService: DateService
  ) {}

  async getCategories(): Promise<any> {
    const firm = this.firmStore.currentFirm;
    const language = this.firmStore.language;

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
            {
              'storeId': await this.storeService.getStoreId(),
              'passwordToken': await this.checkToken(firm?.id),
              'demoPasswordToken': await this.getDemoToken(),
              'firmId': String(firm?.id),
              'language': String(language),
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + await localStorage.getItem('token'),
              'appVersion': config.VERSION,
              'apiVersion': config.VERSION,
              'deviceOS': 'Web',
              'deviceOSVersion': 'Web',
              'deviceModel': 'Web'
            }
          )
        };

        this.http.get(environment.API_URL + this.endpoint, httpOptions)
          .subscribe(
            (data: any) => {
              if(firm.uses_deliverect) {
                this.setDeliverectCategories(data.data);
              }

              data.data.forEach(p => {
                this.setProductAvailableData(p);
              });
              resolve(data.data);
            },
            async err => {
              let check = await this.errorService.checkProductsErrors(err.status, await this.parseError(err));

              if (check == 'TOKEN_INVALID') {
                this.getCategories();
              }
            });
      });
    }
  }

  async getCategoryById(firm_id, lang_code, cat_id, uses_deliverect) {
    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
            {
              'storeId': await this.storeService.getStoreId(),
              'passwordToken': await this.checkToken(firm_id),
              'demoPasswordToken': await this.getDemoToken(),
              'firmId': String(firm_id),
              'language': String(lang_code),
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + await localStorage.getItem('token'),
              'appVersion': config.VERSION,
              'apiVersion': config.VERSION,
              'deviceOS': 'Web',
              'deviceOSVersion': 'Web',
              'deviceModel': 'Web'
            }
          )
        };

        this.http.get(environment.API_URL + this.endpoint_cat + cat_id, httpOptions)
          .subscribe(
            (data: any) => {
              resolve(this.setProductAvailableData(data.data[0]));
            },
            async err => {
              let check = await this.errorService.checkProductsErrors(err.status, await this.parseError(err));

              if (check == 'TOKEN_INVALID') {
                this.getCategoryById(firm_id, lang_code, cat_id, uses_deliverect);
              }
            });
      });
    }
  }

  async getProductsByCategoryId(firm_id, lang_code, cat_id, uses_deliverect) {
    let response: any = await this.getCategoryById(firm_id, lang_code, cat_id, uses_deliverect);

    if(response.products && response.products.length > 0) {
      return response.products;
    }
    else if(response.subcategories && response.subcategories.length > 0) {
      var array = [];
      await response.subcategories.forEach(subcat => {
        subcat.products.forEach(prod => {
          prod.name = `${prod.name} (${subcat.name})`;
          array.push(prod);
        });
      });
      return array;
    }
  }

  async getItemById(firm_id, lang_code, prod_id) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
            {
              'storeId': await this.storeService.getStoreId(),
              'passwordToken': await this.checkToken(firm_id),
              'demoPasswordToken': await this.getDemoToken(),
              'firmId': String(firm_id),
              'language': String(lang_code),
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + await localStorage.getItem('token'),
              'appVersion': config.VERSION,
              'apiVersion': config.VERSION,
              'deviceOS': 'Web',
              'deviceOSVersion': 'Web',
              'deviceModel': 'Web'
            }
          )
        };

        this.http.get(environment.API_URL + this.endpoint_prod + prod_id, httpOptions)
          .subscribe(
            async (data: any) => {
              data.data.category = await this.setProductAvailableData(data.data.category);
              resolve(data.data);
            },
            async err => {
              let check = await this.errorService.checkProductsErrors(err.status, await this.parseError(err));

              if (check == 'TOKEN_INVALID') {
                this.getItemById(firm_id, lang_code, prod_id);
              }
            });

      });
    }
  }

  setDeliverectCategories(categories): any[] {
    let index = 0;
    const deliverectCategories = [];

    categories.forEach((cat) => {
      deliverectCategories.push({
        name: cat.name,
        index: index,
      });
      index += 1;
    });

    localStorage.setItem('deliverectCategories', JSON.stringify(deliverectCategories));

    return deliverectCategories;
  }

  async getDeliverectCategories(): Promise<any> {
    const categories = JSON.parse(localStorage.getItem('deliverectCategories'));

    if (!categories) {
      const categories = await this.getCategories();
      return this.setDeliverectCategories(categories);
    }

    return categories;
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    }
    catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async checkToken(firm_id) {
    let tokens = await JSON.parse(localStorage.getItem('password_tokens'));

    var passwordToken = [];

    if (tokens != null) {
      tokens.forEach(token => {
        if (token.firm_id == firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    return passwordToken;
  }

  async getDemoToken() {
    var demoToken = await JSON.parse(localStorage.getItem('demo_password_token'));

    if (demoToken == null) {
      demoToken = '';
    }

    return demoToken;
  }

  removeProduct(original_products, original_product) {
    let index = original_products.indexOf(original_product);
    if (index !== -1) {
      original_products.splice(index, 1);
    }
  }

  getProducts(categories: any) {
    categories.forEach(cat => {
      if (cat.subcategories.length > 0) {
        this.getProducts(cat.subcategories);
      } else {
        cat.products.forEach(prod => {
          this.products.push(prod);
        });
      }
    });
  }

  /* Get main image */
  getImage(images) {
    var thumb;

    images.forEach(img => {
      if (img.main == true) {
        thumb = img.thumb;
      }
    });

    return thumb;
  }

  /* Get main image */
  getDetailImage(images) {
    var detail;

    images.forEach(img => {
      if (img.main == true) {
        detail = img.detail;
      }
    });

    return detail;
  }

  /* Get VAT percentage of a product */
  async getVatPercentage(product, firm) {
    var VAT_percentage;

    if (firm.vat_settings.allowed_vat_percentages.length == 1) {
      VAT_percentage = firm.vat_settings.allowed_vat_percentages[0];
    }
    else {
      let vat_delivery_method = await localStorage.getItem('vat_delivery_method');

      let delivery_methods = firm.vat_settings.delivery_method.filter((method) => method.method == vat_delivery_method);

      if(product) {
        product.vat_percentages.forEach(item_percentage => {
          delivery_methods.forEach(method => {
            if (method.vat_percentage == item_percentage) {
              VAT_percentage = item_percentage;
            }
          });
        });
      }
    }

    return VAT_percentage;
  }

  isAvailable(availabilities: Availability[]): boolean {
    if (!availabilities || availabilities.length === 0) {
      return true;
    }

    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime;

    let date;

    if (deliveryTime === 'asap') {
      date = moment();
    } else {
      date = moment(deliveryTime);
    }

    const availableDays = availabilities.filter((day) => day.day_id === date.isoWeekday());

    if (!availableDays || availableDays.length === 0) {
      return false;
    }

    let available = false;

    availableDays.forEach((day) => {
      const fromDate = this.dateService.setTimeOnDate(new Date(date), day.from_time);
      const toDate = this.dateService.setTimeOnDate(new Date(date), day.to_time);
      if (date.isSame(fromDate) || date.isBetween(fromDate, toDate)) {
        available = true;
      }
    });

    return available;
  }

  /**
   * Returns the next available date
   * Don't use this function if available now (ProductService#isAvailable).
   * @param availabilities
   */
  async getFirstAvailableDate(availabilities) {
    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime;
    let date;

    if (deliveryTime === 'asap') {
      date = moment();
    } else {
      date = moment(deliveryTime);
    }

    let dayId = date.isoWeekday();
    let counter = 1;

    if (availabilities?.length > 0) {
      while (
        availabilities.find(
          (av) =>
            av.day_id === dayId &&
            (av.day_id !== date.isoWeekday() ||
              (av.day_id === date.isoWeekday() &&
                date.isBefore(this.dateService.setTimeOnDate(new Date(date), av.from_time))))
        ) === undefined &&
        counter <= 7
      ) {
        dayId = dayId === 7 ? (dayId = 1) : (dayId += 1);
        counter += 1;
      }

      const availableDays = availabilities.filter((av) => av.day_id === dayId);

      if (dayId === date.isoWeekday()) {
        return availableDays.find((day) =>
          date.isBefore(this.dateService.setTimeOnDate(new Date(date), day.from_time))
        );
      }

      return availableDays[0];
    }
  }

  isSnoozed(item): boolean {
    if (!item.snooze_start || !item.snooze_end) {
      return false;
    }

    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime;

    if (deliveryTime === 'asap') {
      return moment().isBetween(item.snooze_start, item.snooze_end);
    }

    return moment(deliveryTime).isBetween(item.snooze_start, item.snooze_end);
  }

  isDateWithinCurrentYear(date) {
    return moment(date).isBefore(moment().add(1, 'y'));
  }

  async setProductAvailableData(category) {
    category.isAvailable = this.isAvailable(category.availabilities);

    if(!category.isAvailable) {
      category.firstAvailableDate = await this.getFirstAvailableDate(category.availabilities);
    }

    if('category' in category) {
      category.category = await this.setProductAvailableData(category.category);
    }

    return category;
  }

  async openOrderProductModal(productId) {
    if (this.menuStore.viewMenuActive.getValue() === true) {
      this.dialogsService.openReadyToOrderModal();
      return;
    }

    const firm = this.firmStore.currentFirm;
    const language = this.firmStore.language;
    const product: any = await this.getItemById(firm.id, language, productId);

    this.dialogsService.openModal(product, firm, product.category.category_id, language);
  }
}
