import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Address } from '../../../core/models/address';

@Injectable({
  providedIn: 'root',
})
export class CustomerStore {
  public customer: any;
  public addresses: Address[];
  public selectedAddress: Address;

  public deliveryAddress$ = new BehaviorSubject<Address>(null);

  constructor() {}

  public setCustomer(customer: any): void {
    this.customer = customer;
  }

  public setAddresses(addresses: Address[]): void {
    this.addresses = addresses;
  }

  public getSelectedAddress() {
    const selectedAddress = localStorage.getItem('selectedAddress');

    if (selectedAddress) {
      return JSON.parse(selectedAddress);
    }

    return null;
  }

  public setSelectedAddress(address: Address): void {
    this.selectedAddress = address;
    this.setDeliveryAddress();
    localStorage.setItem('selectedAddress', JSON.stringify(address));
  }

  public updateAddress(address: Address): void {
    if (address.is_default) {
      this.updateIsDefaultToFalseForAllAddresses();
      address.is_default = true;
    }

    const index = this.addresses.findIndex((a: Address) => a.id === address.id);
    this.addresses.splice(index, 1, address);
  }

  public addAddress(address: Address): void {
    if (address.is_default) {
      this.updateIsDefaultToFalseForAllAddresses();
    }

    this.addresses.push(address);
  }

  public setDeliveryAddress(): void {
    this.deliveryAddress$.next(this.selectedAddress);
  }

  private updateIsDefaultToFalseForAllAddresses(): void {
    this.addresses = this.addresses.map((address: Address) => ({
      ...address,
      is_default: false,
    }));
  }
}
