import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { OrderService } from '../services/order.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import { TagService } from '../services/tag.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css'],
})
export class PaymentMethodComponent implements OnInit {
  own_domain: boolean;
  order: any;
  firm: any;
  firm_name: any;
  language: any;
  sub_total: any;
  delivery_cost: any;
  total_price: any;
  payment_methods: any;

  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public router: Router,
    public routeService: RouteService,
    public slug: SlugifyService,
    public orderService: OrderService,
    public firmService: FirmService,
    private languageService: LanguageService,
    private tagService: TagService
  ) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
    this.languageService.setLanguage(this.language);
  }

  /* Get order */
  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then(async (data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;

      // Get order
      this.order = await this.orderService.getOrder();

      this.checkForErrors();
    });
  }

  checkForErrors() {
    if (!this.firm.has_website) {
      this.router.navigate([this.firm.website_details.sitename, this.language, '404']);
    } else if (!this.order) {
      this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
    } else if (!this.order.process_timestamp && !this.order.user) {
      this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
    } else {
      this.initData();
    }
  }

  /* Get all data */
  async initData() {
    this.tagService.addEvent('eo_web_checkout');
    this.getAvailablePaymentMethods();
  }

  getAvailablePaymentMethods() {
    // Get payment methods
    if (!this.order.firm_pickup_point_id) {
      this.payment_methods = [];

      this.firm.payment_methods.forEach((method) => {
        if (this.order.delivery_method == 'pickup' && method.method == 'pickup') {
          this.payment_methods.push(method);
        } else if (this.order.delivery_method == 'table' && method.method == 'table') {
          this.payment_methods.push(method);
        } else if (this.order.delivery_method == 'delivery' && method.method == 'delivery') {
          this.payment_methods.push(method);
        }
      });
    } else {
      this.firm.pickup_points.forEach((pickup_point) => {
        if (pickup_point.id == this.order.firm_pickup_point_id) {
          this.payment_methods = pickup_point.payment_methods;
        }
      });
    }
  }

  async choosePaymentMethod(payment_method, custom) {
    if (!custom) {
      this.order.firm_custom_delivery_pickup_payment_method_id = undefined;

      this.payment_methods.forEach((method) => {
        if (method.payment_method_id === payment_method) {
          this.order.payment_method_id = method.payment_method_id;
          this.order.payment_method = method.payment_method;
        }
      });
    } else {
      this.order.payment_method_id = 1;
      this.order.payment_method = 'cash';
      this.order.firm_custom_delivery_pickup_payment_method_id = payment_method.id;
      this.order.custom_payment_method = payment_method;
    }
  }

  /* Update order and go to overview */
  goToOverview(): void {
    this.orderService.saveOrder(this.order);
    this.routeService.navigateToOverview(this.own_domain, this.firm, this.language);
  }
}
