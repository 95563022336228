<eo-modal
  cancelPosition="above-right"
  footerPosition="underneath"
  [buttonLabel]="'CONTINUE' | translate"
  [displayButton]="true"
  [disableButton]="isButtonDisabled()"
  (submitted)="save()">

  <method-delivery-switcher *ngIf="showSwitcher"></method-delivery-switcher>

  <div class="eo-modal-header flex align-items-center">
    <ion-icon src="../../assets/img/qrcode.svg"></ion-icon>
    <div class="ml-10">
      <p>{{ 'ORDER_ON_THE_SPOT' | translate }}</p>
      <p class="font-13">{{ 'SELECT_AN_OPTION' | translate }}</p>
    </div>
  </div>

  <div class="tableMethods" *ngIf="methods && methods?.length > 0">
    <div class="eo-select">
      <mat-select [(ngModel)]="selectedMethodId">
        <mat-option
          *ngFor="let method of methods"
          (click)="selectMethod(method)"
          [value]="method?.id">
          {{ method?.name }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="tableNumbers" *ngIf="selectedMethod?.location_numbers?.length > 0">
    <p class="title">{{ 'CHOOSE_TABLE_NUMBER' | translate:{table_number: selectedMethod?.location_number_name?.toLowerCase()} }}</p>

    <div scrollx="true" class="scroll-x">
      <mat-radio-group [(ngModel)]="selectedTableNumber">
        <ng-container *ngFor="let number of selectedMethod?.location_numbers">
          <div
            class="radio-container eo-radio-button flex align-items-center justify-space-between clickable"
            (click)="selectTableNumber(number)">
            <div class="title-container">
              <p>{{ number }}</p>
            </div>

            <div class="radio-button-container flex ion-justify-content-end ion-align-items-center">
              <mat-radio-button [value]="number"></mat-radio-button>
            </div>
          </div>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>

</eo-modal>
