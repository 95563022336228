import { Component, Input, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CustomerService } from '../services/customer.service';
import { FirmService } from '../services/firm.service';

export const MY_FORMATS = {
  display: {
    dateInput: 'DD MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-reservation-tool',
  templateUrl: './reservation-tool.component.html',
  styleUrls: ['./reservation-tool.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'nl'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReservationToolComponent implements OnInit {

  @Input() firm: any;
  @Input() own_domain: any;

  language: any;
  amounts: any = [];
  chosen_amount: string;
  date: any;
  times: any;
  chosen_time: string;
  enableTime: boolean = false;
  minDate: any;
  maxDate: any;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  remarks: string;
  enableSuccess: boolean = false;
  enableError: boolean = false;
  disableBtn: boolean = false;
  error_msg: any;

  constructor(public route: ActivatedRoute, public firmService: FirmService, public customerService: CustomerService) {
    this.language = route.snapshot.params.language;
  }

  async ngOnInit() {
    for (var i = this.firm.reservations.min_persons; i <= this.firm.reservations.max_persons; i++) {
      this.amounts.push(i);
    }

    if(this.amounts.length > 0) {
      this.chosen_amount = this.amounts[0];
    }

    await this.calculateMinAndMaxDates();

    this.getTimeSlots();

    let login = await JSON.parse(localStorage.getItem('login'));
    if(login) {
      if(login.activated) {
        this.getUser(login.id, this.own_domain);
      }
    }
  }

  calculateMinAndMaxDates() {
    let advance_time = this.firm.reservations.booking_in_advance_hours;
    let add_days = Math.floor(advance_time/24);
    this.minDate = moment().add(add_days, 'day').format();
    this.maxDate = moment(this.minDate).add(1, 'year').format();
    this.date = this.minDate;
  }

  async getUser(id, own_domain) {
    await this.customerService.linkFirm(this.firm, id, this.language, this.firm.distributor, own_domain);
    const response: any = await this.customerService.get(this.firm, id, this.language, this.firm.distributor);

    this.first_name = response.first_name;
    this.last_name = response.last_name;
    this.email = response.email;
    this.phone = response.phone;
    this.checkDisable();
  }

  async getTimeSlots() {
    if(this.date != undefined && this.chosen_amount != undefined) {
      this.times = await this.firmService.getTimeSlots({ date: moment(this.date).format('DD/MM/YYYY'), guests: this.chosen_amount }, this.firm.id, this.language);
      this.chosen_time = this.times[0];
      this.enableTime = true;
    }
    else {
      this.enableTime = false;
      this.chosen_time = undefined;
    }
    this.checkDisable();
  }

  checkDisable() {
    if( this.chosen_amount != undefined &&
      this.chosen_time != undefined &&
      this.date != undefined &&
      this.first_name != undefined && this.first_name != "" &&
      this.last_name != undefined && this.last_name != "" &&
      this.email != undefined && this.email != "" &&
      this.phone != undefined && this.phone != "") {
      this.disableBtn = true;
    }
    else {
      this.disableBtn = false;
    }
  }

  async confirmReservation() {
    let reservation: any = {
      date: moment(this.date).format('DD/MM/YYYY'),
      guests: this.chosen_amount,
      time: this.chosen_time,
      name: this.first_name + " " + this.last_name,
      email: this.email,
      phone: this.phone,
      remarks: this.remarks
    }

    let response = await this.firmService.confirmReservation(reservation, this.firm.id, this.language);

    if(response) {
      this.error_msg = response;
      this.enableSuccess = false;
      this.enableError = true;
    }
    else {
      this.error_msg = undefined;
      this.enableError = false;
      this.enableSuccess = true;
    }
  }

}
