import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FirmsComponent } from '../firms/firms.component';
import { MenuComponent } from '../menu/menu.component';
import { CategoryComponent } from '../category/category.component';
import { ItemComponent } from '../item/item.component';
import { BasketComponent } from '../basket/basket.component';
import { NotfoundComponent } from '../notfound/notfound.component';
import { InfoComponent } from '../info/info.component';
import { SearchComponent } from '../search/search.component';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';
import { OverviewComponent } from '../overview/overview.component';
import { OrderProcessedComponent } from '../order-processed/order-processed.component';
import { RouteService } from '../services/route.service';
import { FavoritesComponent } from '../favorites/favorites.component';
import { ProfileComponent } from '../profile/profile.component';
import { SettingsComponent } from '../settings/settings.component';
import { RecentOrdersComponent } from '../recent-orders/recent-orders.component';
import { OrderComponent } from '../order/order.component';
import { PasswordComponent } from '../password/password.component';
import { DemoPasswordComponent } from '../demo-password/demo-password.component';
import { LoyaltyComponent } from '../loyalty/loyalty.component';
import { ReservationComponent } from '../reservation/reservation.component';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { SignupComponent } from '../signup/signup.component';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { ExtraInfoComponent } from '../extra-info/extra-info.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from '../cookie-policy/cookie-policy.component';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: FirmsComponent, canActivate: [RouteService] },

  /* 404 */
  { path: ':firm_name/404', pathMatch: 'full', component: NotfoundComponent },
  { path: ':firm_name/:language/404', pathMatch: 'full', component: NotfoundComponent },
  { path: '404', pathMatch: 'full', component: NotfoundComponent },

  /* Firms (container) */
  { path: 'firms', component: FirmsComponent },

  /* Demo password */
  { path: 'demo-password/:firm_name', component: DemoPasswordComponent },

  /* Password */
  { path: 'password/:firm_name', component: PasswordComponent },

  /* Normal categories (legacy) */
  { path: ':language/categorie/:cat_name/:cat_id', pathMatch: 'full', component: CategoryComponent },
  { path: ':firm_name/:language/categorie/:cat_name/:cat_id', pathMatch: 'full', component: CategoryComponent },
  {
    path: ':language/categorie/:cat_name/:cat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },
  {
    path: ':firm_name/:language/categorie/:cat_name/:cat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },

  /* Normal categories */
  { path: ':language/category/:cat_name/:cat_id', pathMatch: 'full', component: CategoryComponent },
  { path: ':firm_name/:language/category/:cat_name/:cat_id', pathMatch: 'full', component: CategoryComponent },
  {
    path: ':language/category/:cat_name/:cat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },
  {
    path: ':firm_name/:language/category/:cat_name/:cat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },

  /* With subcategories (legacy) */
  {
    path: ':language/categorie/:cat_name/:cat_id/subcategorie/:subcat_name/:subcat_id',
    pathMatch: 'full',
    component: CategoryComponent,
  },
  {
    path: ':firm_name/:language/categorie/:cat_name/:cat_id/subcategorie/:subcat_name/:subcat_id',
    pathMatch: 'full',
    component: CategoryComponent,
  },
  {
    path: ':language/categorie/:cat_name/:cat_id/subcategorie/:subcat_name/:subcat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },
  {
    path: ':firm_name/:language/categorie/:cat_name/:cat_id/subcategorie/:subcat_name/:subcat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },

  /* With subcategories */
  {
    path: ':language/category/:cat_name/:cat_id/subcategory/:subcat_name/:subcat_id',
    pathMatch: 'full',
    component: CategoryComponent,
  },
  {
    path: ':firm_name/:language/category/:cat_name/:cat_id/subcategory/:subcat_name/:subcat_id',
    pathMatch: 'full',
    component: CategoryComponent,
  },
  {
    path: ':language/category/:cat_name/:cat_id/subcategory/:subcat_name/:subcat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },
  {
    path: ':firm_name/:language/category/:cat_name/:cat_id/subcategory/:subcat_name/:subcat_id/product/:prod_name/:prod_id',
    pathMatch: 'full',
    component: ItemComponent,
  },

  /* Checkout */
  { path: ':language/checkout/extra-info/:method', pathMatch: 'full', component: ExtraInfoComponent },
  { path: ':firm_name/:language/checkout/extra-info/:method', pathMatch: 'full', component: ExtraInfoComponent },
  { path: ':language/checkout/paymentmethod', pathMatch: 'full', component: PaymentMethodComponent },
  { path: ':firm_name/:language/checkout/paymentmethod', pathMatch: 'full', component: PaymentMethodComponent },
  { path: ':language/checkout/overview', pathMatch: 'full', component: OverviewComponent },
  { path: ':firm_name/:language/checkout/overview', pathMatch: 'full', component: OverviewComponent },
  { path: ':language/checkout/order/:order_id/processed', pathMatch: 'full', component: OrderProcessedComponent },
  {
    path: ':firm_name/:language/checkout/order/:order_id/processed',
    pathMatch: 'full',
    component: OrderProcessedComponent,
  },

  /* Search */
  { path: ':language/search/:search_term', pathMatch: 'full', component: SearchComponent },
  { path: ':firm_name/:language/search/:search_term', pathMatch: 'full', component: SearchComponent },

  /* Recent orders */
  { path: ':language/recent-orders', pathMatch: 'full', component: RecentOrdersComponent },
  { path: ':firm_name/:language/recent-orders', pathMatch: 'full', component: RecentOrdersComponent },
  { path: ':language/order/:order_id', pathMatch: 'full', component: OrderComponent },
  { path: ':firm_name/:language/order/:order_id', pathMatch: 'full', component: OrderComponent },

  /* Other pages */
  { path: ':language/favorites', pathMatch: 'full', component: FavoritesComponent },
  { path: ':firm_name/:language/favorites', pathMatch: 'full', component: FavoritesComponent },
  { path: ':language/info', pathMatch: 'full', component: InfoComponent },
  { path: ':firm_name/:language/info', pathMatch: 'full', component: InfoComponent },
  { path: ':language/basket', pathMatch: 'full', component: BasketComponent },
  { path: ':firm_name/:language/basket', pathMatch: 'full', component: BasketComponent },
  { path: ':language/profile', pathMatch: 'full', component: ProfileComponent },
  { path: ':firm_name/:language/profile', pathMatch: 'full', component: ProfileComponent },
  { path: ':language/signup', pathMatch: 'full', component: SignupComponent },
  { path: ':firm_name/:language/signup', pathMatch: 'full', component: SignupComponent },
  { path: ':language/password-reset', pathMatch: 'full', component: PasswordResetComponent },
  { path: ':firm_name/:language/password-reset', pathMatch: 'full', component: PasswordResetComponent },
  { path: ':language/settings', pathMatch: 'full', component: SettingsComponent },
  { path: ':firm_name/:language/settings', pathMatch: 'full', component: SettingsComponent },
  { path: ':language/loyalty', pathMatch: 'full', component: LoyaltyComponent },
  { path: ':firm_name/:language/loyalty', pathMatch: 'full', component: LoyaltyComponent },
  { path: ':language/reservation', pathMatch: 'full', component: ReservationComponent },
  { path: ':firm_name/:language/reservation', pathMatch: 'full', component: ReservationComponent },

  /* Policies */
  { path: ':language/privacy-policy', pathMatch: 'full', component: PrivacyPolicyComponent },
  { path: ':firm_name/:language/privacy-policy', pathMatch: 'full', component: PrivacyPolicyComponent },
  { path: ':language/terms-of-use', pathMatch: 'full', component: TermsOfUseComponent },
  { path: ':firm_name/:language/terms-of-use', pathMatch: 'full', component: TermsOfUseComponent },
  { path: ':language/cookie-policy', pathMatch: 'full', component: CookiePolicyComponent },
  { path: ':firm_name/:language/cookie-policy', pathMatch: 'full', component: CookiePolicyComponent },

  /* QR code page */
  { path: 'order_detail/:reference', pathMatch: 'full', component: OrderDetailComponent },

  /* Firm */
  { path: 'firm', pathMatch: 'full', component: MenuComponent },
  { path: ':parameter', pathMatch: 'full', component: MenuComponent },
  { path: ':firm_name/:language', pathMatch: 'full', component: MenuComponent },

  /* 404 */
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RouteService],
})
export class AppRoutingModule {}
