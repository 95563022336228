import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '../../../../services/date.service';
import { DeliveryTimeServiceResolver } from '../../services/delivery-time-service.resolver';
import { DeliveryTimeServiceInterface } from '../../services/delivery-time-service.interface';
import * as moment from 'moment';
import { DeliveryTimeStore } from '../../store/delivery-time.store';

@Component({
  selector: 'timeslots-picker',
  templateUrl: './timeslots-picker.component.html',
  styleUrls: ['./timeslots-picker.component.scss'],
})
export class TimeslotsPickerComponent implements OnInit {
  @ViewChild('dateDayPicker') dateDayPicker;

  @Input() showHeader = true;
  @Input() firm: any;

  public dateDay = this.translate.instant('LOADING');
  public selectedTimeslot = this.translate.instant('SHOW_LIST');
  public selectedInterval = this.translate.instant('SHOW_LIST');
  public dateDayModel = '';
  public minDate = '';
  public maxDate = moment().add(1, 'month').format();
  public timeslots = [];
  public intervals = [];

  public showIntervals = false;
  public loadingTimeslot = true;
  public loadingInterval = false;
  public noTimeslotAvailable = false;

  private deliveryTimeService: DeliveryTimeServiceInterface;
  private hasGottenTimeslotsForTheFirstAvailableDate = false;

  constructor(
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private dateService: DateService,
    private translate: TranslateService,
    private deliveryTimeStore: DeliveryTimeStore
  ) {}

  public ngOnInit(): void {
    this.deliveryTimeServiceResolver
      .resolveDeliveryTimeService(this.firm)
      .then(async (service: DeliveryTimeServiceInterface) => {
        this.deliveryTimeService = service;
        const firstAvailableTimeResponse = await service.calculateTimestamp();

        this.minDate = moment(firstAvailableTimeResponse.timestamp).format();
        this.dateDayModel = firstAvailableTimeResponse.timestamp;
        await this.selectDate(firstAvailableTimeResponse.timestamp);

        if (this.timeslots.length > 0) {
          await this.selectTimeslot(this.timeslots[0].id);

          if (this.intervals.length > 0) {
            await this.selectInterval(this.intervals[0].time);
          }
        }

        this.hasGottenTimeslotsForTheFirstAvailableDate = true;
      });
  }

  public async selectDate(time: string): Promise<void> {
    this.loadingTimeslot = true;
    this.noTimeslotAvailable = false;
    this.timeslots = await this.deliveryTimeService?.getFirmTimeslots(time);
    this.dateDay = this.dateService.getDateDay(time);

    if (this.timeslots?.length === 0) {
      this.noTimeslotAvailable = true;

      return;
    }

    this.loadingTimeslot = false;
  }

  public openDateDayPicker(): void {
    this.dateDayPicker.open();
  }

  public async selectTimeslot(timeslotId: number): Promise<void> {
    if (this.selectedTimeslot === timeslotId) {
      return;
    }

    this.selectedTimeslot = timeslotId;
    this.loadingInterval = true;
    this.selectedInterval = this.translate.instant('SHOW_LIST');

    const intervals = await this.deliveryTimeService?.getTimeIntervals(timeslotId, this.dateDayModel);

    this.intervals = this.filterValidIntervals(intervals);

    if (this.intervals.length === 0) {
      this.noTimeslotAvailable = true;

      return;
    }

    this.showIntervals = true;
    this.loadingInterval = false;
  }

  public selectInterval(interval: string): void {
    this.selectedInterval = interval;
    let day = this.dateDay;

    if (day === this.translate.instant('TODAY')) {
      day = moment().format('DD/MM');
    }

    this.deliveryTimeService?.updateDeliveryTime(moment(`${day} ${interval}`, 'DD/MM HH:mm').format());
  }

  public async handleOnDateDayChange(): Promise<void> {
    if (!this.hasGottenTimeslotsForTheFirstAvailableDate) {
      return;
    }

    this.selectedTimeslot = this.translate.instant('SHOW_LIST');
    this.selectedInterval = this.translate.instant('SHOW_LIST');
    this.showIntervals = false;
    await this.selectDate(this.dateDayModel);
  }

  private filterValidIntervals(intervals: any[]): any[] {
    return intervals.filter((interval: any) => !interval.blocked && interval.available);
  }
}
