export const config = {
  VERSION: '7.0.0',
  STORE_IDS: [
    { domain: 'localhost', store_id: 'a5b1d728-bf41-42c8-a212-3d1a2a28cd9a' },
    { domain: 'storev4-staging.easyorderapp.com', store_id: 'a5b1d728-bf41-42c8-a212-3d1a2a28cd9a' },
    { domain: 'storev4.easyorderapp.com', store_id: 'a5b1d728-bf41-42c8-a212-3d1a2a28cd9a' },
    { domain: 'shop-staging.easyorderapp.com', store_id: 'a5b1d728-bf41-42c8-a212-3d1a2a28cd9a' },
    { domain: 'shop.easyorderapp.com', store_id: 'a5b1d728-bf41-42c8-a212-3d1a2a28cd9a' },
  ],
};
