import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppComponent } from './app.component';
import { BasketComponent } from './basket/basket.component';
import { CategoryItemComponent } from './category-item/category-item.component';
import { CategoryComponent } from './category/category.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { DeliveryHoursComponent } from './delivery-hours/delivery-hours.component';
import { DeliveryInfoComponent } from './delivery-info/delivery-info.component';
import { DemoPasswordComponent } from './demo-password/demo-password.component';
import { EditOrderModalComponent } from './edit-ordermodal/edit-ordermodal.component';
import { EmbeddedHeaderComponent } from './embedded-header/embedded-header.component';
import { ErrorModal } from './errormodal/errormodal.component';
import { ExtraInfoComponent } from './extra-info/extra-info.component';
import { ExtraPointsComponent } from './extra-points/extra-points.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { FeaturedProductsComponent } from './featured-products/featured-products.component';
import { FirmInfoComponent, SafePipe } from './firm-info/firm-info.component';
import { FirmsComponent } from './firms/firms.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HoursToggleComponent } from './hours-toggle/hours-toggle.component';
import { InfoComponent } from './info/info.component';
import { ItemComponent } from './item/item.component';
import { ListItemComponent } from './list-item/list-item.component';
import { LoyaltyComponent } from './loyalty/loyalty.component';
import { MenuComponent } from './menu/menu.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderHeaderComponent } from './order-header/order-header.component';
import { OrderItemComponent } from './order-item/order-item.component';
import { OrderNowBarComponent } from './order-now-bar/order-now-bar.component';
import { OrderProcessedComponent } from './order-processed/order-processed.component';
import { OrderSideBarComponent } from './order-side-bar/order-side-bar.component';
import { OrderComponent } from './order/order.component';
import { OrderModalComponent } from './ordermodal/ordermodal.component';
import { MinimumPricePipe, OverviewComponent } from './overview/overview.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordComponent } from './password/password.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PaymentModal } from './paymentmodal/paymentmodal.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { RecentOrdersComponent } from './recent-orders/recent-orders.component';
import { ReservationToolComponent } from './reservation-tool/reservation-tool.component';
import { ReservationComponent } from './reservation/reservation.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { SearchComponent } from './search/search.component';
import { AuthService } from './services/auth.service';
import { BasketItemsService } from './services/basket-items.service';
import { ThemingService } from './services/theming.service';
import { CustomerService } from './services/customer.service';
import { DialogsService } from './services/dialogs.service';
import { ErrorService } from './services/error.service';
import { FavoritesService } from './services/favorites.service';
import { FirmService } from './services/firm.service';
import { LanguageService } from './services/language.service';
import { LoaderService } from './services/loader.service';
import { OrderService } from './services/order.service';
import { PaymentService } from './services/payment.service';
import { ProductService } from './services/products.service';
import { SearchService } from './services/search.service';
import { SlugifyService } from './services/slugify.service';
import { StoreService } from './services/store.service';
import { SettingsComponent } from './settings/settings.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { VatDeliveryMethodOpenerComponent } from './vat-delivery-method-opener/vat-delivery-method-opener.component';
import { VatDeliveryMethodComponent } from './vat-delivery-method/vat-delivery-method.component';
import { EmailLabelComponent } from './email-label/email-label.component';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { QuantitySelectorComponent } from './quantity-selector/quantity-selector.component';
import { UnitSelectorComponent } from './quantity-selector/unit-selector.component';
import { WeightSelectorComponent } from './quantity-selector/weight-selector.component';
import { OutOfStockLabelComponent } from './out-of-stock-label/out-of-stock-label.component';
import { LoyaltyRewardComponent } from './loyalty-reward/loyalty-reward.component';
import { MenuCardComponent } from './menu-card/menu-card.component';
import { MainThumbPipe } from './pipes/main-thumb.pipe';
import { SharedModule } from './shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SocialLoginsComponent } from './social-logins/social-logins.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { CustomerModule } from './modules/customer/customer.module';
import { DeliveryMethodModule } from './modules/delivery-method/delivery-method.module';
import { DeliveryTimeModule } from './modules/delivery-time/delivery-time.module';
import { FirmModule } from './modules/firm/firm.module';
import { ModifierComponent } from './modifier/modifier.component';
import { SnoozedLabelComponent } from './snoozed-label/snoozed-label.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BasketComponent,
    MenuComponent,
    CategoryComponent,
    NotfoundComponent,
    FooterComponent,
    ItemComponent,
    FirmsComponent,
    InfoComponent,
    SearchComponent,
    FirmInfoComponent,
    PaymentMethodComponent,
    OverviewComponent,
    OrderProcessedComponent,
    OrderModalComponent,
    EditOrderModalComponent,
    PaymentModal,
    SafePipe,
    MinimumPricePipe,
    ErrorModal,
    FavoritesComponent,
    ProfileComponent,
    SettingsComponent,
    RecentOrdersComponent,
    OrderComponent,
    PasswordComponent,
    DemoPasswordComponent,
    VatDeliveryMethodComponent,
    CategoryItemComponent,
    ListItemComponent,
    LoyaltyComponent,
    ReservationComponent,
    ReservationToolComponent,
    VatDeliveryMethodOpenerComponent,
    FeaturedProductsComponent,
    OrderNowBarComponent,
    DeliveryHoursComponent,
    DeliveryInfoComponent,
    OrderSideBarComponent,
    OrderHeaderComponent,
    OrderDetailComponent,
    QrCodeComponent,
    OrderItemComponent,
    EmbeddedHeaderComponent,
    SigninComponent,
    UserProfileComponent,
    SignupComponent,
    PasswordResetComponent,
    ExtraInfoComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsOfUseComponent,
    CookiePolicyComponent,
    ExtraPointsComponent,
    EmailLabelComponent,
    HoursToggleComponent,
    ImageCarouselComponent,
    QuantitySelectorComponent,
    UnitSelectorComponent,
    WeightSelectorComponent,
    OutOfStockLabelComponent,
    LoyaltyRewardComponent,
    MenuCardComponent,
    MainThumbPipe,
    SubHeaderComponent,
    SocialLoginsComponent,
    ModifierComponent,
    SnoozedLabelComponent,
  ],
  exports: [OrderModalComponent, EditOrderModalComponent, PaymentModal, ErrorModal],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    SharedModule,
    CustomerModule,
    DeliveryMethodModule,
    DeliveryTimeModule,
    FirmModule,
    DeviceDetectorModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDGZa8KAscOv19uz2UDLg6Q0aKHo0R367M',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthService,
    FirmService,
    ProductService,
    DialogsService,
    SlugifyService,
    OrderService,
    CustomerService,
    PaymentService,
    LanguageService,
    LoaderService,
    ErrorService,
    SearchService,
    FavoritesService,
    StoreService,
    ThemingService,
    BasketItemsService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
