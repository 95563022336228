import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogsService } from '../../../../services/dialogs.service';
import { FirmStore } from '../../../firm/store/firm.store';
import { CustomerService } from '../../../../services/customer.service';
import { MenuStore } from '../../../menu/store/menu.store';
declare const google: any;

@Component({
  selector: 'multifirm-homepage',
  templateUrl: './multifirm-homepage.component.html',
  styleUrls: ['./multifirm-homepage.component.scss'],
})
export class MultifirmHomepageComponent implements OnInit {
  @Input() firms: any[];
  @Input() distributor: any;

  siteName: string;
  language: string;
  mapMarker: any;
  mapBounds = new google.maps.LatLngBounds();

  constructor(
    private route: ActivatedRoute,
    private modalService: DialogsService,
    private firmStore: FirmStore,
    private customerService: CustomerService,
    private menuStore: MenuStore
  ) {
    window.scrollTo(0, 0);
    this.siteName = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;
  }

  ngOnInit(): void {
    this.menuStore.setViewMenuActive(false);
    this.firmStore.setHasParent(true);
    this.convertCoordinatesToNumbers();
    this.mapMarker = {
      scaledSize: { width: 40, height: 40 },
      url: this.distributor.map_marker,
    };

    const login = this.customerService.getLogin();
    if (login) {
      this.customerService.get(this.firms[0], login.id, this.language, this.firms[0].distributor);
    }
  }

  convertCoordinatesToNumbers() {
    this.firms.forEach((firm) => {
      firm.geocode.lat = Number(firm.geocode.lat);
      firm.geocode.long = Number(firm.geocode.long);
      this.mapBounds.extend(new google.maps.LatLng(firm.geocode.lat, firm.geocode.long));
    });
  }

  openMethodSelectionModal() {
    this.modalService.openMethodSelectionModal();
  }
}
