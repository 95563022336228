import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeliveryTimeStore {
  private readonly STORE_KEY = 'deliveryTime';
  public currentDeliveryTime: string;
  public updateEvent: EventEmitter<string>;
  public selectedTimeslotId$ = new BehaviorSubject(0);
  public modalShown$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.updateEvent = new EventEmitter<string>();
  }

  setDeliveryTime(deliveryTime: string): void {
    this.currentDeliveryTime = deliveryTime;
    this.updateEvent.emit(deliveryTime);
    localStorage.setItem(this.STORE_KEY, JSON.stringify(deliveryTime));
  }

  getDeliveryTime(): string {
    if (!this.currentDeliveryTime) {
      const deliveryTime = JSON.parse(localStorage.getItem(this.STORE_KEY));
      this.setDeliveryTime(deliveryTime);
    }

    return this.currentDeliveryTime;
  }

  selectTimeslot(id: number): void {
    this.selectedTimeslotId$.next(id);
    localStorage.setItem('selectedTimeslot', JSON.stringify(id));
  }

  getTimeslot(): number {
    if (this.selectedTimeslotId$.getValue()) {
      return this.selectedTimeslotId$.getValue();
    }

    return JSON.parse(localStorage.getItem('selectedTimeslot'));
  }
}
