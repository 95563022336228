import { Component } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './errormodal.component.html',
  styleUrls: ['./errormodal.component.css'],
})
export class ErrorModal {
  title: any;
  message: any;
  buttons: boolean;

  constructor() {}
}
