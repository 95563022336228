<head>
  <title>{{ firm?.name }}</title>
</head>
<body>
<div class="main"
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.main_gradient_contrast_color">

  <!-- Header -->
  <app-header *ngIf="!firmService.checkEmbed(); else embeddedHeader" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
  <ng-template #embeddedHeader>
    <app-embedded-header [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>
  </ng-template>

  <!-- Content -->
  <div class="pagecontent">

    <div class="view-productdetail">
      <div class="centered">

        <!-- Vat delivery method -->
        <app-vat-delivery-method-opener *ngIf="firm" [firm]="firm" (vatChanged)="vatDeliveryMethodChanged($event)"></app-vat-delivery-method-opener>

        <!-- Breadcrumb -->
        <div *ngIf="!subcategory" class="breadcrumb">
          <a href="javascript:void(0)" (click)="routeService.navigateToMenu()">{{ 'HEADER.SELECTION' | translate }}</a>
          <a href="javascript:void(0)" (click)="routeService.navigateToCategory(own_domain, firm, language, cat_name, cat_id)">{{ cat_name }}</a>
        </div>
        <div *ngIf="subcategory" class="breadcrumb">
          <a href="javascript:void(0)" (click)="routeService.navigateToMenu()">{{ 'HEADER.SELECTION' | translate }}</a>
          <a href="javascript:void(0)" (click)="routeService.navigateToCategory(own_domain, firm, language, cat_name, cat_id)">{{ cat_name }}</a>
          <a href="javascript:void(0)" (click)="routeService.navigateToSubCategory(own_domain, firm, language, cat_name, cat_id, subcat_name, subcat_id)">{{ subcat_name }}</a>
        </div>

        <div class="details row">

          <!-- Images -->
          <div class="col half col-sm image" *ngIf="firm?.product_images_enabled" style="overflow: hidden;">

            <!-- Single image -->
            <div class="outlinedimage slideshow" *ngIf="item?.images && item?.images.length == 1">
              <ul class="slides clear">
                <li class="slide">
                  <img *ngIf="item?.images" [src]="item?.images[0].detail" alt="">
                </li>
              </ul>
            </div>

            <!-- Multiple images -->
            <eo-image-carousel *ngIf="item?.images && item?.images.length > 1" [images]="this.item.images"></eo-image-carousel>

          </div>

          <!-- Content -->
          <div class="col half col-sm info">

            <!-- Badges -->
            <div class="badge-container" *ngIf="!item?.in_stock || item?.has_promotional_price || item?.popular">
              <div *ngIf="!item?.in_stock" class="stock-container container-shadow">
                <p>{{ 'NOT_IN_STOCK' | translate }}</p>
              </div>
              <div *ngIf="item?.has_promotional_price" class="promo-container container-shadow">
                <p>{{ 'PROMO' | translate }}</p>
              </div>
              <div *ngIf="item?.popular" class="popular-container container-shadow">
                <p>{{ 'POPULAR' | translate }}</p>
              </div>
            </div>

            <!-- Product title -->
            <h1 class="product-title title-font">{{ item?.name }}</h1>

            <!-- Snoozed -->
            <div class="out_of_stock" *ngIf="item && productService.isSnoozed(item)">
              <eo-snoozed-label [snoozeEnd]="item.snooze_end"></eo-snoozed-label>
            </div>

            <!-- Parent category not available -->
            <div class="unavailable" *ngIf="parent_category && firm?.only_show_available_parent_categories && !parent_category?.isAvailable">
              <p><span>{{ parent_category.name }}</span> {{ 'CAT_NOT_AVAILABLE_ATM' | translate }}</p>
              <p *ngIf="parent_category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: parent_category.firstAvailableDate.day_name.toLowerCase()} }} {{ 'FROM' | translate:{time: parent_category.firstAvailableDate.from_time} }}</p>
            </div>

            <!-- Kcal -->
            <div class="kcal" *ngIf="item?.calories">
              <i class="fas fa-fire icon"></i>
              <p>{{ item?.calories }} kcal</p>
            </div>

            <!-- Description -->
            <div class="product-detail">
              <h5 class="product-detail--title">{{ 'INFO.TITLE' | translate}}</h5>

              <p>{{ item?.description }}</p>
            </div>

            <!-- Ingredients -->
            <div *ngIf="mainExists" class="product-detail">
              <h5 class="product-detail--title">{{ 'ITEM.INGREDIENTS' | translate }}</h5>
              <p>{{ mainString }}</p>
            </div>

            <!-- Option groups -->
            <div *ngIf="item?.option_groups" class="ingredients">
              <div *ngFor="let option_group of item?.option_groups">
                <p class="item-margin ingr-title color-grey"
                   style="margin-bottom: 0px">{{ option_group.name | uppercase }}:</p>

                <div *ngFor="let option of option_group.options" style="margin-left: 10px">
                  <p class="item-margin color-darkgray">{{ option.name }}
                    <span class="color-grey font-13"
                          *ngIf="option.price > 0 && !firm?.uses_vat">+ {{ firm?.currency.symbol }}{{ option.price | number:'1.2-2' }}
                      <span *ngIf="item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                                            <span *ngIf="item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                                        </span>
                    <span class="color-grey font-13"
                          *ngIf="option.price > 0 && firm?.uses_vat">+ {{ firm?.currency.symbol }}{{ option.price + (option.price / 100 * VAT_percentage) | number:'1.2-2' }}
                      <span *ngIf="item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                                            <span *ngIf="item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                                        </span>
                  </p>
                </div>
              </div>
            </div>

            <!-- Allergenes -->
            <div *ngIf="item?.allergenes.length > 0" class="ingredients allergenes">
              <p class="item-margin ingr-title color-grey">{{ 'ALLERGENS' | translate }}</p>
              <img *ngFor="let allergene of item?.allergenes" [src]="allergene.image" mat-raised-button [matTooltip]="allergene.name">
            </div>

            <!-- Price (No VAT) -->
            <div class="price" *ngIf="!firm?.uses_vat">
              <div *ngIf="item?.has_promotional_price == false" style="margin-top: 15px">
                <p no-margin>{{ 'BASKET.TOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ item?.price | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
              </div>

              <!-- With promotion -->
              <div *ngIf="item?.has_promotional_price == true" style="margin-top: 15px">
                <del class="color-grey">{{ 'BASKET.TOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ item?.price | number:'1.2-2' }}/{{ item?.unit.name_singular}}</del>
                <p class="color-red">{{ 'PROMOTION' | translate }}
                  : {{ firm?.currency.symbol }}{{ item?.promotion.price | number:'1.2-2' }}
                  /{{ item?.unit.name_singular}}</p>
                <p
                  class="color-grey font-14">{{ 'MESSAGES.PROMO_FROM' | translate }}{{ promo_from }}{{ 'MESSAGES.PROMO_TO' | translate }}{{ promo_to }}</p>
              </div>
            </div>

            <!-- Price (VAT BE only) -->
            <div class="price" *ngIf="(firm?.uses_vat && !firm?.show_vat_in_frontend)">
              <div *ngIf="!item?.has_promotional_price" style="margin-top: 15px">
                <p no-margin *ngIf="!itemDisabled">{{ 'BASKET.TOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
              </div>

              <!-- With promotion -->
              <div *ngIf="item?.has_promotional_price == true" style="margin-top: 15px">
                <del class="color-grey">{{ 'SUBTOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ item?.price + (item?.price / 100 * VAT_percentage) | number:'1.2-2' }}
                  /{{ item?.unit.name_singular}}</del>
                <p no-margin *ngIf="!itemDisabled">{{ 'BASKET.TOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
                <p
                  class="color-grey font-14">{{ 'MESSAGES.PROMO_FROM' | translate }}{{ promo_from }}{{ 'MESSAGES.PROMO_TO' | translate }}{{ promo_to }}</p>
              </div>
            </div>

            <!-- Price (VAT) -->
            <div class="price"
                 *ngIf="(firm?.uses_vat && vat_delivery_method || firm?.uses_vat && firm.vat_settings.allowed_vat_percentages.length == 1) && firm?.show_vat_in_frontend && !itemDisabled">
              <div *ngIf="item?.has_promotional_price == false" style="margin-top: 15px">
                <p no-margin>{{ 'SUBTOTAL' | translate }}: {{ firm?.currency.symbol }}{{ item?.price | number:'1.2-2' }}
                  /{{ item?.unit.name_singular}}</p>
                <p no-margin>{{ 'VAT' | translate }} ({{ VAT_percentage }}
                  %): {{ firm?.currency.symbol }}{{ VAT | number:'1.2-2' }}</p>
                <p no-margin>{{ 'BASKET.TOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
              </div>

              <!-- With promotion -->
              <div *ngIf="item?.has_promotional_price == true" style="margin-top: 15px">
                <del class="color-grey">{{ 'SUBTOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ item?.price + (item?.price / 100 * VAT_percentage) | number:'1.2-2' }}
                  /{{ item?.unit.name_singular}}</del>
                <p no-margin>{{ 'VAT' | translate }} ({{ VAT_percentage }}
                  %): {{ firm?.currency.symbol }}{{ VAT | number:'1.2-2' }}</p>
                <p no-margin class="color-red">{{ 'PROMOTION' | translate }}
                  : {{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
                <p
                  class="color-grey font-14">{{ 'MESSAGES.PROMO_FROM' | translate }}{{ promo_from }}{{ 'MESSAGES.PROMO_TO' | translate }}{{ promo_to }}</p>
              </div>
            </div>

            <!-- Order button -->
            <div class="item_addtobasket"
                 *ngIf="firm?.website_details.is_webshop && !itemDisabled && item?.in_stock && !productService.isSnoozed(item)">
              <button (click)="openModal()" class="btn-fill add custom-btn">
                +<span class="label">{{ 'CATEGORY.ADD_TO_BASKET' | translate }}</span>
              </button>
            </div>

            <!-- No favorite -->
            <div class="item_addtobasket favorite-btn" *ngIf="!favorite">
              <a href="javascript:void(0)" (click)="addToFavorites()"
                 class="btn-fill add color-white background-grey">
                <i class="far fa-star"></i>
              </a>
            </div>

            <!-- Favorite -->
            <div class="item_addtobasket favorite-btn" *ngIf="favorite">
              <a (click)="addToFavorites()" class="btn-fill add">
                <i class="far fa-star"></i>
              </a>
            </div>

            <p class="vat_disabled color-red font-14" *ngIf="itemDisabled">{{ 'NO_ORDER_POSSIBLE' | translate }}</p>
          </div>

        </div>

      </div>
    </div>

  </div>

  <!-- Footer -->
  <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

</div>

<div id="iosfix-top"></div>
<div id="iosfix-btm"></div>

</body>
