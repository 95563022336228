import { Component, Input } from '@angular/core';
import { RouteService } from '../services/route.service';
import { MenuStore } from '../modules/menu/store/menu.store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  @Input() firm: any;
  @Input() distributor: any;
  @Input() own_domain: any;
  @Input() language: any;

  constructor(public routeService: RouteService, public menuStore: MenuStore) {}
}
