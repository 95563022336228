import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FirmsListModalComponent } from './components/firms-list-modal/firms-list-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { SharedModule } from '../../shared/shared.module';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, TranslateModule, MatRadioModule],
  declarations: [FirmsListModalComponent, ErrorModalComponent],
  exports: [FirmsListModalComponent, ErrorModalComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class FirmModule {}
