import { Injectable } from '@angular/core';
import { Address } from '../../../core/models/address';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddressSelectionModalComponent } from '../components/address-selection-modal/address-selection-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DeliveryAddressService {
  constructor(private dialog: MatDialog) {}

  public openAddressSelectionModal(
    addresses: Address[],
    showSwitcher: boolean,
    address: Address | null = null
  ): Observable<any> {
    const dialogRef = this.dialog.open(AddressSelectionModalComponent, {
      panelClass: 'eo-modal',
      width: '400px',
    });

    dialogRef.componentInstance.addresses = addresses;
    dialogRef.componentInstance.showSwitcher = showSwitcher;
    dialogRef.componentInstance.address = address;

    return dialogRef.afterClosed();
  }
}
